//* Packages Imports */
import clsx from "clsx";

//* Components Imports */
import Button from "@Core/Button";

//* Utils Imports */
import { ILeadTasks, ILeadTaskUpdatePayload } from "@Types/common";
import { safeHtmlUnescape } from "@Utils/stringUtils";
import { calculateTimeDifference } from "@Utils/timeUtils";
import { useAppSelector } from "@Hooks/redux-hooks";
import { userData } from "@Slice/AuthSlice";

//* Assets Imports */
import CompletedOutline from "@Assets/icons/CompletedOutline.png";
import ClockRed from "@Assets/icons/ClockRed.png";

//* Styles Imports */
import Styles from "@Components/TaskSection/TaskSection.module.scss";

const TasksCard = ({
  task,
  handleTaskUpdate,
  updateTaskStatus = false,
}: {
  task: ILeadTasks;
  handleTaskUpdate: (id: number, data: ILeadTaskUpdatePayload) => void;
  updateTaskStatus: boolean | undefined;
}) => {
  const { id: userId } = useAppSelector(userData);
  const activeUserId = Number(userId);

  const getUserName = (obj: any) =>
    obj?.id === activeUserId ? "Me" : obj?.name || "";

  const delayedBy =
    !task.isCompleted &&
    calculateTimeDifference(new Date(task.remindAt), new Date());

  return (
    <div>
      <div className={clsx(Styles.singleTask, { [Styles.error]: delayedBy })}>
        <div className={Styles.taskTitle}>
          {task.taskCategory?.category}
          <div className={Styles.taskSummary}>
            {safeHtmlUnescape(task.summary || "")}
          </div>
        </div>
        {(task.createdBy?.id !== activeUserId ||
          task.assignee?.id !== activeUserId) && (
          <div
            className={clsx(Styles.taskBy, {
              [Styles.yellow]: task.createdBy?.id === activeUserId,
              [Styles.purple]: task.assignee?.id === activeUserId,
            })}
          >
            Assigned to <span>{getUserName(task.assignee)}</span> by{" "}
            <span>{getUserName(task.createdBy)}</span>
          </div>
        )}

        {task.isCompleted ? (
          <div className={Styles.completionTime}>
            <span>Completed </span>
            on
            <span> {task.updatedAt}</span>
          </div>
        ) : (
          <>
            {delayedBy && (
              <div className={Styles.delayedMessage}>
                <img src={ClockRed} alt="clock" />
                Delayed by {delayedBy}
              </div>
            )}
            <div className={Styles.taskDue}>
              Due at <span>{task.remindAt}</span>
            </div>
            {updateTaskStatus && task.assignee?.id === activeUserId && (
              <>
                <div className={Styles.dividerLine} />
                <Button
                  type="button"
                  variant="outline"
                  classes={Styles.markCompleted}
                  onClick={() =>
                    handleTaskUpdate(task.id, { isCompleted: true })
                  }
                >
                  <img src={CompletedOutline} alt="complete" />
                  Mark as Completed
                </Button>
              </>
            )}
          </>
        )}
      </div>
      <p className={Styles.taskFooter}>
        {task.createdBy?.name} on {task.updatedAt}
      </p>
    </div>
  );
};

export default TasksCard;
