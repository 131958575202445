//* Package Imports */
import { ReactNode, useEffect, useRef, useState } from "react";
import clsx from "clsx";

//* Assets Imports */
import ClockOutline from "@Assets/icons/ClockOutline.png";

//* Utils Imports */
import { ITimeInputData } from "@Types/common";

//* Styles Imports */
import Styles from "@Core/TimeInput/TimeInput.module.scss";

//* Constants Declaration */
const VISIBILE_HOURS = Array.from({ length: 12 }, (_, i) =>
  `${i + 1}`.padStart(2, "0"),
);
const VISIBLE_MINUTES = Array.from({ length: 13 }, (_, i) =>
  `${i * 5}`.padStart(2, "0"),
);
const VISIBLE_MERIDIEM = ["AM", "PM"];

const DropdownList = ({
  options,
  selectedValue,
  setValue,
  bottomComponent,
}: {
  options: string[];
  selectedValue: string;
  setValue: (data: string) => void;
  bottomComponent?: ReactNode;
}) => (
  <div className={Styles.dropdownList}>
    {options.map((hour: string) => (
      <div
        key={hour}
        onClick={() => setValue(hour)}
        className={clsx(Styles.option, {
          [Styles.selected]: hour === selectedValue,
        })}
      >
        {hour}
      </div>
    ))}
    {bottomComponent}
  </div>
);

const TimeInput = ({
  defaultTime = new Date(),
  setValue,
  errorState,
}: {
  defaultTime?: Date;
  setValue: (data: ITimeInputData) => void;
  errorState?: boolean;
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const minuteRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLInputElement>(null);
  const meridiemRef = useRef<HTMLInputElement>(null);

  const defaultHours = defaultTime.getHours();

  const [hours, setHours] = useState<string>(
    defaultHours > 12
      ? `${defaultHours - 12}`.padStart(2, "0")
      : defaultHours === 0
        ? "12"
        : `${defaultHours}`.padStart(2, "0"),
  );
  const [minutes, setMinutes] = useState<string>(
    defaultTime.getMinutes().toString().padStart(2, "0"),
  );
  const [meridiem, setMeridiem] = useState<string>(
    defaultHours >= 12 ? "PM" : "AM",
  );
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const handleHoursInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = e.target?.value;
    const regex = /^(0|0[1-9]|[1-9]|1[0-2])$/;
    if (fieldValue === "" || regex.test(fieldValue)) {
      setHours(fieldValue);
      if (
        fieldValue.length === 2 ||
        (fieldValue.length === 1 && parseInt(fieldValue) > 1)
      ) {
        setHours(fieldValue.padStart(2, "0"));
        minuteRef.current?.focus();
      }
    }
  };

  const handleMinutesInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = e.target.value;
    const regex = /^[0-5]?[0-9]$/;
    if (fieldValue === "" || regex.test(fieldValue)) {
      setMinutes(fieldValue);
      if (fieldValue.length === 2) meridiemRef.current?.focus();
    }
  };

  const submitTime = () => {
    if (hours && hours !== "0" && minutes && meridiem) {
      let formattedHours = parseInt(hours);
      const formattedMinutes = parseInt(minutes);

      if (meridiem === "PM" && parseInt(hours) !== 12) {
        formattedHours += 12;
      }
      // Adjust midnight
      if (meridiem === "AM" && parseInt(hours) === 12) {
        formattedHours = 0;
      }

      setValue?.({
        label: `${String(formattedHours).padStart(2, "0")}:${String(
          formattedMinutes,
        ).padStart(2, "0")} ${meridiem}`,
        hours: formattedHours,
        minutes: formattedMinutes,
      });
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      const handleDropdownClick = (event: MouseEvent) => {
        if (
          !dropdownRef.current?.contains(event.target as HTMLElement) &&
          dropdownVisible
        )
          setDropdownVisible(false);
      };

      document.addEventListener("mousedown", handleDropdownClick);
      return () => {
        document.removeEventListener("mousedown", handleDropdownClick);
      };
    }
  }, [dropdownVisible]);

  useEffect(() => {
    submitTime();
  }, [minutes, hours, meridiem]);

  return (
    <div className={Styles.timeInputContainer} ref={dropdownRef}>
      {dropdownVisible && (
        <div className={Styles.dropdownPanel}>
          <DropdownList
            options={VISIBILE_HOURS}
            selectedValue={hours}
            setValue={setHours}
          />
          <DropdownList
            options={VISIBLE_MINUTES}
            selectedValue={minutes}
            setValue={setMinutes}
          />
          <DropdownList
            options={VISIBLE_MERIDIEM}
            selectedValue={meridiem}
            setValue={setMeridiem}
            bottomComponent={
              <button
                onClick={() => setDropdownVisible(false)}
                className={Styles.submit}
              >
                Done
              </button>
            }
          />
        </div>
      )}
      <div className={clsx(Styles.timeField, { [Styles.error]: errorState })}>
        <input
          type="text"
          ref={hourRef}
          value={hours}
          onChange={handleHoursInput}
          placeholder="HH"
          className={Styles.input}
          maxLength={2}
        />
        <span className="-mt-[1px]">:</span>
        <input
          type="text"
          ref={minuteRef}
          value={minutes}
          onChange={handleMinutesInput}
          onKeyDown={(e) => {
            if (
              e.code === "Backspace" &&
              (e.target as HTMLInputElement).value === ""
            )
              hourRef.current?.focus();
          }}
          placeholder="MM"
          className={Styles.input}
          maxLength={2}
        />
        <div className={Styles.meridiem}>
          <input
            type="text"
            value={meridiem}
            ref={meridiemRef}
            onChange={() => {}}
            onKeyDown={(e) => {
              if (e.code === "KeyA") {
                setMeridiem("AM");
              } else if (e.code === "KeyP") {
                setMeridiem("PM");
              } else if (e.code === "Backspace") minuteRef.current?.focus();
            }}
            placeholder="AM"
            className={Styles.input}
            maxLength={2}
          />
        </div>
        <img
          src={ClockOutline}
          alt="clock-dropdown"
          className={Styles.dropdownIcon}
          onClick={() => setDropdownVisible(!dropdownVisible)}
        />
      </div>
    </div>
  );
};

export default TimeInput;
