//* Package Imports */
import { useState, useEffect, useRef } from "react";
import clsx from "clsx";

//* Components Imports */
import Checkbox from "@Core/Checkbox";

//* Assets Imports */
import ChevronDown from "@Assets/icons/ChevronDown.png";

//* Utils Imports */
import debounce from "@Utils/debounce";

//* Styles */
import Styles from "@Core/Select/Select.module.scss";

//* Interface */
interface SelectProps {
  options: Record<string, any>[];
  label?: string;
  showDownArrow?: boolean;
  canSearch?: boolean;
  variant?: "singleSelect" | "multiSelect";
  placeholder?: string;
  defaults?: any;
  labelKey?: string;
  valueKey?: string;
  size?: "sm" | "xs" | "xxs";
  disabled?: boolean;
  mandatory?: boolean;
  labelPosition?: "left" | "top";
  setValue?: (option: any) => void;
  fetchOptions?: () => void;
  optionsLoading?: boolean;
  optionPosition?: "top" | "bottom";
}

const Select = ({
  options,
  canSearch,
  variant = "singleSelect",
  label = "",
  showDownArrow = true,
  placeholder = "Select",
  labelKey = "label",
  valueKey = "value",
  defaults = {},
  size = "sm",
  disabled = false,
  setValue,
  labelPosition = "top",
  mandatory,
  fetchOptions,
  optionsLoading,
  optionPosition = "bottom",
}: SelectProps) => {
  const textClass = `text-${size}`;

  const selectorRef = useRef<HTMLDivElement | null>(null);
  const isDirty = useRef(false);
  const firstLoad = useRef(true);

  const [listVisible, setListVisible] = useState<boolean>(false);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState(options || []);
  const [selectedOptions, setSelectedOptions] = useState<any[]>();

  const getSelectedOptions = () => {
    return Array.isArray(defaults) ? defaults : [defaults];
  };

  const checkBoxSelectedOption = selectedOptions?.map(
    (option) => option?.[labelKey],
  );

  const selected: string =
    variant === "multiSelect" && selectedOptions?.length
      ? checkBoxSelectedOption?.join(", ")
      : selectedOptions?.[0]?.[labelKey];

  const handleDropdownClose = () => {
    setDropdownOptions(options);
    setInputActive(false);
    setListVisible(false);
  };

  const handleSelectOption = (value: any, checked?: boolean) => {
    let selectedValue;
    if (!isDirty.current) isDirty.current = true;
    if (variant === "multiSelect") {
      const currentSelectedOptions =
        checked && selectedOptions
          ? [...selectedOptions, value]
          : selectedOptions?.filter(
              (option) => option?.[valueKey] !== value?.[valueKey],
            );
      setSelectedOptions(currentSelectedOptions);

      const multiSelectedOptions = currentSelectedOptions?.map(
        (option: any) => option[valueKey],
      );

      selectedValue = options?.filter((option) => {
        return multiSelectedOptions?.includes(option[valueKey]);
      });
    } else {
      handleDropdownClose();
      setSelectedOptions([value]);
      selectedValue = value;
    }

    setValue?.(selectedValue);
  };

  const handleInputChange = debounce((value: string) => {
    setDropdownOptions(
      options?.filter((option) =>
        option[labelKey].toLowerCase().includes(value.trim().toLowerCase()),
      ),
    );
  }, 500);

  useEffect(() => {
    if (listVisible) {
      if (firstLoad.current) {
        fetchOptions?.();
        firstLoad.current = false;
      }
      /* 
      Causing shifts in page, might need to remove - DISCUSS
      
      selectorRef.current
        ?.getElementsByClassName(Styles.selected)[0]
        ?.scrollIntoView({
          behavior: "instant",
          block: "center",
          inline: "start",
        }); 
      */

      const handleDropdownClick = (event: MouseEvent) => {
        if (
          !selectorRef.current?.contains(event.target as HTMLElement) &&
          listVisible
        ) {
          handleDropdownClose();
        }
      };

      document.addEventListener("mousedown", handleDropdownClick);
      return () => {
        document.removeEventListener("mousedown", handleDropdownClick);
      };
    }
  }, [listVisible, options]);

  useEffect(() => {
    const defaultSelectedOptions = getSelectedOptions();

    if (
      defaultSelectedOptions?.[0] &&
      Object.keys(defaultSelectedOptions?.[0]).length === 0
    )
      return;

    if (
      JSON.stringify(selectedOptions) !==
        JSON.stringify(defaultSelectedOptions) &&
      !isDirty.current
    ) {
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [defaults]);

  useEffect(() => {
    if (JSON.stringify(options) !== JSON.stringify(dropdownOptions)) {
      setDropdownOptions(options || []);
    }
  }, [options]);

  return (
    <div
      className={clsx(Styles.selectContainer, {
        [Styles.disabled]: disabled,
        [Styles.labelLeft]: labelPosition === "left",
      })}
      ref={selectorRef}
    >
      {label && (
        <label className={Styles.selectLabel}>
          {mandatory && <span>*</span>}
          {label}
        </label>
      )}
      <div className={Styles.selectWrapper}>
        <section
          className={Styles.selectedField}
          onClick={() => {
            if (!disabled) setListVisible(!listVisible);
          }}
        >
          <div
            className={clsx(
              Styles.selectValue,
              {
                [Styles.placeholder]: !selected && !inputActive,
                [Styles.searchable]: canSearch,
              },
              textClass,
            )}
            onClick={(e) => {
              if (canSearch && !disabled) {
                e.stopPropagation();
                setListVisible(true);
                setInputActive(true);
              }
            }}
            aria-disabled={disabled}
          >
            {inputActive ? (
              <input
                className={Styles.input}
                type="text"
                placeholder="Search..."
                autoFocus
                onChange={(e) => {
                  handleInputChange(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            ) : (
              <>{selected ? selected : placeholder}</>
            )}
          </div>

          {showDownArrow && (
            <img
              className={clsx(Styles.chevron, {
                [Styles.chevronUp]: listVisible,
              })}
              src={ChevronDown}
              alt="down"
            />
          )}
        </section>
        {listVisible && (
          <div
            className={clsx(Styles.selectPanel, {
              [Styles.optionsTop]: optionPosition === "top",
            })}
            role="listbox"
          >
            {optionsLoading ? (
              <div
                className={clsx(
                  Styles.selectOption,
                  Styles.noOptions,
                  textClass,
                )}
              >
                Loading...
              </div>
            ) : dropdownOptions?.length > 0 ? (
              dropdownOptions.map((option, index) => (
                <div
                  className={clsx(Styles.selectOption, {
                    [Styles.selected]:
                      variant !== "multiSelect" &&
                      selectedOptions?.some(
                        (item) => item?.[valueKey] === option[valueKey],
                      ),
                    [Styles.singleSelect]: variant !== "multiSelect",
                  })}
                  key={`${option[labelKey]}${index}`}
                  role="option"
                  onClick={() => {
                    if (variant !== "multiSelect") handleSelectOption(option);
                  }}
                >
                  {variant === "multiSelect" ? (
                    <Checkbox
                      label={option[labelKey]}
                      name={option[labelKey]}
                      value={option[valueKey]}
                      checked={checkBoxSelectedOption?.includes(
                        option[labelKey],
                      )}
                      onChange={(_, checked) =>
                        handleSelectOption(option, checked)
                      }
                    />
                  ) : (
                    <span className={textClass}>{option[labelKey]}</span>
                  )}
                </div>
              ))
            ) : (
              <div
                className={clsx(
                  Styles.selectOption,
                  Styles.noOptions,
                  textClass,
                )}
              >
                No options
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
