//* Components Imports */
import RenderOnEntry from "@Components/utility/RenderOnEntry";
import LoanDetails from "@Components/pages/leads/lead-details/StudentDetailsSection/LoanDetails";
import BasicDetails from "@Components/pages/leads/lead-details/StudentDetailsSection/BasicDetails";
import AcademicDetails from "@Components/pages/leads/lead-details/StudentDetailsSection/AcademicDetails";
import AdmissionDetails from "@Components/pages/leads/lead-details/StudentDetailsSection/AdmissionDetails";
import MarketingDetails from "@Components/pages/leads/lead-details/StudentDetailsSection/MarketingDetails";
import CoApplicantSection from "@Components/pages/leads/lead-details/StudentDetailsSection/CoApplicantSection";

//* Utils Imports */
import { useAppSelector } from "@Hooks/redux-hooks";

//* Styles Imports */
import Styles from "@Components/pages/leads/lead-details/StudentDetailsSection/StudentDetailsSection.module.scss";

const StudentDetailsSection = () => {
  const { access } = useAppSelector((state) => state.user);

  return (
    <div className={Styles.studentDetailsFormSection}>
      <h2 className={Styles.formSectionHeader}>Lead Details</h2>
      <div className="flex flex-col gap-6">
        {access?.detail.marketingDetails.view && (
          <>
            <RenderOnEntry>
              <MarketingDetails />
            </RenderOnEntry>

            <hr className={Styles.divider} />
          </>
        )}
        {access?.detail.basicDetails.view && (
          <>
            <RenderOnEntry>
              <BasicDetails />
            </RenderOnEntry>

            <hr className={Styles.divider} />
          </>
        )}
        {access?.detail.admissionDetails.view && (
          <>
            <RenderOnEntry>
              <AdmissionDetails />
            </RenderOnEntry>

            <hr className={Styles.divider} />
          </>
        )}

        {access?.detail.academicDetails.view && (
          <>
            <RenderOnEntry>
              <AcademicDetails />
            </RenderOnEntry>
            <hr className={Styles.divider} />
          </>
        )}

        {access?.detail.loanDetails.view && (
          <>
            <RenderOnEntry>
              <LoanDetails />
            </RenderOnEntry>

            <hr className={Styles.divider} />
          </>
        )}
        {access?.detail.coapplicantDetails.view && (
          <RenderOnEntry>
            <CoApplicantSection />
          </RenderOnEntry>
        )}
      </div>
    </div>
  );
};

export default StudentDetailsSection;
