//* Library */
import { useState } from "react";

//* Components */
import Modal from "@Components/utility/Modal";
import RaiseIssueModal from "@Components/RaiseIssueModal";

//* Assets */
import Logout from "@Assets/icons/logout.png";
import YellowAlert from "@Assets/icons/yellow-error.png";

//* Services Imports */
import { handleLogout } from "@Utils/auth";

export default function ProfilePopper() {
  const [showModal, setShowModal] = useState(false);

  const handleLogoutClick = async () => {
    try {
      handleLogout();
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className="w-[272px] p-4">
      <div className="flex items-start gap-2 cursor-pointer">
        <div className="w-7 h-7">
          <img src={YellowAlert} className="w-5 h-5" />
        </div>
        <div
          className="flex flex-col gap-1 cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          <span className="text-xs">Raise an issue</span>
          <span className="text-gray text-xxs">
            Help us improve by reporting any issues you&apos;ve encountered
          </span>
        </div>
      </div>

      <hr className="border-gray-border my-3" />

      <div
        className="flex items-center gap-2 ml-1 cursor-pointer"
        onClick={handleLogoutClick}
      >
        <img src={Logout} alt="logout" className="w-4 h-4" />
        <span className="text-error text-xxs">Logout</span>
      </div>

      {showModal && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <RaiseIssueModal />
        </Modal>
      )}
    </div>
  );
}
