//* Packages */
import { ReactNode, useEffect } from "react";

//* Components */
import Portal from "@Components/utility/Portal";

interface IModal {
  children: ReactNode;
  open: boolean;
  persist?: boolean;
  onClose: () => void;
}

export default function Modal({
  children,
  open,
  persist = false,
  onClose,
}: IModal) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
  }, [open]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  });

  return (
    <Portal render={open}>
      <div
        className="w-full h-[100vh] bg-black bg-opacity-40 flex items-center justify-center z-[999]"
        onClick={persist ? undefined : onClose}
      >
        <div
          className="relative p-6 bg-white rounded-lg shadow-md z-[999]"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          {children}

          <div
            className="absolute right-6 top-6 cursor-pointer text-dark-gray text-xl"
            onClick={onClose}
          >
            &times;
          </div>
        </div>
      </div>
    </Portal>
  );
}
