//* Packages imports */
import { Navigate, Outlet } from "react-router-dom";
interface IProtectedProps {
  isAuthenticated: boolean;
}

export default function Protected({ isAuthenticated }: IProtectedProps) {
  // const getLoginURL = () => {
  //   const redirectURL = window.location.pathname + window.location.search;
  //   if (redirectURL) {
  //     return `/login?redirect=${encodeURIComponent(redirectURL)}`;
  //   } else {
  //     return "/login";
  //   }
  // };

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}
