//* Packages */
import { useMemo } from "react";

//* Assets */
import NoAccess from "@Assets/icons/no-access.png";
import NotFound from "@Assets/icons/not-found.png";

export default function ErrorComponent({
  status,
}: {
  status: "NOT_FOUND" | "NO_ACCESS";
}) {
  const STATUS_MAP = useMemo(() => {
    return {
      NOT_FOUND: {
        text: "Lead not found in this system",
        img: NotFound,
      },
      NO_ACCESS: {
        text: "You do not have an access to this lead",
        img: NoAccess,
      },
    };
  }, [status]);

  return (
    <div className="flex items-center justify-center w-full h-[80vh]">
      <div className="bg-white border px-10 h-[72px] border-lighter-gray rounded-md flex items-center justify-center gap-3">
        <img src={STATUS_MAP[status].img} alt={status} className="w-6 h-6" />
        {STATUS_MAP[status].text}
      </div>
    </div>
  );
}
