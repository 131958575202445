import { StatusKeyEnum } from "@Enums/common";
import { ISelect } from "@Types/common";

export const AGENT_LEAD_STATUS = [
  { label: "Pre Qualified", value: StatusKeyEnum.PRE_QUALIFIED },
  { label: "Created", value: StatusKeyEnum.CREATED },
  { label: "Contacted", value: StatusKeyEnum.CONTACTED },
  { label: "Processing", value: StatusKeyEnum.PROCESSING },
  { label: "Important", value: StatusKeyEnum.IMPORTANT },
  { label: "Docs Pending", value: StatusKeyEnum.DOCS_PENDING },
  { label: "Logged In", value: StatusKeyEnum.LOGGED_IN },
  { label: "Sanctioned", value: StatusKeyEnum.SANCTIONED },
  { label: "PF Confirmed", value: StatusKeyEnum.PF_CONFIRMED },
  { label: "Disbursed", value: StatusKeyEnum.DISBURSED },
  { label: "Opportunity", value: StatusKeyEnum.OPPORTUNITY },
  { label: "LQT Lost", value: StatusKeyEnum.LQT_LOST },
  { label: "Lost", value: StatusKeyEnum.LOST },
];

export const LENDER_LEAD_STATUS = [
  { label: "Yet to Connect", value: StatusKeyEnum.YET_TO_CONNECT },
  { label: "Under Discussion", value: StatusKeyEnum.UNDER_DISCUSSION },
  {
    label: "No Product/Not Eligible",
    value: StatusKeyEnum.NO_PRODUCT_OR_NOT_ELIGIBLE,
  },
  { label: "Future Date", value: StatusKeyEnum.FUTURE_DATE },
  { label: "Not Responding/Did not pick", value: StatusKeyEnum.NOT_RESPONDING },
  { label: "Awaiting Docs", value: StatusKeyEnum.AWAITING_DOCS },
  { label: "Ready to login", value: StatusKeyEnum.READY_TO_LOGIN },
  { label: "Logged In", value: StatusKeyEnum.LOGGED_IN },
  { label: "Sanctioned", value: StatusKeyEnum.SANCTIONED },
  { label: "PF Confirmed", value: StatusKeyEnum.PF_CONFIRMED },
  { label: "Disbursal", value: StatusKeyEnum.DISBURSED },
];

export const ADMISSION_STATUS = [
  {
    label: "Not Applied",
    value: "NOT APPLIED",
  },
  {
    label: "Applied",
    value: "APPLIED",
  },
  {
    label: "Conditional Offer",
    value: "CONDITIONAL OFFER LETTER",
  },
  {
    label: "Unconditional Offer",
    value: "UNCONDITIONAL OFFER LETTER",
  },
];

export const DEGREES_LIST = [
  {
    label: "Bachelors",
    value: "bachelors",
  },
  {
    label: "Masters",
    value: "masters",
  },
  {
    label: "Ph.D",
    value: "ph.d",
  },
  {
    label: "Diploma",
    value: "diploma",
  },
];

export const EXAM_LIST = [
  {
    label: "TOEFL",
    value: "TOEFL",
  },
  {
    label: "IELTS",
    value: "IELTS",
  },
  {
    label: "PTE",
    value: "PTE",
  },
  {
    label: "Duolingo",
    value: "DUOLINGO",
  },
  {
    label: "GRE",
    value: "GRE",
  },
  {
    label: "GMAT",
    value: "GMAT",
  },
  {
    label: "SAT",
    value: "SAT",
  },
];

export const LOAN_TYPE = [
  {
    label: "Secured",
    value: "SECURED",
  },
  {
    label: "Unsecured",
    value: "UNSECURED",
  },
];

export const EMPLOYMENT_TYPE = [
  {
    label: "Salaried",
    value: "SALARIED",
  },
  {
    label: "Self Employed",
    value: "SELF_EMPLOYED",
  },
  {
    label: "Unemployed",
    value: "UNEMPLOYED",
  },
];

export const AREA_OF_STUDY = [
  {
    label: "Aerospace and Manufacturing Engineering",
    value: "Aerospace and Manufacturing Engineering",
  },
  { label: "Agriculture", value: "Agriculture" },
  { label: "American Studies", value: "American Studies" },
  { label: "Anatomy and Physiology", value: "Anatomy and Physiology" },
  { label: "Anthropology", value: "Anthropology" },
  { label: "Applied Physics", value: "Applied Physics" },
  { label: "Architecture", value: "Architecture" },
  { label: "Art and Art History", value: "Art and Art History" },
  { label: "Astronomy", value: "Astronomy" },
  { label: "Audio Visual Studies", value: "Audio Visual Studies" },
  {
    label: "Australian Indigenous Languages",
    value: "Australian Indigenous Languages",
  },
  { label: "Automotive technology", value: "Automotive technology" },
  {
    label: "Avionics and Aeronautical Engineering",
    value: "Avionics and Aeronautical Engineering",
  },
  { label: "Bakery", value: "Bakery" },
  { label: "Banking and Finance", value: "Banking and Finance" },
  { label: "Beauty", value: "Beauty" },
  { label: "Behavioural Science", value: "Behavioural Science" },
  { label: "Biochemistry", value: "Biochemistry" },
  {
    label: "Biological and Life Sciences",
    value: "Biological and Life Sciences",
  },
  { label: "Biotechnology", value: "Biotechnology" },
  { label: "Botany", value: "Botany" },
  { label: "Broadcast Media", value: "Broadcast Media" },
  { label: "Building Economics", value: "Building Economics" },
  { label: "Building Technology", value: "Building Technology" },
  { label: "Business Accounting", value: "Business Accounting" },
  {
    label: "Business Administration and Management",
    value: "Business Administration and Management",
  },
  { label: "Business Analytics", value: "Business Analytics" },
  {
    label: "Business and Commercial Law",
    value: "Business and Commercial Law",
  },
  { label: "Business Economics", value: "Business Economics" },
  { label: "Business English", value: "Business English" },
  { label: "Chemical Engineering", value: "Chemical Engineering" },
  { label: "Chemical lab technology", value: "Chemical lab technology" },
  { label: "Chemistry", value: "Chemistry" },
  { label: "Civil Engineering", value: "Civil Engineering" },
  {
    label: "Classics and Ancient History",
    value: "Classics and Ancient History",
  },
  { label: "Complementary Therapy", value: "Complementary Therapy" },
  { label: "Computer Graphics", value: "Computer Graphics" },
  {
    label: "Computer Science and Engineering",
    value: "Computer Science and Engineering",
  },
  {
    label: "Computing and Information Technology (IT)",
    value: "Computing and Information Technology (IT)",
  },
  { label: "Construction Management", value: "Construction Management" },
  { label: "Corporate Communications", value: "Corporate Communications" },
  { label: "Cosmetology", value: "Cosmetology" },
  { label: "Counselling", value: "Counselling" },
  { label: "Crafts and textiles", value: "Crafts and textiles" },
  { label: "Creative Arts", value: "Creative Arts" },
  { label: "Criminal Law", value: "Criminal Law" },
  { label: "Criminology", value: "Criminology" },
  { label: "Culinary", value: "Culinary" },
  {
    label: "Curriculum and Education Studies",
    value: "Curriculum and Education Studies",
  },
  { label: "Data analysis", value: "Data analysis" },
  {
    label: "Dentistry and Dental Studies",
    value: "Dentistry and Dental Studies",
  },
  { label: "Design and Applied Arts", value: "Design and Applied Arts" },
  { label: "Design and Crafts", value: "Design and Crafts" },
  { label: "Development Studies", value: "Development Studies" },
  {
    label: "Digital Communications and Multimedia",
    value: "Digital Communications and Multimedia",
  },
  { label: "Drama and Theatre", value: "Drama and Theatre" },
  { label: "Earth Sciences", value: "Earth Sciences" },
  { label: "East Asian Languages", value: "East Asian Languages" },
  { label: "Ecology", value: "Ecology" },
  { label: "Economics", value: "Economics" },
  { label: "Electrical Engineering", value: "Electrical Engineering" },
  {
    label: "Electrical Fitting, Electrical Mechanics",
    value: "Electrical Fitting, Electrical Mechanics",
  },
  { label: "Electronics", value: "Electronics" },
  { label: "Engineering Design", value: "Engineering Design" },
  { label: "Engineering Science", value: "Engineering Science" },
  {
    label: "English and Creative Writing",
    value: "English and Creative Writing",
  },
  {
    label: "Environmental Engineering and Sciences",
    value: "Environmental Engineering and Sciences",
  },
  {
    label: "Family and Consumer Sciences",
    value: "Family and Consumer Sciences",
  },
  { label: "Fashion and Textiles", value: "Fashion and Textiles" },
  { label: "Film and TV production", value: "Film and TV production" },
  { label: "Fine Arts", value: "Fine Arts" },
  { label: "Fisheries studies", value: "Fisheries studies" },
  { label: "Food and Hospitality", value: "Food and Hospitality" },
  { label: "Food Science", value: "Food Science" },
  {
    label: "Forensic Science and Archaeology",
    value: "Forensic Science and Archaeology",
  },
  { label: "Forensics", value: "Forensics" },
  { label: "Forestry Studies", value: "Forestry Studies" },
  { label: "Game Development", value: "Game Development" },
  { label: "Gender Studies", value: "Gender Studies" },
  { label: "General Engineering", value: "General Engineering" },
  { label: "General Studies", value: "General Studies" },
  { label: "Genetics", value: "Genetics" },
  { label: "Geography", value: "Geography" },
  { label: "Geology", value: "Geology" },
  { label: "Geomatic Engineering", value: "Geomatic Engineering" },
  { label: "Germanic Languages", value: "Germanic Languages" },
  { label: "Gerontology", value: "Gerontology" },
  { label: "Global Studies", value: "Global Studies" },
  { label: "Graphic and Design Studies", value: "Graphic and Design Studies" },
  {
    label: "Health and Medical Sciences",
    value: "Health and Medical Sciences",
  },
  { label: "Heritage", value: "Heritage" },
  { label: "History", value: "History" },
  {
    label: "Horticulture and Viticulture",
    value: "Horticulture and Viticulture",
  },
  { label: "Hospitality and Tourism", value: "Hospitality and Tourism" },
  { label: "Hotel Management", value: "Hotel Management" },
  { label: "Human Resource Management", value: "Human Resource Management" },
  { label: "Humanities", value: "Humanities" },
  { label: "Industrial Engineering", value: "Industrial Engineering" },
  {
    label: "Information Science and Librarianship",
    value: "Information Science and Librarianship",
  },
  { label: "Information Systems", value: "Information Systems" },
  { label: "Innovation", value: "Innovation" },
  { label: "Insurance", value: "Insurance" },
  { label: "Interior Design", value: "Interior Design" },
  { label: "International Business", value: "International Business" },
  { label: "International Law", value: "International Law" },
  { label: "International Relations", value: "International Relations" },
  { label: "IT and Cyber Security", value: "IT and Cyber Security" },
  { label: "Journalism", value: "Journalism" },
  { label: "Justice studies", value: "Justice studies" },
  {
    label: "Landscape design and Architecture",
    value: "Landscape design and Architecture",
  },
  { label: "Language and Culture", value: "Language and Culture" },
  { label: "Language and Literature", value: "Language and Literature" },
  { label: "Law and Legal Studies", value: "Law and Legal Studies" },
  { label: "Liberal Arts and Sciences", value: "Liberal Arts and Sciences" },
  { label: "Linguistics", value: "Linguistics" },
  { label: "Literacy And Numeracy", value: "Literacy And Numeracy" },
  { label: "Logistics", value: "Logistics" },
  { label: "Manufacturing Engineering", value: "Manufacturing Engineering" },
  {
    label: "Marine Sciences and Marine Engineering",
    value: "Marine Sciences and Marine Engineering",
  },
  { label: "Marketing", value: "Marketing" },
  {
    label: "Materials and Mineral Engineering",
    value: "Materials and Mineral Engineering",
  },
  { label: "Mathematics", value: "Mathematics" },
  { label: "MBA", value: "MBA" },
  {
    label: "Mechanic and Repair Technologies",
    value: "Mechanic and Repair Technologies",
  },
  { label: "Mechanical Engineering", value: "Mechanical Engineering" },
  { label: "Media and Communication", value: "Media and Communication" },
  { label: "Media Studies", value: "Media Studies" },
  { label: "Medicine", value: "Medicine" },
  { label: "Microbiology", value: "Microbiology" },
  { label: "Military", value: "Military" },
  { label: "Mining", value: "Mining" },
  { label: "Multidisciplinary Studies", value: "Multidisciplinary Studies" },
  { label: "Music", value: "Music" },
  { label: "Nanotechnology", value: "Nanotechnology" },
  {
    label: "Natural Resources and Conservation",
    value: "Natural Resources and Conservation",
  },
  {
    label: "Northern European Languages",
    value: "Northern European Languages",
  },
  { label: "Nuclear Physics", value: "Nuclear Physics" },
  { label: "Nursing and midwifery", value: "Nursing and midwifery" },
  { label: "Nutrition", value: "Nutrition" },
  { label: "Obstetrics And Gynaecology", value: "Obstetrics And Gynaecology" },
  {
    label: "Occupational Health and Safety",
    value: "Occupational Health and Safety",
  },
  { label: "Occupational Therapy", value: "Occupational Therapy" },
  { label: "Ophthalmology", value: "Ophthalmology" },
  { label: "Optical Science", value: "Optical Science" },
  { label: "Organisation Management", value: "Organisation Management" },
  { label: "Others", value: "Others" },
  { label: "Paediatrics", value: "Paediatrics" },
  { label: "Paleontology", value: "Paleontology" },
  { label: "Paramedical Studies", value: "Paramedical Studies" },
  {
    label: "Particle and Material Physics",
    value: "Particle and Material Physics",
  },
  { label: "Performing Arts and Music", value: "Performing Arts and Music" },
  { label: "Petroleum Engineering", value: "Petroleum Engineering" },
  { label: "Pharmaceutical Science", value: "Pharmaceutical Science" },
  { label: "Pharmacy and Pharmacology", value: "Pharmacy and Pharmacology" },
  { label: "Philosophy", value: "Philosophy" },
  { label: "Photography", value: "Photography" },
  { label: "Physical Sciences", value: "Physical Sciences" },
  { label: "Physics", value: "Physics" },
  { label: "Physiology", value: "Physiology" },
  { label: "Physiotherapy", value: "Physiotherapy" },
  { label: "Plant Sciences", value: "Plant Sciences" },
  { label: "Podiatry", value: "Podiatry" },
  {
    label: "Political and Social Sciences",
    value: "Political and Social Sciences",
  },
  { label: "Process Engineering", value: "Process Engineering" },
  { label: "Product Design", value: "Product Design" },
  { label: "Project Management", value: "Project Management" },
  {
    label: "Psychiatry and Neurosurgery",
    value: "Psychiatry and Neurosurgery",
  },
  { label: "Psychology", value: "Psychology" },
  { label: "Public Health", value: "Public Health" },
  { label: "Public Relations (PR)", value: "Public Relations (PR)" },
  { label: "Publishing", value: "Publishing" },
  { label: "Quality Management", value: "Quality Management" },
  { label: "Radiography", value: "Radiography" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Rehabilitation Therapy", value: "Rehabilitation Therapy" },
  { label: "Religious Studies", value: "Religious Studies" },
  { label: "Risk Management", value: "Risk Management" },
  { label: "Robotics and Mechatronics", value: "Robotics and Mechatronics" },
  { label: "Romance Languages", value: "Romance Languages" },
  { label: "Sales And Marketing", value: "Sales And Marketing" },
  { label: "Security Services", value: "Security Services" },
  {
    label: "Social and Cultural Courses",
    value: "Social and Cultural Courses",
  },
  {
    label: "Social Policy and Administration",
    value: "Social Policy and Administration",
  },
  { label: "Social Skills Programmes", value: "Social Skills Programmes" },
  { label: "Social Work", value: "Social Work" },
  { label: "Software Engineering", value: "Software Engineering" },
  { label: "Southern Asian Languages", value: "Southern Asian Languages" },
  {
    label: "Southern European Languages",
    value: "Southern European Languages",
  },
  {
    label: "Southwest Asian And North African Languages",
    value: "Southwest Asian And North African Languages",
  },
  { label: "Speech Pathology", value: "Speech Pathology" },
  { label: "Sports Management", value: "Sports Management" },
  { label: "Sports Science", value: "Sports Science" },
  { label: "Statistics", value: "Statistics" },
  { label: "Supply Chain and Logistics", value: "Supply Chain and Logistics" },
  { label: "Surgery", value: "Surgery" },
  { label: "Surveying", value: "Surveying" },
  { label: "Systems Engineering", value: "Systems Engineering" },
  { label: "Taxation", value: "Taxation" },
  { label: "Teaching and Education", value: "Teaching and Education" },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Tourism", value: "Tourism" },
  { label: "Town and Country Planning", value: "Town and Country Planning" },
  { label: "Urban Planning", value: "Urban Planning" },
  { label: "Veterinary Science", value: "Veterinary Science" },
  { label: "Visual Arts", value: "Visual Arts" },
  { label: "Visual Communications", value: "Visual Communications" },
  { label: "Web Development", value: "Web Development" },
  { label: "Welfare Studies", value: "Welfare Studies" },
  { label: "Wildlife Conservation", value: "Wildlife Conservation" },
  { label: "Zoology", value: "Zoology" },
];

export const BOOLEAN_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const RELATION_ARRAY = [
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Spouse", value: "Spouse" },
  { label: "Uncle", value: "Uncle" },
  { label: "Aunt", value: "Aunt" },
  { label: "First Cousin", value: "First Cousin" },
  { label: "Father-in-Law", value: "Father-in-Law" },
  { label: "Mother-in-Law", value: "Mother-in-Law" },
  { label: "Brother-in-Law", value: "Brother-in-Law" },
  { label: "Sister-in-Law", value: "Sister-in-Law" },
  { label: "Grandfather", value: "Grandfather" },
  { label: "Grandmother", value: "Grandmother" },
];

export const CO_APPLICANT_PROFESSION: ISelect[] = [
  {
    label: "Salaried",
    value: "SALARIED",
  },
  {
    label: "Self-Employed",
    value: "SELF_EMPLOYED",
  },
  {
    label: "Self-Employed Professional",
    value: "SELF_EMPLOYED_PROFESSIONAL",
  },
  {
    label: "Farmers",
    value: "FARMER",
  },
  {
    label: "Pensioners",
    value: "PENSIONER",
  },
  {
    label: "Retired (without Pension)",
    value: "RETIRED_WITHOUT_PENSION",
  },
  {
    label: "Rental Income",
    value: "RENTAL_INCOME",
  },
];
