//* Packages */
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

//* Components */
import Header from "@Components/Header";
import JumpToTop from "@Components/utility/JumpToTop";

//* Utils */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

import { getCountries, getPartners } from "@Actions/app";

export default function DetailsLayout() {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCountries());
    if (role !== "lender") dispatch(getPartners());
  }, []);

  return (
    <div className="relative">
      <Header />
      <div className="p-4 min-h-screen bg-crm-bg">
        <Outlet />
      </div>
      <JumpToTop />
    </div>
  );
}
