//* Packages Imports */
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//* Components Imports */
import Filters from "@Components/Filter";

//* Types Imports */
import { FilterProps } from "@Types/common";

const QueryFilters = ({
  filterConfig,
  filterKeys,
  hasClearAll,
  handleApplyFilters,
}: FilterProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryFilters = useMemo(() => {
    const queries = new URLSearchParams(location.search);

    return filterKeys.reduce<Record<string, string>>((acc, key) => {
      const queryKey = filterConfig[key]?.queryKey;

      if (queryKey) {
        const queryValue = queries.get(queryKey);
        if (queryValue) {
          acc[queryKey] = queryValue;
        }
      }

      return acc;
    }, {});
  }, [location.search, filterKeys, filterConfig]);

  const updateQueryParams = (newFilters: Record<string, string>) => {
    const searchParams = new URLSearchParams(newFilters);
    handleApplyFilters?.(newFilters);
    navigate(
      searchParams
        ? `${location.pathname}?${searchParams.toString()}`
        : location.pathname,
      {
        replace: true,
      },
    );
  };

  return (
    <Filters
      filterKeys={filterKeys}
      handleApplyFilters={updateQueryParams}
      hasClearAll={hasClearAll}
      preAppliedFilters={getQueryFilters}
      filterConfig={filterConfig}
    />
  );
};

export default QueryFilters;
