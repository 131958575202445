//* Packages */
import useOutsideClick from "@/hooks/useOutsideClick";
import React, { useState, useRef, useEffect, ReactNode } from "react";

interface PopperProps {
  trigger: ReactNode;
  children: ReactNode;
  position?: "bottom-left" | "bottom-right";
}

const Popper: React.FC<PopperProps> = ({
  trigger,
  children,
  position = "bottom-right",
}) => {
  const [show, setShow] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const togglePopper = () => {
    setShow(!show);
  };

  useOutsideClick([triggerRef, popperRef], () => setShow(false));

  useEffect(() => {
    const triggerEl = triggerRef.current;
    const popperEl = popperRef.current;

    if (triggerEl && popperEl && show) {
      const { height } = triggerEl.getBoundingClientRect();

      const styles: Record<string, string> = {
        top: `${height * 1.5}px`,
      };

      if (position === "bottom-right") {
        styles.left = `${-triggerEl.offsetWidth / 2.75}px`;
      }
      if (position === "bottom-left") {
        styles.left = "0px";
      }

      Object.assign(popperEl.style, styles);
    }
  }, [show, position]);

  return (
    <div className="relative inline-block w-full">
      <div ref={triggerRef} onClick={togglePopper} className="cursor-pointer">
        {trigger}
      </div>

      {show && (
        <div
          ref={popperRef}
          className="cursor-default absolute z-40 bg-white rounded shadow-lg 
                      transition-opacity duration-300"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Popper;
