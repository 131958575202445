import { STATUS_COLOR } from "@Core/StatusBadge/constants";
import { StatusKey } from "@Types/common";

interface IStatusBadge {
  status: StatusKey;
  font?: "sm" | "md" | "xs" | "lg";
}

export default function StatusBadge({ status, font = "sm" }: IStatusBadge) {
  const statusOb = STATUS_COLOR[status];

  const bg = STATUS_COLOR[status]?.color;
  const text = STATUS_COLOR[status]?.text;
  const textSize = `text-${font}`;

  return (
    <div
      className={`px-3 py-1 rounded w-fit  ${textSize} font-medium ${bg} ${text || "text-white"}`}
    >
      {statusOb?.label || status}
    </div>
  );
}
