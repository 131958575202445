//* Packages Imports */
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import {
  getAcademicDetails,
  setLeadAcademicDetails,
} from "@Actions/lead/DetailsForm";
import { getCopyString } from "@Utils/common";
import { MapBackendToFrontend } from "@Axios/mapping-framework";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Assets Imports */
import AcademicDetailsIcon from "@Assets/icons/AcademicDetails.png";

//* Types Imports */
import { IAcademicDetails } from "@Types/leads";
import { COPY_ACADEMIC_DETAILS_MAPPER } from "@Mappers/lead";

const AcademicDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading, saveLoader, academicDetails } =
    useAppSelector(lead).leadDetails.academicDetailsObj;
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.academicDetails.change;
  const { leadId } = useParams();

  const formInputData: Array<InputFieldsType> = [
    {
      type: "number",
      name: "tenthMarks",
      label: "10th %",
      disabled: isDisabled,
      isFloat: true,
    },
    {
      type: "number",
      name: "twelfthMarks",
      label: "12th %",
      disabled: isDisabled,
      isFloat: true,
    },
    {
      type: "number",
      name: "bachelorMarks",
      label: "Bachelors %",
      disabled: isDisabled,
      isFloat: true,
    },
    {
      type: "number",
      name: "mastersMarks",
      label: "Masters %",
      disabled: isDisabled,
      isFloat: true,
    },
    {
      type: "number",
      name: "noOfBacklogs",
      label: "No. of Backlogs",
      disabled: isDisabled,
    },
    {
      type: "number",
      name: "workGap",
      label: "Work Gap (in months)",
      disabled: isDisabled,
    },
    {
      type: "number",
      name: "academicGap",
      label: "Academic Gap (in months)",
      disabled: isDisabled,
    },
  ];

  const inputFieldsData = useMemo(() => {
    return formInputData;
  }, []);

  const handleFormSubmit = (formData: IAcademicDetails) => {
    if (isNaN(Number(leadId))) return;

    dispatch(
      setLeadAcademicDetails({
        leadId: Number(leadId),
        payload: formData,
      }),
    );
  };

  const academicDetailsCopyContent = useMemo(() => {
    const modelMapper = new MapBackendToFrontend(COPY_ACADEMIC_DETAILS_MAPPER);

    const formattedData: Record<string, string> =
      modelMapper.mapBackendToFrontend({
        tenthMarks: academicDetails?.tenthMarks || "",
        twelfthMarks: academicDetails?.twelfthMarks || "",
        bachelorMarks: academicDetails?.bachelorMarks || "",
        mastersMarks: academicDetails?.mastersMarks || "",
        noOfBacklogs:
          academicDetails?.noOfBacklogs !== null
            ? academicDetails?.noOfBacklogs
            : "",
        workGap:
          academicDetails?.workGap !== null ? academicDetails?.workGap : "",
        academicGap:
          academicDetails?.academicGap !== null
            ? academicDetails?.academicGap
            : "",
      });

    return getCopyString(formattedData);
  }, [academicDetails]);

  useEffect(() => {
    if (leadId) dispatch(getAcademicDetails(Number(leadId)));
  }, []);

  return (
    <div>
      <div className="flex relative gap-2 mb-7">
        <img src={AcademicDetailsIcon} alt="Academic Details" />
        <h3>Academic Details</h3>
        <div className="absolute right-0 top-[6px]">
          <CopyToClipboard content={academicDetailsCopyContent} />
        </div>
      </div>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          onSubmit={(formData) => handleFormSubmit(formData)}
          gridCol={2}
          defaultValues={academicDetails || {}}
          hideSubmitButton={isDisabled}
          disableSubmitBtn={saveLoader}
        />
      )}
    </div>
  );
};

export default AcademicDetails;
