//* Packages */
import { useParams } from "react-router-dom";

//* Components */
import Tag from "@Core/Tag";
import Select from "@Core/Select";

//* Services */
import { getTags } from "@Actions/app";
import { updateTags } from "@Actions/lead";

//* Utils */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Types & Enums */
import { ITag } from "@Types/common";
import { TAG_ACTION } from "@Enums/common";

//* Assets */
import Loading from "@Assets/images/loader.gif";

interface ITags {
  leadTags: ITag[];
}

export default function Tags({ leadTags }: ITags) {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();
  const allTags = useAppSelector((state) => state.app.tags);
  const tagsLoading = useAppSelector((state) => state.lead.tagsLoading);
  const tagAccess = useAppSelector(
    (state) => state.user.access?.detail.leadTags,
  );

  const handleRemoveTag = (tag: ITag) => {
    dispatch(
      updateTags({
        leadId: Number(leadId),
        action: TAG_ACTION.REMOVE,
        tag,
      }),
    );
  };

  const addTag = (tag: ITag) => {
    dispatch(
      updateTags({
        leadId: Number(leadId),
        action: TAG_ACTION.ADD,
        tag,
      }),
    );
  };

  return (
    <div className="flex flex-col gap-1">
      <span className="text-xxs text-tag-gray">Tags</span>

      <div className="flex items-center gap-2 flex-wrap">
        {leadTags.map((tag, index) => (
          <Tag
            key={index}
            tag={tag}
            removeCallback={handleRemoveTag}
            enableRemove={tagAccess?.delete}
          />
        ))}

        {tagAccess?.add && (
          <>
            <div className="w-[170px]">
              <Select
                placeholder="Add tag"
                options={allTags}
                labelKey="name"
                valueKey="code"
                size="xxs"
                setValue={(tag: ITag) => addTag(tag)}
                disabled={tagsLoading}
                optionsLoading={tagsLoading}
                fetchOptions={() => dispatch(getTags())}
                canSearch
              />
            </div>
            {tagsLoading ? (
              <img src={Loading} alt="loader" className="w-5 h-5" />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
