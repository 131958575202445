//* Packages */
import { createSlice } from "@reduxjs/toolkit";

//* Service Imports */
import { RootState } from "@Store/index";

//* Types Imports */
import { ILeadsListingItem } from "@Types/dashboard";
import { ILeadTasks, StatusKey } from "@Types/common";

//* Interfaces Definition */
export interface IAllLeadsInitialState {
  list: ILeadsListingItem[];
  loading: boolean;
  pages: number | null;
  count: number | null;
  statusList: StatusKey[];
  statusCount: Record<StatusKey, number> | null;
  statusLoading: boolean;
}

export interface IMyTasksInitialStatel {
  list: ILeadTasks[];
  loading: boolean;
  pages: number | null;
  count: number | null;
}

interface ILeadInitialState {
  leads: IAllLeadsInitialState;
  myTasks: IMyTasksInitialStatel;
}

const initialState: ILeadInitialState = {
  leads: {
    list: [],
    loading: true,
    pages: null,
    count: null,
    statusList: [],
    statusCount: null,
    statusLoading: false,
  },
  myTasks: {
    list: [],
    loading: false,
    pages: null,
    count: null,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    setAllLeadsLoading(state, action) {
      state.leads.loading = action.payload;
    },
    setAllLeads(state, action) {
      state.leads.pages = action.payload.pages;
      state.leads.count = action.payload.count;
      state.leads.list = action.payload.list;
    },
    setStatusList(state, action) {
      state.leads.statusList = action.payload;
    },
    setStatusCountData(state, action) {
      state.leads.statusCount = action.payload;
    },
    setStatusLoading(state, action) {
      state.leads.statusLoading = action.payload;
    },
    setMyTasksLoading(state, action) {
      state.myTasks.loading = action.payload;
    },
    setMyTasks(state, action) {
      state.myTasks.pages = action.payload.pages;
      state.myTasks.count = action.payload.count;
      state.myTasks.list = action.payload.list;
    },
  },
});

export const {
  setAllLeadsLoading,
  setAllLeads,
  setStatusList,
  setStatusCountData,
  setStatusLoading,
  setMyTasksLoading,
  setMyTasks,
} = dashboardSlice.actions;

export const dashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
