//* Assets */
import UCLogo from "@Assets/images/uc-logo.png";
import PiiFields from "@Components/pages/leads/lead-details/LeadDetailsCard/PiiFields";

//* Components */
import CopyToClipboard from "@Components/utility/CopyToClipboard";

//* Utils */
import { useAppSelector } from "@Hooks/redux-hooks";
import useOpenWhatsApp from "@Hooks/useOpenWhatsApp";

export default function AgentCard() {
  const data = useAppSelector((state) => state.lead.leadData?.leadinfo.agent);

  const [, sendWhatsAppMessage] = useOpenWhatsApp(data?.phone || "", "", true);

  return (
    <div className="bg-white w-full h-12 flex items-center justify-between py-2 px-4 rounded">
      <div className="flex items-center gap-1.5">
        <img src={UCLogo} alt="uc-agent" className="w-[80px]" />
        <span className="text-xs mt-0.5">Agent</span>
      </div>

      <div className="flex items-center gap-2 text-xs ">
        <div className="w-fit text-nowrap">
          <span className="font-medium">{data?.name}</span>
        </div>

        <span className="text-lighter-gray">|</span>

        {data?.phone && (
          <>
            <div className="w-full text-nowrap">
              <PiiFields
                type="phone"
                isUnMasked
                value={data?.phone}
                onWhatsAppClick={sendWhatsAppMessage}
              />
            </div>

            <span className="text-lighter-gray">|</span>
          </>
        )}

        <div className="w-fit flex items-center">
          <span>{data?.email}</span>
          <div className="w-4 ml-1">
            <CopyToClipboard content={data?.email || ""} />
          </div>
        </div>
      </div>
    </div>
  );
}
