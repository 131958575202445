//* Packages Imports */
// import { StrictMode } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

/* //* Components Imports */
import AppRoutes from "@Router/index.tsx";

/* //* Utils Imports */
import { store, persistor } from "@Store/index.ts";

/* //* Styles Imports */
import "@/index.css";

const root = document.getElementById("root");
if (!root) throw new Error("No root element found");

createRoot(root).render(
  // <StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ToastContainer />
        <div id="_portal" className="w-full fixed z-[99]" />
        <AppRoutes />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </StrictMode>
);
