//* Packages */
import { ReactNode, Suspense } from "react";

//* Hooks */
import useOnViewLoad from "@Hooks/useOnViewLoad";

interface IRenderOnEntry {
  children: ReactNode;
  [key: string]: unknown;
}

export default function RenderOnEntry({ children, ...rest }: IRenderOnEntry) {
  const [isVisible, wrapperRef] = useOnViewLoad(0.4);

  return (
    <div {...rest} ref={wrapperRef}>
      {isVisible && (
        <Suspense fallback={<span>Loading...</span>}>{children}</Suspense>
      )}
    </div>
  );
}
