//* Packages Imports */
import { lazy, useEffect } from "react";

//* Components Imports */
import RenderOnEntry from "@Components/utility/RenderOnEntry";
import StudentDetailsSection from "@Components/pages/leads/lead-details/StudentDetailsSection";

//* Dynamic Imports */
const TimelineSection = lazy(() => import("@Components/Timeline"));
const ApplicationStatus = lazy(
  () => import("@Components/pages/leads/ApplicationStatus"),
);
const LoanDocuments = lazy(
  () => import("@Components/pages/leads/lead-details/LoanDocuments"),
);
const ApplicationTracking = lazy(
  () => import("@Components/pages/leads/lead-details/ApplicationTracking"),
);
const PartnerAssignment = lazy(
  () => import("@Components/pages/leads/lead-details/PartnerAssignment"),
);

//* Utils Imports */
import { scrollToDiv } from "@Utils/common";
import { useAppSelector } from "@Hooks/redux-hooks";

//* Styles Imports */
import Styles from "@Components/pages/leads/lead-details/LeadFormSection/LeadFormSection.module.scss";

const LeadFormSection = () => {
  const { access } = useAppSelector((state) => state.user);
  const canViewLeadDetailsForm =
    access?.detail.marketingDetails.view ||
    access?.detail.basicDetails.view ||
    access?.detail.admissionDetails.view ||
    access?.detail.academicDetails.view ||
    access?.detail.loanDetails.view ||
    access?.detail.coapplicantDetails.view;
  const canViewApplicationTracking = access?.detail.applicationTracking.view;
  const canViewApplicationStatus = access?.detail.applicationStatus.view;
  const canViewLoanDocuments =
    access?.detail.kycDocuments.view ||
    access?.detail.academicDocuments.view ||
    access?.detail.financialDocuments.view ||
    access?.detail.coapplicantDocs.view;
  const canViewTimelineLogs =
    access?.detail.timeline.view ||
    access?.detail.repeatedLead.view ||
    access?.detail.conversionData.view ||
    access?.detail.disbursalData.view;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const tabContainer = document.querySelector(`.${Styles.tabContainer}`);
      const tabContainerHeight = tabContainer?.clientHeight;
      const tabContainerTop = tabContainer?.getBoundingClientRect().top;

      if (tabContainerHeight && tabContainerTop) {
        if (tabContainerTop <= 0) {
          tabContainer.classList.add(Styles.fixedTabContainer);
        } else {
          tabContainer.classList.remove(Styles.fixedTabContainer);
        }
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <div className={Styles.leadFormSection}>
      {/* Tabs */}
      <div className={Styles.tabContainer}>
        <span
          className={Styles.tabTitle}
          onClick={() => scrollToDiv("lead-details")}
        >
          Lead Details
        </span>
        <span className={Styles.tabDivider} />
        {canViewApplicationTracking && (
          <>
            <span
              className={Styles.tabTitle}
              onClick={() => scrollToDiv("application-tracking")}
            >
              Application Tracking
            </span>
            <span className={Styles.tabDivider} />
            <span
              className={Styles.tabTitle}
              onClick={() => scrollToDiv("lender-assignment")}
            >
              Lender Assignment
            </span>
            <span className={Styles.tabDivider} />
          </>
        )}
        {canViewApplicationStatus && (
          <>
            <span
              className={Styles.tabTitle}
              onClick={() => scrollToDiv("application-status")}
            >
              Application Status
            </span>
            <span className={Styles.tabDivider} />
          </>
        )}
        {canViewLoanDocuments && (
          <>
            <span
              className={Styles.tabTitle}
              onClick={() => scrollToDiv("loan-document")}
            >
              Loan Documents
            </span>
            <span className={Styles.tabDivider} />
          </>
        )}
        {canViewTimelineLogs && (
          <span
            className={Styles.tabTitle}
            onClick={() => scrollToDiv("timeline")}
          >
            Timeline
          </span>
        )}
      </div>

      {/* Tabs Components */}
      <div className={Styles.leadFormSectionContainer}>
        {canViewLeadDetailsForm && (
          <div id="lead-details">
            <StudentDetailsSection />
          </div>
        )}

        {canViewApplicationTracking && (
          <>
            <div id="application-tracking">
              <RenderOnEntry>
                <ApplicationTracking />
              </RenderOnEntry>
            </div>
            <div id="lender-assignment">
              <RenderOnEntry>
                <PartnerAssignment />
              </RenderOnEntry>
            </div>
          </>
        )}

        {canViewApplicationStatus && (
          <div id="application-status">
            <RenderOnEntry>
              <ApplicationStatus />
            </RenderOnEntry>
          </div>
        )}

        {canViewLoanDocuments && (
          <div id="loan-document">
            <RenderOnEntry>
              <LoanDocuments />
            </RenderOnEntry>
          </div>
        )}

        {canViewTimelineLogs && (
          <div id="timeline">
            <RenderOnEntry>
              <TimelineSection />
            </RenderOnEntry>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadFormSection;
