//* Packages Imports */
import { useEffect, useState } from "react";

//* Components Imports */
import TagSelect from "@Core/TagSelect";
import Popper from "@Components/utility/Popper";
import FilterTag from "@Components/Filter/FilterTag";
import CustomDateRangePicker from "@Components/core/Datepicker";

//* Utils Imports */
import debounce from "@Utils/debounce";
import { useAppSelector } from "@Hooks/redux-hooks";
import { getParamDateString } from "@Utils/dateUtils";

//* Types Imports */
import { FilterProps } from "@Types/common";

//* Styles Imports */
import Styles from "@Components/Filter/Filters.module.scss";

const Filters = ({
  filterConfig,
  filterKeys,
  hasClearAll = false,
  handleApplyFilters,
  preAppliedFilters = {},
}: FilterProps) => {
  const appData = useAppSelector((state) => state.app);
  const appLoading = useAppSelector((state) => state.app.appLoading);

  const [appliedFilters, setAppliedFilters] = useState<Record<string, string>>({
    ...preAppliedFilters,
  });

  const debouncedFiltersApply = debounce((filters: Record<string, string>) => {
    handleApplyFilters?.(filters);
  }, 300);

  const handleFilterChange = (key: string, value: string) => {
    if (!key) return;

    setAppliedFilters((currentFilters) => {
      const updatedFilters = { ...currentFilters };

      if (value) {
        updatedFilters[key] = value;
      } else {
        delete updatedFilters[key];
      }

      debouncedFiltersApply(updatedFilters);
      return updatedFilters;
    });
  };

  const handleClearAll = () => {
    setAppliedFilters({});
    debouncedFiltersApply({});
  };

  const getFilterComponent = (config: any) => {
    switch (config.type) {
      case "daterange":
        return (
          <Popper
            trigger={
              <FilterTag
                filterKey="date_range"
                label="Date Range"
                value={appliedFilters?.date_range}
                handleClear={(key: string) => handleFilterChange(key, "")}
              />
            }
            position="bottom-left"
          >
            <div className="p-4 w-[300px]">
              <CustomDateRangePicker
                setSelectedDateObj={(ob: any) => {
                  const val = `${getParamDateString(ob.startDate)}:${getParamDateString(ob.endDate)}`;
                  handleFilterChange("date_range", val);
                }}
                type="range"
              />
            </div>
          </Popper>
        );

      case "list":
        return (
          <TagSelect
            {...config}
            setValue={(newValue) => {
              const cloned = structuredClone(newValue) || [];
              const selectedValues = cloned
                .map((val: any) => val[config?.valueKey || "value"])
                .join(",");

              handleFilterChange(config.queryKey, selectedValues);
            }}
            defaults={appliedFilters[config.queryKey]}
            options={
              config.optionsKey
                ? appData[config.optionsKey as keyof typeof appData]
                : config.options
            }
          />
        );
      default:
        break;
    }
    return <></>;
  };

  useEffect(() => {
    handleApplyFilters?.(appliedFilters);
  }, []);

  return appLoading ? (
    <div className="text-xs h-[26px]">Fetching filters</div>
  ) : (
    <div className={Styles.filtersContainer}>
      {filterKeys.map((filter) => (
        <div key={filter} className={Styles.filterBlock}>
          <div className={Styles.filterElement}>
            {getFilterComponent(filterConfig[filter])}
          </div>
        </div>
      ))}
      {Object.keys(appliedFilters).length > 0 && hasClearAll && (
        <button className={Styles.clearFilters} onClick={handleClearAll}>
          Clear All
        </button>
      )}
    </div>
  );
};

export default Filters;
