//* Packages */
import { createSlice } from "@reduxjs/toolkit";

//* Service Imports */
import { RootState } from "@Store/index";

//* Types Imports */
import { Agent, ILead, LeadTeamType } from "@Types/leads";
import {
  INoteDetails,
  ILeadAssigneeUsers,
  ITag,
  ILeadTasks,
  ILeadTaskCategory,
} from "@Types/common";
import {
  IAdmissionDetails,
  IBasicDetails,
  ICoApplicantDetails,
  ILoanDetails,
  IMarketingDetails,
  ILeadLoanApplication,
} from "@Types/leads";
import {
  IConversionDisplayData,
  IDisbursalDisplayData,
  IRepeatedLead,
  ITimelineLogs,
} from "@Types/timeline";
import { LoanDocs } from "@Types/documents";
import { DISBURSEMENT_PROOF_STATUS } from "@Enums/common";

//* Interfaces Definition */
export interface INoteInitialState {
  list: INoteDetails[];
  upcomingLink: string;
  hasMore: boolean;
  isCreateDisabled: boolean;
}

export interface ILeadDetailsForm {
  marketingDetailsObj: {
    isLoading: boolean;
    marketingDetails: IMarketingDetails | null;
  };
  basicDetailsObj: {
    isLoading: boolean;
    saveLoader: boolean;
    basicDetails: IBasicDetails | any;
  };
  admissionDetailsObj: {
    isLoading: boolean;
    saveLoader: boolean;
    admissionDetails: IAdmissionDetails | any;
  };
  academicDetailsObj: {
    isLoading: boolean;
    saveLoader: boolean;
    academicDetails: any;
  };
  loanDetailsObj: {
    isLoading: boolean;
    saveLoader: boolean;
    loanDetails: ILoanDetails | any;
  };
  coApplicantDetailsObj: {
    isLoading: boolean;
    saveLoader: boolean;
    coApplicantDetails: ICoApplicantDetails[] | any;
  };
}

export interface ITaskInitialState {
  list: ILeadTasks[];
  upcomingLink: string;
  hasMore: boolean;
  activeTasksCount: null | number;
  completedTasksCount: null | number;
  filters: Record<string, boolean | string>;
  isWriteDisabled: boolean;
  categories: ILeadTaskCategory[];
}

interface ILeadTeamData {
  loading: false;
  list?: Agent[];
}

interface ILeadInitialState {
  unMasking: {
    isEmailUnMasked: boolean;
    isPhoneUnMasked: boolean;
    isAltPhoneUnMasked: boolean;
    loadingField: string[];
  };
  leadData: null | ILead;
  leadErrorStatus?: "NOT_FOUND" | "NO_ACCESS" | null;
  leadDataLoading: boolean;
  logs: {
    timeline: {
      timelineLoading: boolean;
      timelineData: ITimelineLogs[];
    };
    disbursal: {
      disbursalLoading: boolean;
      disbursalData: IDisbursalDisplayData[];
      saveLoader: boolean;
      showModal: boolean;
      reviewDocument: {
        reviewModal: boolean;
        saveLoader: DISBURSEMENT_PROOF_STATUS | "";
      };
    };
    conversion: {
      conversionLoading: boolean;
      conversionData: IConversionDisplayData[];
    };
    repeatedLead: {
      repeatedLeadLoading: boolean;
      repeatedLeadsData: IRepeatedLead[];
    };
  };
  tagsLoading: boolean;
  teamData: {
    [K in LeadTeamType]?: ILeadTeamData;
  };
  notes: INoteInitialState;
  tasks: ITaskInitialState;
  assignees: ILeadAssigneeUsers[];
  leadDetails: ILeadDetailsForm;
  partners: {
    assigned: number[];
    loading: boolean;
    saving: boolean;
    status: "READY" | "NOT_READY" | "PARTIALLY_READY";
  };
  loanApplications: { list: ILeadLoanApplication[]; loading: boolean };
  loanDocuments: LoanDocs | null;
  loadingDocuments: boolean;
  uploadingDocument: boolean;
}

const initialState: ILeadInitialState = {
  unMasking: {
    isEmailUnMasked: false,
    isPhoneUnMasked: false,
    isAltPhoneUnMasked: false,
    loadingField: [],
  },
  leadDataLoading: true,
  leadData: null,
  leadErrorStatus: null,
  tagsLoading: false,
  teamData: {},
  notes: {
    list: [],
    upcomingLink: "",
    hasMore: true,
    isCreateDisabled: false,
  },
  tasks: {
    list: [],
    upcomingLink: "",
    hasMore: true,
    activeTasksCount: null,
    completedTasksCount: null,
    filters: { isCompleted: false },
    isWriteDisabled: false,
    categories: [],
  },
  assignees: [],
  leadDetails: {
    marketingDetailsObj: {
      isLoading: false,
      marketingDetails: null,
    },
    basicDetailsObj: {
      isLoading: false,
      saveLoader: false,
      basicDetails: null,
    },
    admissionDetailsObj: {
      isLoading: false,
      saveLoader: false,
      admissionDetails: null,
    },
    academicDetailsObj: {
      isLoading: false,
      saveLoader: false,
      academicDetails: null,
    },
    loanDetailsObj: {
      isLoading: false,
      saveLoader: false,
      loanDetails: null,
    },
    coApplicantDetailsObj: {
      isLoading: false,
      saveLoader: false,
      coApplicantDetails: null,
    },
  },
  partners: {
    assigned: [],
    loading: false,
    saving: false,
    status: "READY",
  },
  logs: {
    timeline: {
      timelineData: [],
      timelineLoading: false,
    },
    disbursal: {
      disbursalData: [],
      disbursalLoading: false,
      saveLoader: false,
      showModal: false,
      reviewDocument: {
        reviewModal: false,
        saveLoader: "",
      },
    },
    conversion: {
      conversionLoading: false,
      conversionData: [],
    },
    repeatedLead: {
      repeatedLeadLoading: false,
      repeatedLeadsData: [],
    },
  },
  loanApplications: {
    list: [],
    loading: false,
  },
  loanDocuments: null,
  loadingDocuments: true,
  uploadingDocument: false,
};

export const leadSlice = createSlice({
  name: "leadSlice",
  initialState,
  reducers: {
    setLeadDataLoading(state, action) {
      state.leadDataLoading = action.payload;
    },
    setRepeatLeadsData(state, action) {
      state.logs.repeatedLead.repeatedLeadsData = action.payload;
    },
    setRepeatedLeadLoading(state, action) {
      state.logs.repeatedLead.repeatedLeadLoading = action.payload;
    },
    setTimelineLoading(state, action) {
      state.logs.timeline.timelineLoading = action.payload;
    },
    setDisbursalLoading(state, action) {
      state.logs.disbursal.disbursalLoading = action.payload;
    },
    setDisbursalSaveLoader(state, action) {
      state.logs.disbursal.saveLoader = action.payload;
    },
    setDisbursalForm(state, action) {
      state.logs.disbursal.showModal = action.payload;
    },
    setTimelineLogs(state, action) {
      state.logs.timeline.timelineData = action.payload;
    },
    setConversionLoading(state, action) {
      state.logs.conversion.conversionLoading = action.payload;
    },
    setDisbursalData(state, action) {
      state.logs.disbursal.disbursalData = action.payload;
    },
    updateDisbursalData(state, action) {
      const disbursalStoreData = state.logs.disbursal.disbursalData;
      const disbursalId = disbursalStoreData.findIndex(
        (item) => item.id.value === action.payload.id.value,
      );
      if (disbursalId !== -1) {
        disbursalStoreData[disbursalId] = { ...action.payload };
      } else {
        disbursalStoreData.push(action.payload);
      }
    },
    setConversionData(state, action) {
      state.logs.conversion.conversionData = action.payload;
    },
    setLeadData(state, action) {
      state.leadData = action.payload;
    },
    addLeadTag(state, action) {
      if (state.leadData) state.leadData.tags.push(action.payload);
    },
    setLeadErrorStatus(state, action) {
      state.leadErrorStatus = action.payload;
    },
    removeLeadTag(state, action) {
      if (state.leadData)
        state.leadData.tags = state.leadData.tags.filter(
          (tag: ITag) => tag.code !== action.payload.code,
        );
    },
    setTagsLoading(state, action) {
      state.tagsLoading = action.payload;
    },
    updateTeamData(state, action) {
      state.teamData = { ...state.teamData, ...action.payload };
    },
    unshiftNotes(state, action) {
      state.notes.list.unshift(action.payload);
    },
    addNotes(state, action) {
      state.notes.list.push(...action.payload);
    },
    setNotesUpcomingLink(state, action) {
      state.notes.upcomingLink = action.payload;
    },
    setHasMoreNotes(state, action) {
      state.notes.hasMore = action.payload;
    },
    setNoteCreateDisabled(state, action) {
      state.notes.isCreateDisabled = action.payload;
    },
    resetNotes(state) {
      state.notes = {
        list: [],
        upcomingLink: "",
        hasMore: true,
        isCreateDisabled: false,
      };
    },
    setLeadAssignees(state, action) {
      state.assignees = action.payload;
    },
    setMarketingLoading(state, action) {
      state.leadDetails.marketingDetailsObj.isLoading = action.payload;
    },
    setMarketingDetails(state, action) {
      state.leadDetails.marketingDetailsObj.marketingDetails = action.payload;
    },
    setBasicDetailsLoading(state, action) {
      if (action.payload.type === "save") {
        state.leadDetails.basicDetailsObj.saveLoader = action.payload.loading;
      } else {
        state.leadDetails.basicDetailsObj.isLoading = action.payload.loading;
      }
    },
    setBasicDetails(state, action) {
      state.leadDetails.basicDetailsObj.basicDetails = action.payload;
    },
    setAdmissionDetailsLoading(state, action) {
      if (action.payload.type === "save") {
        state.leadDetails.admissionDetailsObj.saveLoader =
          action.payload.loading;
      } else {
        state.leadDetails.admissionDetailsObj.isLoading =
          action.payload.loading;
      }
    },
    setAdmissionDetails(state, action) {
      state.leadDetails.admissionDetailsObj.admissionDetails = action.payload;
    },
    setAcademicDetailsLoading(state, action) {
      if (action.payload.type === "save") {
        state.leadDetails.academicDetailsObj.saveLoader =
          action.payload.loading;
      } else {
        state.leadDetails.academicDetailsObj.isLoading = action.payload.loading;
      }
    },
    setAcademicDetails(state, action) {
      state.leadDetails.academicDetailsObj.academicDetails = action.payload;
    },
    setLoanDetailsLoading(state, action) {
      if (action.payload.type === "save") {
        state.leadDetails.loanDetailsObj.saveLoader = action.payload.loading;
      } else {
        state.leadDetails.loanDetailsObj.isLoading = action.payload.loading;
      }
    },
    setLoanDetails(state, action) {
      state.leadDetails.loanDetailsObj.loanDetails = action.payload;
    },
    setCoApplicantDetailsLoading(state, action) {
      if (action.payload.type === "save") {
        state.leadDetails.coApplicantDetailsObj.saveLoader =
          action.payload.loading;
      } else {
        state.leadDetails.coApplicantDetailsObj.isLoading =
          action.payload.loading;
      }
    },
    setCoApplicantDetails(state, action) {
      state.leadDetails.coApplicantDetailsObj.coApplicantDetails =
        action.payload;
    },
    updateCoApplicantDetails(state, action) {
      const coApplicantId =
        state.leadDetails.coApplicantDetailsObj.coApplicantDetails.findIndex(
          (coApplicant: ICoApplicantDetails) =>
            coApplicant?.id === action.payload.id,
        );

      if (coApplicantId !== -1) {
        state.leadDetails.coApplicantDetailsObj.coApplicantDetails[
          coApplicantId
        ] = {
          ...action.payload,
        };
      } else {
        state.leadDetails.coApplicantDetailsObj.coApplicantDetails.push(
          action.payload,
        );
      }
    },
    removeCoApplicantDetails(state, action) {
      const coApplicantId =
        state.leadDetails.coApplicantDetailsObj.coApplicantDetails.findIndex(
          (coApplicant: ICoApplicantDetails) =>
            coApplicant?.id === action.payload,
        );

      if (coApplicantId !== -1) {
        state.leadDetails.coApplicantDetailsObj.coApplicantDetails.splice(
          coApplicantId,
          1,
        );
      }
    },
    unshiftTasks(state, action) {
      state.tasks.list.unshift(action.payload);
    },
    addTasks(state, action) {
      state.tasks.list.push(...action.payload);
    },
    setTasksUpcomingLink(state, action) {
      state.tasks.upcomingLink = action.payload;
    },
    setHasMoreTasks(state, action) {
      state.tasks.hasMore = action.payload;
    },
    setActiveTaskCount(state, action) {
      state.tasks.activeTasksCount = action.payload;
    },
    setCompletedTaskCount(state, action) {
      state.tasks.completedTasksCount = action.payload;
    },
    setTaskFilters(state, action) {
      state.tasks.filters = action.payload;
    },
    replaceTask(state, action) {
      const taskId = state.tasks.list.findIndex(
        (task) => task.id === action.payload.id,
      );
      state.tasks.list[taskId] = action.payload;
    },
    setTaskWriteDisabled(state, action) {
      state.tasks.isWriteDisabled = action.payload;
    },
    setTaskCategories(state, action) {
      state.tasks.categories = action.payload;
    },
    resetTaskList(state) {
      state.tasks = {
        ...state.tasks,
        list: [],
        upcomingLink: "",
        hasMore: true,
      };
    },
    setAssignedPartners(state, action) {
      state.partners.assigned = action.payload;
    },
    setSavingPartners(state, action) {
      state.partners.saving = action.payload;
    },
    setAssignmentStatus(state, action) {
      state.partners.status = action.payload;
    },
    setAssignmentStatusRefresh(state, action) {
      state.partners.loading = action.payload;
    },
    setLoanApplicationsLoading(state, action) {
      state.loanApplications.loading = action.payload;
    },
    setLoanApplications(state, action) {
      state.loanApplications.list = action.payload;
    },
    replaceLoanApplication(state, action) {
      const applicationId = state.loanApplications.list.findIndex(
        (task) => task.id === action.payload.id,
      );
      state.loanApplications.list[applicationId] = action.payload;
    },
    setDocumentsLoading(state, action) {
      state.loadingDocuments = action.payload;
    },
    setUploadingDocument(state, action) {
      state.uploadingDocument = action.payload;
    },
    setDocuments(state, action) {
      state.loanDocuments = action.payload;
    },
    setPhoneIsMasked(state, action) {
      state.unMasking.isPhoneUnMasked = action.payload;
    },
    setEmailIsMasked(state, action) {
      state.unMasking.isEmailUnMasked = action.payload;
    },
    setUnMaskingPhone(state, action) {
      if (state.leadData) {
        state.leadData.phone = action.payload;
      }
    },
    setUnMaskingEmail(state, action) {
      if (state.leadData) {
        state.leadData.email = action.payload;
      }
    },
    setAltPhoneIsMasked(state, action) {
      if (state.leadData) {
        state.unMasking.isAltPhoneUnMasked = action.payload;
      }
    },
    setUnMaskingLoadingVariable(state, action) {
      state.unMasking.loadingField = action.payload;
    },
    setUnMaskingAltPhone(state, action) {
      if (state.leadData) {
        state.leadData.altPhone = action.payload;
      }
    },
    updateDisbursalDocumentStatus(state, action) {
      const disbursalsData = state.logs.disbursal.disbursalData;
      const { status, comment, disbursalId } = action.payload;
      state.logs.disbursal.disbursalData = disbursalsData.map((disbursal) => {
        if (disbursal.id.value === disbursalId) {
          return {
            ...disbursal,
            proofOfDisbursal: {
              ...disbursal.proofOfDisbursal,
              value: {
                ...disbursal.proofOfDisbursal.value,
                status,
                comment,
              },
            },
          };
        }
        return disbursal;
      });
    },
    setReviewDisbursalModel(state, action) {
      state.logs.disbursal.reviewDocument.reviewModal = action.payload;
    },
    setReviewDisbursalLoader(state, action) {
      state.logs.disbursal.reviewDocument.saveLoader = action.payload;
    },
  },
});

export const {
  updateDisbursalDocumentStatus,
  setReviewDisbursalModel,
  setReviewDisbursalLoader,
  setRepeatedLeadLoading,
  setRepeatLeadsData,
  setUnMaskingAltPhone,
  setPhoneIsMasked,
  setEmailIsMasked,
  setUnMaskingPhone,
  setUnMaskingEmail,
  setLeadDataLoading,
  setDisbursalForm,
  setTimelineLoading,
  setConversionLoading,
  setDisbursalLoading,
  setDisbursalSaveLoader,
  setDisbursalData,
  setTimelineLogs,
  setConversionData,
  setLeadData,
  addLeadTag,
  setLeadErrorStatus,
  removeLeadTag,
  setTagsLoading,
  unshiftNotes,
  addNotes,
  setNotesUpcomingLink,
  setHasMoreNotes,
  resetNotes,
  setNoteCreateDisabled,
  setLeadAssignees,
  setMarketingLoading,
  setMarketingDetails,
  setBasicDetailsLoading,
  setBasicDetails,
  setAdmissionDetailsLoading,
  setAdmissionDetails,
  setAcademicDetailsLoading,
  setAcademicDetails,
  setLoanDetailsLoading,
  setLoanDetails,
  setCoApplicantDetailsLoading,
  setCoApplicantDetails,
  updateCoApplicantDetails,
  removeCoApplicantDetails,
  unshiftTasks,
  addTasks,
  setTasksUpcomingLink,
  setHasMoreTasks,
  setActiveTaskCount,
  setCompletedTaskCount,
  setTaskFilters,
  replaceTask,
  resetTaskList,
  setTaskWriteDisabled,
  setTaskCategories,
  setAssignedPartners,
  setSavingPartners,
  setAssignmentStatus,
  setLoanApplications,
  replaceLoanApplication,
  setLoanApplicationsLoading,
  setDocumentsLoading,
  setUploadingDocument,
  setDocuments,
  updateTeamData,
  setAssignmentStatusRefresh,
  updateDisbursalData,
  setUnMaskingLoadingVariable,
  setAltPhoneIsMasked,
} = leadSlice.actions;

export const lead = (state: RootState) => state.lead;

export default leadSlice.reducer;
