//* Packages */
import React from "react";
import { ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

//* Components */
import Toast, { IToast } from "@Core/Toast";

class ToastService {
  notify = (
    variant: IToast["variant"],
    title: IToast["title"],
    message: IToast["message"] = "",
    actionCallback?: IToast["actionCallback"],
    options?: ToastOptions,
  ) => {
    toast(
      React.createElement(Toast, {
        variant,
        message,
        title,
        actionCallback,
      }),
      {
        hideProgressBar: true,
        position: "bottom-right",
        autoClose: 3500,
        closeOnClick: true,
        closeButton: false,
        pauseOnFocusLoss: true,
        ...options,
      },
    );
  };
}

export const toastService = new ToastService();
