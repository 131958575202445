/*
Safely HTML unescapes chars escaped by security 
middleware in Backend but required on Frontend
*/
export const safeHtmlUnescape = (input: string) => {
  return input
    .replace(/&amp;/gm, "&")
    .replace(/&#39;/gm, "'")
    .replace(/&quot;/gm, '"')
    .replace(/&lt;/gm, "<")
    .replace(/&gt;/gm, ">");
};
