//* Types & Enums
import { ITag } from "@Types/common";

interface ITagProps {
  tag: ITag;
  enableRemove?: boolean;
  removeCallback?: (tag: ITag) => void;
}

export default function Tag({
  tag,
  enableRemove = false,
  removeCallback,
}: ITagProps) {
  return (
    <div className="flex items-center gap-1.5 bg-tag-light-gray w-fit text-xxs py-1 px-2.5 font-medium rounded-md">
      {tag.name}
      {enableRemove && (
        <div
          className="text-placeholder-gray font-light text-sm cursor-pointer mt-0.5"
          onClick={() => removeCallback?.(tag)}
        >
          &times;
        </div>
      )}
    </div>
  );
}
