//* Assets */
import YellowAlert from "@Assets/icons/yellow-error.png";
import PaperClip from "@Assets/icons/paperclip.png";

//* Components */
import Button from "@Core/Button";
import Input from "@Core/Input";

export default function RaiseIssueModal() {
  return (
    <div className="flex flex-col justify-between w-[486px] min-h-[368px]">
      <div className="flex items-center gap-4">
        <img src={YellowAlert} className="w-5 h-5" alt="Raise Issue" />
        <span className="text-xl font-medium">Raise an Issue</span>
      </div>

      <div className="flex flex-col">
        <Input
          placeholder="Please provide a detailed description of the problem"
          label="Describe your issue here"
          required
          multiline
          rows={12}
          className="resize-none bg-white"
        />
      </div>

      <div className="flex items-center justify-between">
        <Button font="xs" variant="outline" classes="w-fit">
          <div className="flex items-center gap-2">
            <img src={PaperClip} alt="attach-files" className="w-4 h-4" />
            Attach Files
          </div>
        </Button>
        <Button font="xs" classes="w-[180px]">
          Submit
        </Button>
      </div>
    </div>
  );
}
