//* Packages Imports */
import clsx from "clsx";

//* Component Imports */
import { STATUS_COLOR } from "@Core/StatusBadge/constants";

//* Utils Imports */
import { ILeadTasks } from "@Types/common";
import { calculateTimeDifference } from "@Utils/timeUtils";

//* Styles Imports */
import Styles from "@Components/MyTasks/MyTasks.module.scss";

export const MyTaskHeader = ({ isLender }: { isLender: boolean }) => {
  const headerColumns = [
    "Lead Info",
    isLender ? "Application Status" : "Lead Status",
    "Task Category",
    "Description",
    "Created",
    "Assigned to",
    "Due Date",
  ];
  return (
    <div className={Styles.tasksHeader}>
      {headerColumns.map((title: string) => (
        <div key={title}>{title}</div>
      ))}
    </div>
  );
};

const MyTaskCard = ({
  task,
  isLender,
}: {
  task: ILeadTasks;
  isLender: boolean;
}) => {
  const taskStatus = isLender ? task.applicationStatus : task.leadStatus;
  const currentDelay =
    !task.isCompleted &&
    calculateTimeDifference(new Date(task.remindAt), new Date());
  const pastDelay =
    task.isCompleted && new Date(task.remindAt) < new Date(task.updatedAt);

  return (
    <div key={task.id} className={Styles.myTaskCard}>
      <div>
        {task.lead.name}{" "}
        <a href={task.leadLink} target="_blank" rel="noreferrer">
          (#{task.lead?.id})
        </a>
      </div>
      <div>
        {taskStatus && STATUS_COLOR[taskStatus] ? (
          <>
            <span
              className={clsx(
                Styles.statusBanner,
                STATUS_COLOR[taskStatus]?.color,
              )}
            />
            {STATUS_COLOR[taskStatus]?.label}
          </>
        ) : (
          "-"
        )}
      </div>
      <div>{task.taskCategory?.category || "-"}</div>
      <div
        className={clsx(Styles.subscript, Styles.lineClamp)}
        title={task.summary}
      >
        {task.summary || "-"}
      </div>
      <div className={Styles.bottomAligned}>
        {task.createdBy?.name}
        <span className={Styles.subscript}>{task.createdAt || "-"}</span>
      </div>
      <div>{task.assignee?.name || "-"}</div>
      <div className={Styles.bottomAligned}>
        {task.isCompleted && (
          <span className="text-disbursed">
            Completed {pastDelay && <span className="text-error">(Delay)</span>}
          </span>
        )}
        {currentDelay ? (
          <span className="text-error">Delayed by {currentDelay}</span>
        ) : (
          <span className={clsx(task.isCompleted && Styles.subscript)}>
            {task.remindAt}
          </span>
        )}
      </div>
    </div>
  );
};

export default MyTaskCard;
