//* Package Imports */
import { useEffect } from "react";

const changePageTitle = (pageTitle: string) => {
  useEffect(() => {
    if (!pageTitle) return;
    const prevTitle = document.title;
    document.title = pageTitle || "UniCreds - CRM";

    return () => {
      document.title = prevTitle;
    };
  }, [pageTitle]);
};

export default changePageTitle;
