//* Components Imports */
import EmailLoginForm from "@Components/LoginForm/EmailLoginForm";
import GoogleLogin from "@Components/LoginForm/GoogleLogin";

//* Utils Imports */
import UniCredsLogo from "@Assets/icons/UniCredsLogo.png";

const UserLogin = () => {
  return (
    <section className="flex flex-col gap-14">
      <div className="flex flex-col gap-3">
        <img src={UniCredsLogo} alt="UniCreds Logo" className="w-12 h-10" />
        <h1 className="text-xl">Welcome to UniCreds!</h1>
      </div>
      <div className="flex flex-col gap-7">
        <GoogleLogin />
        <div className="flex text-center justify-center gap-3 text-dark-gray">
          <span className="w-20 mx-0 my-auto">
            <hr className="border-outline-button-gray" />
          </span>
          OR
          <span className="w-20 mx-0 my-auto">
            <hr className="border-outline-button-gray" />
          </span>
        </div>
        <EmailLoginForm />
      </div>
      {/* <div className="text-xs font-normal text-gray">
        <span>Having trouble signing in?</span> <a href="#" className="font-semibold text-black">Contact us </a>
      </div> */}
    </section>
  );
};

export default UserLogin;
