//* Packages Import */
import { useEffect, useMemo, useState } from "react";

//* Assets Import */
import AddUser from "@Assets/icons/add-user.png";
import Loader from "@Assets/images/loader.gif";

//* Redux */
import { getCityState } from "@Actions/lead/DetailsForm";
import { getUtmSources } from "@Actions/app";
import { ADMISSION_STATUS, AREA_OF_STUDY } from "@Constants/leadDetailsData";

//* Utils */

import API from "@Axios/main";
import { USER_TYPE } from "@Enums/common";
import { CityState } from "@Types/common";
import { toastService } from "@Utils/toast";
import { LEAD_DETAILS } from "@Constants/urls";
import { getParamDateString } from "@Utils/dateUtils";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Components Import */
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

export default function NewLead() {
  const dispatch = useAppDispatch();

  const _API = new API("v1");

  const { countries, appLoading, utmSources } = useAppSelector(
    (state) => state.app,
  );
  const role = useAppSelector((state) => state.user.role);

  const [fetchCityState, setfetchCityState] = useState("");
  const [savingLead, setSavingLead] = useState(false);
  const [cityState, setCityState] = useState<CityState>({
    pincode: "",
    city: "",
    state: "",
  });

  const getBasicDefaultValues = useMemo(() => {
    if (cityState.city && cityState.state) {
      return {
        pincode: cityState.pincode,
        city: cityState.city,
        state: cityState.state,
      };
    }

    return {};
  }, [cityState]);

  const fetchPincode = async (formObj: any) => {
    if (formObj?.formDataObj?.pincode === "") {
      setCityState({
        pincode: "",
        city: "-",
        state: "-",
      });
      return;
    }
    if (
      !formObj?.formDataObj?.pincode ||
      formObj.formDataObj.pincode.length !== 6
    )
      return;

    setfetchCityState("Fetching City and State ...");
    const response: CityState | undefined = await getCityState(
      formObj.formDataObj.pincode || "",
    );
    if (response) {
      setCityState({
        pincode: formObj.formDataObj.pincode,
        ...response,
      });
    }
    setfetchCityState("");
  };

  const inputFieldData: InputFieldsType[] = [
    {
      type: "text",
      label: "Full Name",
      name: "fullName",
      required: true,
    },
    {
      type: "email",
      label: "Email",
      name: "email",
      required: true,
    },
    {
      type: "phone",
      label: "Mobile Number",
      name: "phone",
      required: true,
      countryCodeName: "countryCode",
    },

    {
      type: "text",
      name: "pincode",
      label: "Pincode",
      fetchingData: fetchCityState,
      callback(formObj: any) {
        fetchPincode(formObj);
      },
      pattern: "^\\d{6}$",
    },
    {
      type: "text",
      label: "City",
      name: "city",
      disabled: true,
    },
    {
      type: "text",
      label: "State",
      name: "state",
      disabled: true,
    },
    {
      type: "select",
      label: "Destination Country",
      name: "country",
      options: countries,
      required: true,
      labelKey: "name",
      valueKey: "id",
    },
    {
      type: "date",
      label: "Intake",
      name: "intake",
      datePickerType: "single",
      disableMonthSelection: false,
      disableYearSelection: false,
      required: true,
    },
    {
      type: "number",
      label: "Loan Amount",
      name: "loanAmount",
      required: true,
    },
    {
      type: "select",
      label: "Area of Study",
      name: "areaOfStudy",
      canSearch: true,
      options: AREA_OF_STUDY,
    },
    {
      type: "select",
      label: "Admission Status",
      name: "admissionStatus",
      options: ADMISSION_STATUS,
    },
    {
      type: "select",
      label: "UTM Source",
      name: "utmSource",
      canSearch: true,
      options: utmSources,
      required: true,
    },
    {
      type: "text",
      label: "Comments",
      name: "comments",
      required: true,
    },
  ];

  const handleSubmit = async (val: any) => {
    const payload = {
      name: val?.fullName,
      email: val?.email,
      comments: val?.comments,
      pincode: val?.pincode || "",
      permanent_city: val?.city || "",
      state: val?.state || "",
      country: val?.country?.id,
      phone: val?.countryCode?.dialCode + val?.phone,
      loan_amount: val?.loanAmount,
      university_join_time: getParamDateString(val?.intake?.selectedDate),
      area_of_study: val?.areaOfStudy?.value || "",
      collateral: val?.collateral?.value,
      utm_source: val?.utmSource?.value,
      admission_status: val?.admissionStatus?.value || "",
    };

    try {
      setSavingLead(true);
      await _API.post(`${LEAD_DETAILS}/`, payload);
      toastService.notify("success", "Lead created successfully!");
      window.location.href = "/";
    } catch (e: any) {
      console.error(e);
      toastService.notify("error", "Error creating lead, please try again!");
    } finally {
      setSavingLead(false);
    }
  };

  useEffect(() => {
    if (role && role === USER_TYPE.LENDER) {
      window.location.href = "/";
      toastService.notify(
        "error",
        "You are not authorized to access this page",
      );
      return;
    }

    if (!utmSources.length) dispatch(getUtmSources());
  }, []);

  return (
    <div className="flex items-center justify-center">
      {appLoading ? (
        <div className="flex flex-col gap-2 items-center mt-[100px]">
          <img
            src={Loader}
            alt="Loading"
            className="w-10 h-10 mix-blend-multiply"
          />
          <span className="text-sm text-gray">Fetching values</span>
        </div>
      ) : (
        <div className="w-[925px] bg-white border border-gray-border rounded-md px-6 py-5 mt-10">
          <div className="flex items-center gap-1">
            <img src={AddUser} alt="add-user" className="w-6 h-6" />
            <span className="text-black text-lg">Add New Lead</span>
          </div>

          <div className="mt-5">
            <FormGenerator
              inputFieldsData={inputFieldData}
              submitCtaText="Submit"
              gridCol={2}
              onSubmit={handleSubmit}
              labelPosition="top"
              defaultValues={getBasicDefaultValues}
              disableSubmitBtn={savingLead}
            />
          </div>
        </div>
      )}
    </div>
  );
}
