//* Packages */
import { useMemo, useState } from "react";

//* Assets */
import Location from "@Assets/icons/location.png";
import University from "@Assets/icons/university.png";
import Rupee from "@Assets/icons/rupee.png";
import Phone from "@Assets/icons/phone.png";
import Mail from "@Assets/icons/mail.png";
import Loading from "@Assets/images/loader.gif";

//* Components */
import StatusBadge from "@Components/core/StatusBadge";
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import Tags from "@Components/pages/leads/lead-details/LeadDetailsCard/Tags";
import DataWidget from "@Components/pages/leads/lead-details/LeadDetailsCard/DataWidget";
import Agents from "@Components/pages/leads/lead-details/LeadDetailsCard/Agents";
import PiiFields from "@Components/pages/leads/lead-details/LeadDetailsCard/PiiFields";

//* Utils */
import { userData } from "@Slice/AuthSlice";
import { copyLeadDetails, getMaskedInfo } from "@Actions/lead";
import {
  capitalizeText,
  convertToTitleCase,
  formatCurrency,
  getCopyString,
} from "@Utils/common";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import useOpenWhatsApp from "@Hooks/useOpenWhatsApp";

//* Types & Enums */
import { ITag, MaskableFields } from "@Types/common";
import {
  AGENT_TEAM_LIST,
  LENDER_TEAM_LIST,
  StatusKeyEnum,
  USER_TYPE,
} from "@Enums/common";

export default function LeadDetailsCard() {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(userData);
  const { leadData, unMasking } = useAppSelector((state) => state.lead);
  const [copyLeadDetailsLoading, setCopyLeadDetailsLoading] = useState(false);

  const [, sendWhatsAppMessage] = useOpenWhatsApp(
    leadData?.phone as string,
    "",
    unMasking.isPhoneUnMasked,
  );

  const widgets = useMemo(
    () => [
      {
        icon: Location,
        label: "Permanent City",
        subtext: `${capitalizeText(leadData?.permanentCity || "-")}`,
      },
      {
        icon: Rupee,
        label: "Loan Amount",
        subtext: `${formatCurrency(leadData?.loanAmount)}`,
      },
      {
        icon: Location,
        label: "Destination Country",
        subtext: `${capitalizeText(leadData?.country || "-", "all")}`,
      },
      {
        icon: University,
        label: "University",
        subtext: `${convertToTitleCase(leadData?.university || "-", "-")}`,
      },
    ],
    [leadData],
  );

  const agentsList = useMemo(() => {
    if (role === USER_TYPE.LENDER)
      return {
        [LENDER_TEAM_LIST.LENDER_EXECUTIVE]:
          leadData?.lenderData?.lender_executive,
        [LENDER_TEAM_LIST.LENDER_MANAGER]: leadData?.lenderData?.lender_manager,
        [LENDER_TEAM_LIST.LENDER_REGIONAL_HEAD]:
          leadData?.lenderData?.lender_regional_head,
      };

    return {
      [AGENT_TEAM_LIST.SALES_AGENT]: leadData?.leadinfo?.agent,
      [AGENT_TEAM_LIST.SANCTION_AGENT]: leadData?.leadinfo?.sanctionAgent,
      [AGENT_TEAM_LIST.ASSISTANT_MANAGER]: leadData?.leadinfo?.amAgent,
      [AGENT_TEAM_LIST.RG_AGENT]: leadData?.leadinfo?.rgAgent,
      [AGENT_TEAM_LIST.LQT_AGENT]: leadData?.leadinfo?.lqtAgent,
    };
  }, [leadData]);

  const handleUnMaskFields = async (
    fieldNames: MaskableFields,
  ): Promise<void> => {
    await dispatch(getMaskedInfo({ fieldNames }));
  };

  const handleCopyLeadDetails = async () => {
    if (leadData?.id) {
      setCopyLeadDetailsLoading(true);
      const leadDetails = await copyLeadDetails({ leadId: leadData?.id });
      const formattedString = getCopyString(leadDetails);
      setCopyLeadDetailsLoading(false);
      return formattedString;
    }
  };

  return (
    <div className="bg-white border border-gray-border rounded-md py-4 px-[18px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <span className="font-medium text-black ">{leadData?.name} </span>

          {role !== USER_TYPE.LENDER && leadData?.leadinfo.isRenewed && (
            <StatusBadge status={StatusKeyEnum.RENEWED} font="xs" />
          )}
          {role !== USER_TYPE.LENDER && leadData?.leadinfo.isRepeated && (
            <StatusBadge status={StatusKeyEnum.REPEATED} font="xs" />
          )}

          <span className="text-lighter-gray"> | </span>

          <div className="flex items-center text-xs">
            <CopyToClipboard content={leadData?.id || ""}>
              <span className="font-light text-dark-gray">Lead ID: </span>
              <span>{leadData?.id}</span>
            </CopyToClipboard>
          </div>

          <span className="text-lighter-gray"> | </span>

          <div className="text-xs font-light text-dark-gray">
            Created on:{" "}
            <span className="font-medium">{leadData?.createdAt}</span>{" "}
          </div>
        </div>

        <div className="flex items-center gap-1 text-xs text-gray">
          {copyLeadDetailsLoading && (
            <img src={Loading} alt="Loading...." className="w-5 h-5" />
          )}

          <CopyToClipboard
            content={""}
            getFieldMaskedInfo={handleCopyLeadDetails}
            isUnMasked={false}
          >
            <span>Copy Details</span>
          </CopyToClipboard>
        </div>
      </div>

      <div className="flex items-start justify-between mt-5 ">
        <div className="grid w-3/5 grid-cols-2 gap-4">
          {widgets.map((widget) => (
            <DataWidget
              key={widget.label}
              icon={widget.icon}
              label={widget.label}
              subtext={widget.subtext}
            />
          ))}
        </div>

        <div className="flex flex-col gap-2 pl-6 font-light border-l cursor-pointer border-l-lighter-gray">
          <PiiFields
            type="phone"
            icon={Phone}
            value={leadData?.phone}
            altText="phone"
            verifiedAltText="verified phone"
            isVerified={leadData?.verifiedPhone}
            isLoading={unMasking.loadingField.includes("phone")}
            isUnMasked={unMasking.isPhoneUnMasked}
            onUnMask={() => handleUnMaskFields(["phone"])}
            onWhatsAppClick={sendWhatsAppMessage}
          />
          <PiiFields
            type="phone"
            icon={Phone}
            altText="phone"
            verifiedAltText="verified phone"
            value={leadData?.altPhone}
            isLoading={unMasking.loadingField.includes("alt_phone")}
            isUnMasked={unMasking.isPhoneUnMasked}
            onUnMask={() => handleUnMaskFields(["alt_phone"])}
            onWhatsAppClick={sendWhatsAppMessage}
          />
          <PiiFields
            type="email"
            icon={Mail}
            altText="mail"
            verifiedAltText="verified email"
            value={leadData?.email}
            isVerified={leadData?.verifiedEmail}
            isLoading={unMasking.loadingField.includes("email")}
            isUnMasked={unMasking.isEmailUnMasked}
            onUnMask={() => handleUnMaskFields(["email"])}
          />
        </div>
      </div>
      <div className="mt-8">
        <Agents data={agentsList} />
      </div>

      <div className="mt-5">
        <Tags leadTags={(leadData?.tags as ITag[]) || []} />
      </div>
    </div>
  );
}
