import clsx from "clsx";

const SkeletonList = ({
  listLength = 1,
  gridCols = 1,
}: {
  listLength?: number;
  gridCols?: number;
}) => (
  <div className="flex flex-col gap-y-2">
    {[...Array(listLength)].map((_, idx) => (
      <div
        key={idx}
        className={clsx(
          "animate-pulse border border-slate-200 rounded-md p-4 h-14 flex items-center",
        )}
      >
        {[...Array(gridCols)].map((_, index) => (
          <div key={index} className="flex-1">
            <span
              className="block h-4 bg-slate-200 rounded w-full flex-1"
              style={{ width: `${Math.floor(Math.random() * 50) + 50}%` }}
            ></span>
          </div>
        ))}
      </div>
    ))}
  </div>
);

export default SkeletonList;
