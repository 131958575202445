//* Components Imports */
import OTP from "@Components/core/Otp";

//* Assets */
import MailGrayTick from "@Assets/icons/MailGrayTick.png";

//* Utils Imports */
import { toastService } from "@Utils/toast";
import { setFormStep } from "@Slice/AuthSlice";
import { getValueFromLS } from "@Utils/storage";
import { useAppDispatch } from "@Hooks/redux-hooks";
import { verifyEmailOTP, sendOtpAction } from "@Actions/auth";
import { FORM_STEP } from "@Enums/common";

const OTPVerification = () => {
  const dispatch = useAppDispatch();
  const userEmail = getValueFromLS("user_email") || "";

  const verifyOTP = (otp: string) => {
    if (!userEmail) {
      toastService.notify("error", "Email is required");
      return;
    }

    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("otp", otp);

    dispatch(verifyEmailOTP(formData));
  };

  const resendOTP = () => {
    if (!userEmail) {
      toastService.notify("error", "Email is required");
      return;
    }

    const formData = new FormData();
    formData.append("email", userEmail);
    dispatch(sendOtpAction(formData));
  };

  return (
    <section className="flex flex-col gap-12">
      <div>
        <div
          className="w-fit flex items-center gap-1 text-xs capitalize cursor-pointer"
          onClick={() => dispatch(setFormStep(FORM_STEP.EMAIL))}
        >
          <span className="font-bold text-white bg-black px-1 pb-[1px] border rounded-full">
            &lt;
          </span>
          <span>Back</span>
        </div>
        <div className="flex justify-between mt-4">
          <span className="text-xl font-medium">OTP Verification</span>
          <span>
            <img src={MailGrayTick} alt="MailGrayTick" className="w-10 h-9" />
          </span>
        </div>
      </div>

      <div className="flex flex-col items-start gap-7">
        <div className="max-w-[301px] text-sm">
          <span>Please enter the 6 digit verification code sent on </span>
          <span className="font-medium underline">{userEmail}</span>
        </div>
        <OTP
          callback={verifyOTP}
          resendCallback={resendOTP}
          timer={{
            minutes: 0,
            seconds: 30,
          }}
        />
      </div>
    </section>
  );
};

export default OTPVerification;
