//* Package Imports */
import clsx from "clsx";

//* Styles Imports */
import Styles from "@Components/NotesSection/NotesSection.module.scss";

//* Utils Imports */
import { safeHtmlUnescape } from "@Utils/stringUtils";
import { INoteDetails } from "@Types/common";

const NotesList = ({ note }: { note: INoteDetails }) => (
  <div>
    <div
      className={clsx(
        Styles.singleNote,
        note.createdBy?.senderType &&
          (note.createdBy?.senderType === "unicreds"
            ? Styles.creds
            : Styles.lender),
      )}
    >
      <div dangerouslySetInnerHTML={{ __html: safeHtmlUnescape(note.note) }} />
      {note.createdBy?.senderImage && (
        <img src={note.createdBy.senderImage} alt="note-sender" />
      )}
    </div>
    <p className={Styles.noteFooter}>
      {note.createdBy?.name} on {note.createdAt}
    </p>
  </div>
);

export default NotesList;
