import { useEffect } from "react";

export default function JumpToTop() {
  useEffect(() => {
    const jumpToTopBtn = document.getElementById("jumpToTop");

    window.addEventListener("scroll", () => {
      if (window.scrollY > 800) {
        jumpToTopBtn?.classList.add("show");
        jumpToTopBtn?.classList.remove("hidden");
      } else {
        jumpToTopBtn?.classList.remove("show");
        jumpToTopBtn?.classList.add("hidden");
      }
    });

    jumpToTopBtn?.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    return () => {
      window.removeEventListener("scroll", () => {});
      jumpToTopBtn?.removeEventListener("click", () => {});
    };
  }, []);

  return (
    <button
      id="jumpToTop"
      className="hidden fixed bottom-10 right-8 w-10 h-10 bg-white text-black font-bold rounded-[50%] shadow-lg"
    >
      ↑
    </button>
  );
}
