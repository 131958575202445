//* Packages Imports /*
import {
  Children,
  isValidElement,
  ReactElement,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";

//* Types Imports */
import { TabPaneProps } from "@Types/common";

//* Styles Imports */
import Styles from "@Core/Tabs/TabList/TabList.module.scss";

//* Interface /*
interface TabListProps {
  tabId: string;
  children: ReactElement[] | ReactElement | null | any;
  activeTabIndex?: number;
  variant?: "solid" | "bordered" | "gray";
  tabsVariant?: "spread" | "left";
  onTabChange: (data: TabPaneProps) => void;
  disabled?: boolean;
  tabContainerClasses?: string;
  tabTitleClasses?: string;
}

interface TabTransformed {
  tabData: any;
  tabAriaLabel: string;
}

const TabList = ({
  tabId,
  children,
  activeTabIndex = 0,
  variant = "solid",
  tabsVariant = "spread",
  onTabChange,
  disabled = false,
  tabContainerClasses = "",
  tabTitleClasses = "",
}: TabListProps) => {
  //* Transforming children to iterable array for Tabs */
  const tabsArray: TabTransformed[] = useMemo(() => {
    const childrenArray: any = Children.toArray(children);
    const transformTabArray: TabTransformed[] = [];
    for (let i = 0; i < childrenArray.length; i++) {
      if (
        isValidElement(childrenArray[i]) &&
        typeof childrenArray[i].type !== "string"
      ) {
        const operatedChild = {
          tabData: childrenArray[i],
          tabAriaLabel: childrenArray[i].props.label
            ?.toLowerCase()
            .replace(" ", "-"),
        };
        transformTabArray.push(operatedChild);
      } else continue;
    }
    return transformTabArray;
  }, [children]);

  const [activeTab, setActiveTab] = useState<number>(activeTabIndex);

  const handleTabClick = (index: number, tabData: TabPaneProps) => {
    if (disabled) return;
    onTabChange(tabData);
    setActiveTab(index);
  };

  return (
    <div
      className={clsx(Styles.tabListContainer, tabContainerClasses)}
      id={tabId}
    >
      <ul
        className={clsx(
          Styles.tabList,
          { [Styles.tabListBlack]: variant === "gray" },
          tabTitleClasses,
        )}
        data-tabs="tabs"
        role="list"
      >
        {tabsArray?.map(
          (tab: { tabData: any; tabAriaLabel: string }, index: number) => (
            <li
              className={clsx(Styles.tabLinkWrapper, {
                [Styles.tabLinkWrapperOutline]: variant === "bordered",
                [Styles.tabLinkWrapperGray]: variant === "gray",
                [Styles.tabContentLeftAligned]: tabsVariant === "left",
                [Styles.activeTab]: activeTab === index,
              })}
              key={tab.tabAriaLabel}
              onClick={() => handleTabClick(index, tab?.tabData?.props)}
            >
              <a
                className={clsx(Styles.tabLink, Styles[variant], {
                  [Styles.activeTabSolid]:
                    activeTab === index && ["solid", "gray"].includes(variant),
                  [Styles.disabled]: disabled,
                })}
                role="tab"
                tabIndex={activeTab === index ? 0 : -1}
                aria-controls={tab?.tabAriaLabel || tab?.tabData?.props?.label}
                aria-selected={activeTab === index ? "true" : "false"}
              >
                {tab?.tabData?.props?.preLabel} {tab?.tabData?.props?.label}{" "}
                {tab?.tabData?.props?.subLabel}
              </a>
            </li>
          ),
        )}
      </ul>
      {tabsArray[activeTab]?.tabData}
    </div>
  );
};

export default TabList;
