//* Packages Imports */
import clsx from "clsx";
import { useState } from "react";

//* Components Imports */
import StatusBadge from "@Core/StatusBadge";
import Select from "@Components/core/Select";
import Button from "@Components/core/Button";
import Modal from "@Components/utility/Modal";

//* Service Imports */
import { appData } from "@Slice/AppSlice";
import { getLostReasons } from "@Actions/app";
import { STATUS_COLOR } from "@Core/StatusBadge/constants";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { IUpdateLeadStatus, updateLeadStatus } from "@Actions/lead";

//* Types Imports */
import { StatusKey } from "@Types/common";
import { StatusKeyEnum } from "@Enums/common";

//* Interface Declaration */
interface ILeadStatus {
  currentStatus: StatusKey;
  disabled?: boolean;
}

const LeadStatus = ({ currentStatus, disabled }: ILeadStatus) => {
  const dispatch = useAppDispatch();

  const { lostReasons } = useAppSelector(appData);
  const { leadData } = useAppSelector((state) => state.lead);
  const loading = useAppSelector((state) => state.app.appLoading);
  const leads = useAppSelector((state) => state.dashboard.leads);

  const [showLostModal, setShowLostModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [lostReason, setLostReason] = useState({
    name: "",
    slug: "",
  });

  const handleStatusClick = async (val: StatusKey) => {
    setSelectedStatus(val);

    if (val === StatusKeyEnum.LOST || val === StatusKeyEnum.LQT_LOST) {
      if (!lostReasons.length) dispatch(getLostReasons());
      setShowLostModal(true);
    } else {
      changeStatus(val);
    }
  };

  const changeStatus = (val: StatusKey, reason = "") => {
    let payload: IUpdateLeadStatus = {
      id: leadData?.id as number,
      status: val,
    };

    if (reason) payload = { ...payload, lost_reason: reason };

    dispatch(updateLeadStatus(payload));
  };

  return (
    <>
      <div className="grid grid-cols-6 gap-2">
        {leads.statusList?.length > 0 &&
          leads.statusList.map((status) => (
            <div
              className="flex items-center justify-center border-r border-r-gray-border h-8"
              key={status}
            >
              {status === currentStatus ? (
                <StatusBadge status={currentStatus} />
              ) : (
                <div
                  className={clsx(
                    "text-black text-sm px-4 py-3 cursor-pointer",
                    {
                      "!cursor-not-allowed": disabled,
                    },
                  )}
                  onClick={
                    !disabled ? () => handleStatusClick(status) : undefined
                  }
                >
                  {STATUS_COLOR[status]?.label}
                </div>
              )}
            </div>
          ))}
      </div>

      <Modal
        open={showLostModal}
        onClose={() => setShowLostModal(false)}
        persist
      >
        <div className="flex flex-col w-[470px] gap-2">
          <h2 className="text-xl font-medium"> Mark as Lost</h2>

          <span className="text-sm font-light">
            Kindly enter the reason for changing this lead status to lost.
          </span>

          <div className="mt-2">
            <Select
              options={lostReasons}
              valueKey="slug"
              labelKey="name"
              placeholder="Select Reason"
              setValue={(val) => setLostReason(val)}
              optionsLoading={loading}
              canSearch
            />
          </div>

          <div className="flex items-center justify-end gap-2 mt-4">
            <Button
              variant="outline"
              font="xs"
              classes="w-[100px]"
              onClick={() => setShowLostModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="error"
              font="xs"
              classes="w-fit"
              onClick={() => changeStatus(selectedStatus, lostReason?.slug)}
              disabled={lostReason.slug === ""}
            >
              Mark as lost
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LeadStatus;
