//* Assets */
import GreenUser from "@Assets/icons/green-user.png";

interface IAvatar {
  imgURL?: string;
  name: string;
  subtext?: string;
  onClick?: () => void;
}

export default function Avatar({ imgURL, name, subtext, onClick }: IAvatar) {
  return (
    <div className="flex items-center gap-1" onClick={onClick}>
      <img src={imgURL || GreenUser} alt="user" className="w-9 h-9" />

      <div className="flex flex-col">
        <span className="font-medium text-black text-xs">{name}</span>
        {subtext && <span className="text-xxs text-label-gray">{subtext}</span>}
      </div>
    </div>
  );
}
