export class MapBackendToFrontend {
  private keyMapping: { [key: string]: string };

  constructor(keyMapping: { [key: string]: string }) {
    this.keyMapping = keyMapping;
  }

  public mapBackendToFrontend<T>(backendData: any): T {
    if (typeof backendData !== "object" || backendData === null) {
      return backendData;
    }

    if (Array.isArray(backendData)) {
      return backendData.map((item) =>
        this.mapBackendToFrontend(item)
      ) as unknown as T;
    }

    const frontendData: any = {};

    for (const backendKey in backendData) {
      if (Object.prototype.hasOwnProperty.call(backendData, backendKey)) {
        const frontendKey = this.keyMapping[backendKey] || backendKey;
        frontendData[frontendKey] = this.mapBackendToFrontend(
          backendData[backendKey]
        );
      }
    }

    return frontendData;
  }
}
