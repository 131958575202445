//* Package Imports */
import { useCallback, useEffect, useRef, useState } from "react";

const useInfiniteScroll = (
  fetchData: () => Promise<unknown>,
  hasMoreData: boolean = false,
  dependencies: Array<unknown> = [],
) => {
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadMoreData = useCallback(async () => {
    if (isLoading || !hasMoreData) return;

    setIsLoading(true);
    await fetchData();
    setIsLoading(false);
  }, [fetchData, isLoading, hasMoreData]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMoreData();
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [isLoading, ...dependencies]);

  return [loaderRef, isLoading];
};

export default useInfiniteScroll;
