import clsx from "clsx";

const SkeletonCard = ({
  listLength = 1,
  contentLines = 2,
  gridCol,
}: {
  listLength?: number;
  contentLines?: number;
  gridCol?: number;
}) => {
  const colClass = `grid-cols-${gridCol}`;
  return (
    <div className="flex flex-col gap-y-3">
      {[...Array(listLength)].map((_, idx) => (
        <div
          key={idx}
          className={clsx(
            "animate-pulse border border-slate-200 rounded-md p-4",
            gridCol && `grid ${colClass} gap-2`,
          )}
        >
          <div className="h-4 bg-slate-200 rounded w-3/4 mb-4"></div>
          <div className="h-3 bg-slate-200 rounded w-1/2 mb-2"></div>
          {[...Array(contentLines)].map((_, index) => (
            <div
              key={index}
              className="h-3 bg-slate-200 rounded mb-2"
              style={{ width: `${Math.floor(Math.random() * 50) + 50}%` }}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkeletonCard;
