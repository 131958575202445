//* Packages */
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Utils */
import API from "@Axios/main";
import {
  ALL_COUNTRIES,
  ALL_PARTNERS,
  ALL_TAGS,
  LOST_REASONS,
  UTM_MEDIUMS,
  UTM_SOURCES,
  TEAM_MEMBERS,
} from "@Constants/urls";
import { toastService } from "@Utils/toast";
import { AGENTS_MAPPER, TAGS_MAPPER } from "@Mappers/app";
import { capitalizeText } from "@Utils/common";

//* Types & Enums */
import { ISelect } from "@Types/common";

//* Redux */
import {
  setAppLoading,
  setCountries,
  setTags,
  setPartners,
  setLostReasons,
  setUtmSources,
  setUtmMediums,
  setTeam,
} from "@Slice/AppSlice";
import { setTagsLoading } from "@Slice/LeadSlice";

const _API = new API("v1");
const _API2 = new API();

export const getTags = createAsyncThunk("app/tags", async (_, { dispatch }) => {
  try {
    dispatch(setTagsLoading(true));
    const res = await _API.get(ALL_TAGS, TAGS_MAPPER);
    dispatch(setTags(res));
  } catch (e: any) {
    toastService.notify(
      "error",
      e.response?.data?.message || "Something went wrong. Please try again!",
    );
  } finally {
    dispatch(setTagsLoading(false));
  }
});

export const getCountries = createAsyncThunk(
  "app/countries",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res = await _API2.get(ALL_COUNTRIES);
      dispatch(setCountries(res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    } finally {
      dispatch(setAppLoading(false));
    }
  },
);

export const getPartners = createAsyncThunk(
  "app/partners",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res = await _API2.get(ALL_PARTNERS);
      dispatch(setPartners(res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    } finally {
      dispatch(setAppLoading(false));
    }
  },
);

export const getLostReasons = createAsyncThunk(
  "app/lostReasons",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res = await _API.get(LOST_REASONS);
      dispatch(setLostReasons(res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    }
    dispatch(setAppLoading(false));
  },
);

export const getUtmSources = createAsyncThunk(
  "app/utmSources",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res: Record<"utm_sources", string[]> = await _API.get(UTM_SOURCES);
      const _res: ISelect[] = res.utm_sources.map((source: string) => ({
        label: capitalizeText(source),
        value: source,
      }));
      dispatch(setUtmSources(_res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    }
    dispatch(setAppLoading(false));
  },
);

export const getUtmMediums = createAsyncThunk(
  "app/utmMediums",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res: Record<"utm_mediums", string[]> = await _API.get(UTM_MEDIUMS);
      const _res: ISelect[] = res.utm_mediums.map((medium: string) => ({
        label: capitalizeText(medium),
        value: medium,
      }));
      dispatch(setUtmMediums(_res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    }
    dispatch(setAppLoading(false));
  },
);

export const getTeamMembers = createAsyncThunk(
  "app/agents",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res: any = await _API2.get(TEAM_MEMBERS, AGENTS_MAPPER);
      dispatch(setTeam(res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    }
    dispatch(setAppLoading(false));
  },
);
