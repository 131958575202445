//* Packages Imports */
import { forwardRef, MutableRefObject } from "react";
import clsx from "clsx";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {
  BeautifulMentionsPlugin,
  BeautifulMentionNode,
  BeautifulMentionsMenuProps,
  BeautifulMentionsMenuItemProps,
} from "lexical-beautiful-mentions";
import { AutoLinkNode } from "@lexical/link";
import ToolbarPlugin from "@Core/RichTextField/Toolbar";
import AutoLinkPlugin from "@Core/RichTextField/AutoLinkPlugin";
import MiscEditorPlugin from "@Core/RichTextField/MiscEditorPlugin";

//* Styles Imports */
import Styles from "@Core/RichTextField/RichTextField.module.scss";

interface IRichTextFieldProps {
  editorRef: MutableRefObject<HTMLElement | null>;
  clearEditorRef?: MutableRefObject<(() => void) | null>;
  getMentionsRef?: MutableRefObject<(() => void) | null>;
  mentionList?:
    | string[]
    | {
        value: string;
        [key: string]: string | number;
      }[];
  hasToolbar?: boolean;
  hideToolbarOnBlur?: boolean;
  classes?: string;
}

const theme = {
  beautifulMentions: {
    "@": "bg-disbursed/30 rounded px-1",
  },
  text: {
    underline: "underline",
  },
  link: "text-royal-blue",
};

const initialConfig = {
  namespace: "RichTextEditor",
  theme,
  onError: (error: any) => {
    console.error(error);
  },
  nodes: [BeautifulMentionNode, AutoLinkNode],
};

const CustomMenu = ({ loading, ...props }: BeautifulMentionsMenuProps) => (
  <div className={Styles.mentionList}>
    {loading && <div>Loading...</div>}
    <ul {...props} />
  </div>
);

const EmptyMenu = () => {
  return (
    <ul className={clsx(Styles.mentionList, Styles.empty)}>
      No results found.
    </ul>
  );
};

// eslint-disable-next-line react/display-name
const CustomMenuItem = forwardRef<
  HTMLLIElement,
  BeautifulMentionsMenuItemProps
  // eslint-disable-next-line react/prop-types
>(({ selected, itemValue, ...props }, ref) => {
  return (
    <li
      className={clsx(Styles.mentionElement, { [Styles.selected]: selected })}
      {...props}
      ref={ref}
    >
      {props?.item?.data?.label || itemValue}
    </li>
  );
});

const RichTextField = ({
  editorRef,
  clearEditorRef,
  getMentionsRef,
  mentionList = [],
  hasToolbar = false,
  hideToolbarOnBlur = false,
  classes = "",
}: IRichTextFieldProps) => {
  const mentionItems = {
    "@": mentionList,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div
        className={clsx(Styles.editorContainer, classes, {
          [Styles.hideOnBlur]: hideToolbarOnBlur,
        })}
      >
        <AutoLinkPlugin />
        <HistoryPlugin />
        <RichTextPlugin
          contentEditable={
            <ContentEditable className="editor-input" ref={editorRef as any} />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        {(clearEditorRef || getMentionsRef) && (
          <MiscEditorPlugin
            clearEditor={clearEditorRef}
            getMentions={getMentionsRef}
          />
        )}
        {mentionList?.length > 0 && (
          <BeautifulMentionsPlugin
            items={mentionItems}
            menuComponent={CustomMenu}
            menuItemComponent={CustomMenuItem}
            emptyComponent={EmptyMenu}
            menuItemLimit={100}
          />
        )}
        {hasToolbar && <ToolbarPlugin />}
      </div>
    </LexicalComposer>
  );
};

export default RichTextField;
