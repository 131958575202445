//* Package Imports */
import clsx from "clsx";

//* Utils Imports */
import { SHORT_MONTHS_MAP } from "@Core/Datepicker/utils";

//* Styles Imports */
import Styles from "@Core/Datepicker/YearMonthTable.module.scss";

interface IYearMonthTable {
  currentYear: number;
  currentMonth: number;
  handleYearChange: (val: number) => void;
  handleMonthChange: (val: number) => void;
  setShowYearTable: (state: {
    open: boolean;
    type: "year" | "month" | "";
  }) => void;
  type: "month" | "year" | "";
}

const YearMonthTable = ({
  currentYear,
  currentMonth,
  handleYearChange,
  handleMonthChange,
  setShowYearTable,
  type,
}: IYearMonthTable) => {
  const _year = new Date().getFullYear();
  const _month = new Date().getMonth();

  return (
    <div className={Styles.container}>
      {type === "month" && (
        <div className={Styles.cellContainer}>
          {Array.from({ length: 12 }, (_, i) => i).map((month) => (
            <button
              type="button"
              key={month}
              className={clsx(Styles.cell, {
                [Styles.selected]: currentMonth === month,
              })}
              onClick={() => {
                handleMonthChange(month);
                setShowYearTable({ open: false, type: "" });
              }}
              disabled={currentYear === _year && month < _month}
            >
              {SHORT_MONTHS_MAP[month]}
            </button>
          ))}
        </div>
      )}
      {type === "year" && (
        <div className={Styles.cellContainer}>
          <button
            type="button"
            className={Styles.cell}
            onClick={() => handleYearChange(currentYear - 10)}
          >
            &lt;
          </button>
          {Array.from({ length: 10 }, (_, i) => i).map((year) => (
            <button
              type="button"
              key={year}
              className={clsx(Styles.cell, {
                [Styles.selected]: currentYear === year + currentYear,
              })}
              onClick={() => {
                handleYearChange(currentYear + year);
                setShowYearTable({ open: false, type: "" });
              }}
            >
              {currentYear + year}
            </button>
          ))}
          <button
            type="button"
            className={Styles.cell}
            onClick={() => handleYearChange(currentYear + 10)}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default YearMonthTable;
