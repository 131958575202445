import React from "react";
import QRCode from "react-qr-code";

//* Assets */
import GreenVerify from "@Assets/icons/green-verify.png";
import Whatsapp from "@Assets/icons/whatsapp.png";
import QR from "@Assets/icons/qr.png";
import Loading from "@Assets/images/loader.gif";

//* Components */
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import Popper from "@Components/utility/Popper";

//* Types */
interface PiiFieldsProps {
  type: "phone" | "email";
  value: string | undefined;
  icon?: string;
  altText?: string;
  verifiedAltText?: string;
  isVerified?: boolean | undefined;
  isLoading?: boolean;
  isUnMasked: boolean;
  onUnMask?: () => Promise<void>;
  onWhatsAppClick?: () => void;
}

const PiiFields: React.FC<PiiFieldsProps> = ({
  type,
  icon,
  altText = `${type}-icon`,
  value,
  verifiedAltText,
  isVerified,
  isLoading,
  isUnMasked,
  onUnMask,
  onWhatsAppClick,
}) => {
  return (
    <>
      {value && (
        <div className="flex items-center gap-2">
          {isLoading && (
            <img src={Loading} alt="Loading...." className="w-5 h-5" />
          )}
          {icon && <img src={icon} alt={altText} className="w-5 h-5" />}
          <span className="text-xs cursor-pointer" onClick={onUnMask}>
            {value || "-"}
          </span>
          {isVerified && (
            <img src={GreenVerify} alt={verifiedAltText} className="w-4 h-4" />
          )}
          <div className="w-full">
            <CopyToClipboard
              content={value || ""}
              isUnMasked={isUnMasked}
              getFieldMaskedInfo={onUnMask}
            />
          </div>
          {type === "phone" && (
            <>
              <Popper
                trigger={
                  <img
                    src={QR}
                    alt="qr"
                    className="w-3 h-3"
                    onClick={onUnMask}
                  />
                }
              >
                {isUnMasked && value && (
                  <div className="p-4">
                    <QRCode
                      size={100}
                      value={`tel://${value}`}
                      viewBox="0 0 100 100"
                    />
                  </div>
                )}
              </Popper>
              <img
                src={Whatsapp}
                alt="WhatsApp"
                className="w-4 h-4"
                onClick={onWhatsAppClick}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PiiFields;
