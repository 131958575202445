//* Package Imports */
import { useState } from "react";

interface ICheckbox {
  name: string;
  subLabel?: string;
  label: string;
  checked?: boolean;
  value: any;
  disabled?: boolean;
  onChange: (value: string | number, checked: boolean) => void;
}

export default function Checkbox({
  name = "custom-checkbox",
  label,
  subLabel = "",
  checked = false,
  value,
  disabled = false,
  onChange,
}: ICheckbox) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    onChange(e.target.value, e.target.checked);
  };

  return (
    <label className="flex items-center gap-2 cursor-pointer w-full">
      <input
        name={name}
        type="checkbox"
        checked={isChecked}
        value={value}
        onChange={handleCheckboxChange}
        disabled={disabled}
        className="accent-success"
      />
      <div className="flex flex-col font-normal gap-1 text-xs">
        <span>{label}</span>
        {subLabel && (
          <span className="text-xs text-dark-gray"> {subLabel}</span>
        )}
      </div>
    </label>
  );
}
