//* Package Import //
import { ReactNode, useRef } from "react";

//* Assets */
import Copy from "@Assets/icons/copy.png";

//* Utils */
import { copyToClipboard } from "@Utils/common";

interface ICopyToClipboard {
  content: string | number;
  isUnMasked?: boolean;
  getFieldMaskedInfo?: () => Promise<string | void>;
  children?: ReactNode;
}

export default function CopyToClipboard({
  content,
  isUnMasked = true,
  getFieldMaskedInfo,
  children,
}: ICopyToClipboard) {
  const copyRef = useRef<HTMLImageElement>(null);
  const handleCopyToClipboard = async () => {
    if (isUnMasked) {
      copyToClipboard(content);
    } else if (getFieldMaskedInfo) {
      const copyContent = await getFieldMaskedInfo();
      copyToClipboard(copyRef?.current?.dataset?.content || copyContent || "");
    }
  };

  return (
    <div
      onClick={handleCopyToClipboard}
      className="flex items-center gap-1 w-fit cursor-pointer"
    >
      {children && children}
      <img
        ref={copyRef}
        src={Copy}
        alt="copy"
        data-content={isUnMasked ? content : ""}
        className="w-3 h-3 ml-0.5 cursor-pointer"
      />
    </div>
  );
}
