//* Packages Imports */
import { useMemo } from "react";

//* Types Imports */
import { TabPaneProps } from "@Types/common";

const TabPane = ({ label, children, containerClasses }: TabPaneProps) => {
  const tabElementAttr = useMemo(() => {
    const tabId = `tab-${label.toLowerCase().replace(" ", "-")}`;
    const labelledBy = label.toLowerCase().replace(" ", "-");
    const tabDataName = `${labelledBy}-data`;
    return { tabId, labelledBy, tabDataName };
  }, [label]);

  return (
    <div
      id={tabElementAttr.tabId}
      className={`w-full flex-1 flex flex-col ${containerClasses}`}
      role="tabpanel"
      aria-labelledby={tabElementAttr.labelledBy}
      data-name={tabElementAttr.tabDataName}
    >
      {children}
    </div>
  );
};

export default TabPane;
