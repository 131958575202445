//* Package Imports */
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import clsx from "clsx";

//* Components Imports */
import Button from "@Core/Button";

//* Utils Imports */
import { padZeroAtStart } from "@Utils/auth";

//* Styles Imports */
import Styles from "@Core/Otp/OTP.module.scss";

//* Interfaces Definition */
interface IOTP {
  callback: (otp: string) => void;
  resendCallback: () => void;
  timer: { minutes: number; seconds: number };
}

const OTP = ({ callback, resendCallback, timer }: IOTP) => {
  const inputRefs = useRef<any>([]);

  const [minutes, setMinutes] = useState<number>(timer.minutes);
  const [seconds, setSeconds] = useState<number>(timer.seconds);
  const [reSendOtp, setReSendOtp] = useState<boolean>(false);
  const [otpValue, setOTPValue] = useState<string[]>(["", "", "", "", "", ""]);
  const [error, setError] = useState<string>("");

  const handleInputChange = (index: number, inputValue: string) => {
    //* Update the value of the current input field
    try {
      if (/^\d+$/.test(inputValue)) {
        if (inputValue.length === 1) {
          const updatedValue = otpValue?.map((digit, i) => {
            if (i === index) return inputValue;
            else return digit;
          });

          setOTPValue(updatedValue);
          setError("");

          if (inputValue !== "" && inputRefs.current[index + 1])
            inputRefs.current[index + 1].focus();
        } else if (inputValue.length === 6) {
          const inputSepration = String(inputValue)
            .split("")
            .map((num) => num);

          const pasteValue: string[] = [...inputSepration];
          setOTPValue(pasteValue);

          //* Move focus to the next input field
          if (inputValue !== "" && index < 5)
            inputRefs.current[index + 5].focus();
        }
      } else throw new Error("Enter a valid OTP");
    } catch (error: any) {
      console.error("error", error.message);
      setError(error.message);
    }
  };

  const handleInputKeyDown = (
    index: number,
    e: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (
      e.key === "Backspace" &&
      otpValue?.[index] === "" &&
      inputRefs.current[index - 1]
    ) {
      const updatedValue = otpValue?.map((digit, i) => {
        if (i === index - 1) return "";
        else return digit;
      });
      setOTPValue(updatedValue);
      inputRefs.current[index - 1].focus();
    } else if (
      e.key === "Backspace" &&
      otpValue?.[index] !== "" &&
      inputRefs.current[index]
    ) {
      const updatedValue = otpValue?.map((digit, i) => {
        if (i === index) return "";
        else return digit;
      });
      setOTPValue(updatedValue);
      inputRefs.current[index].focus();
    }
  };

  const handleInputPaste = (e: any) => {
    const pastedValue = e.clipboardData.getData("text");
    if (/^\d+$/.test(pastedValue) && pastedValue.length <= 6) {
      const updatedValue: string[] = Array.from(pastedValue.padEnd(4, ""));
      setOTPValue(updatedValue);
      inputRefs.current[5].focus();
    }
    e.preventDefault();
  };

  const handleResendOTP = () => {
    setReSendOtp(false);
    setMinutes(timer.minutes);
    setSeconds(timer.seconds);
    setError("");
    resendCallback();
  };

  //* Calculating timer */
  useEffect(() => {
    const interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(interval); //* Stop the timer when it reaches 0:00
        setReSendOtp(true);
      } else {
        if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else setSeconds(seconds - 1);
      }
    }, 1000); //* Update every second

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  return (
    <div className={Styles.otpContainer}>
      <div className={Styles.otpWrapper}>
        <div className={Styles.otpInputDiv}>
          {Array.from({ length: 6 }, (_, index) => index).map(
            (digit, index) => (
              <input
                key={index}
                ref={(ref) => (inputRefs.current[index] = ref)}
                className={clsx(Styles.otpIp, {
                  [Styles.OtpInputError]: error,
                })}
                type="text"
                maxLength={6}
                name="loginOtpVerification"
                value={otpValue?.[digit]}
                onChange={(e) => {
                  handleInputChange(index, e.target.value);
                }}
                onKeyDown={(e) => handleInputKeyDown(index, e)}
                onPaste={handleInputPaste}
                autoComplete="one-time-code"
                inputMode="numeric"
                data-cy="otp-input-box"
              />
            ),
          )}
        </div>
        <span className="text-xs text-error">{error}</span>
      </div>
      {reSendOtp ? (
        <span
          className={Styles.otpResend}
          onClick={handleResendOTP}
          data-cy="resend-otp-link"
        >
          Resend OTP
        </span>
      ) : (
        <span
          className="text-sm font-medium text-tag-gray capitalize"
          data-cy="resend-otp-timer"
        >
          Resend code ({padZeroAtStart(minutes)}:{padZeroAtStart(seconds)}{" "}
          seconds)
        </span>
      )}
      <Button
        variant="solid"
        classes={Styles.otpSubmitButton}
        onClick={() => {
          const otp = otpValue.join("");
          if (otp.length !== 6) {
            setError("Please enter valid OTP to login");
            return;
          }
          callback(otp);
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default OTP;
