interface IDataWidget {
  icon: string;
  label: string;
  subtext?: string | number;
  classes?: string;
}

export default function DataWidget({
  icon,
  label,
  subtext,
  classes = "",
}: IDataWidget) {
  return (
    <div className="flex items-center gap-2">
      <img src={icon} alt="location" className="w-5 h-5" />
      <div className="flex flex-col">
        <span className="text-xxs text-dark-gray">{label}</span>
        <span className={`text-black text-xs ${classes}`}>
          {subtext || "-"}
        </span>
      </div>
    </div>
  );
}
