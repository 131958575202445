//* Package imports */
import { useEffect } from "react";
import { useAppDispatch } from "@Hooks/redux-hooks";

//* Service imports */
import { verifyGoogleLogin } from "@Actions/auth";
import { handleLogout } from "@Utils/auth";
import { getCookiesValue } from "@Utils/storage";

//* Types imports */
import { IAuthAction } from "@Types/auth";

const authorize = () => {
  const dispatch = useAppDispatch();

  const signInUser = async (
    code: string | null,
    state: string | null,
    redirectUrl: string | null,
  ) => {
    const data: IAuthAction = {
      auth_code: code,
      state: state,
    };

    const payload = {
      data: data,
      redirectUrl: redirectUrl,
    };

    try {
      await dispatch(verifyGoogleLogin(payload));
    } catch (error) {
      console.error("error", error);
      handleLogout();
    }
  };

  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const state = params.get("state");
      let decodedState = null;
      if (state) {
        decodedState = JSON.parse(decodeURIComponent(state));
      }
      const { csrf_token, redirectUrl } = decodedState;
      const google_csrf_token = getCookiesValue("google_csrf_token");

      if (csrf_token === google_csrf_token) {
        signInUser(code, csrf_token, redirectUrl);
      } else {
        throw new Error("Invalid CSRF token");
      }
    } catch (error) {
      console.error("error", error);
      handleLogout();
    }
  }, []);

  return <div></div>;
};

export default authorize;
