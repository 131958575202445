import { TASK_MAPPER } from "@Mappers/lead";

export const ALL_LEADS_MAPPER = {
  total_pages: "totalPages",
  total_items: "totalItems",
  current_page: "currentPage",
  assigned_on: "assignedOn",
};

export const MY_TASKS_MAPPER = {
  ...TASK_MAPPER,
  total_pages: "totalPages",
  total_items: "totalItems",
  current_page: "currentPage",
};
