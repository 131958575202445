export const calculateTimeDifference = (date1: Date, date2: Date) => {
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  const differenceMs = date2Ms - date1Ms;

  if (differenceMs < 0) {
    return "";
  }

  // Calculate the difference in days
  const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  if (daysDifference > 0) {
    return `${daysDifference} day(s)`;
  }

  // Calculate hours if less than a day
  const hoursDifference = Math.floor(differenceMs / (1000 * 60 * 60));

  if (hoursDifference > 0) {
    return `${hoursDifference} hours`;
  }

  // Calculate minutes if less than an hour
  const minutesDifference = Math.floor(differenceMs / (1000 * 60));

  return `${minutesDifference} minutes`;
};

const getInitialTime = (value: number) => {
  return value.toString().length > 1 ? value : "0" + value;
};

export const getStructuredDate = (
  dateStr: Date | string | number | undefined | null,
  showTime: boolean = false,
) => {
  let formattedDate = "";

  if (!dateStr) {
    return formattedDate;
  }

  const date = new Date(dateStr);
  const day = getInitialTime(date.getDate());
  const month = getInitialTime(date.getMonth() + 1);
  const year = getInitialTime(date.getFullYear());
  const hour = getInitialTime(date.getHours());
  const min = getInitialTime(date.getMinutes());
  const sec = getInitialTime(date.getSeconds());

  formattedDate = `${year}-${month}-${day}`;

  if (showTime) {
    formattedDate += ` ${hour}:${min}:${sec}`;
  }

  return formattedDate;
};
