//* Package Imports */
import { useEffect, useState } from "react";

//* Components Imports */
import MonthSelector from "@Core/Datepicker/MonthSelector";
import DaysTable from "@Core/Datepicker/DaysTable";
import YearMonthTable from "@Core/Datepicker/YearMonthTable";

const CustomDateRangePicker = ({
  showNeighbouringMonth = false,
  type = "single",
  disablePreviousDates = false,
  setSelectedDateObj,
  defaultSelectedDateObj,
  disableMonthSelection = false,
  disableYearSelection = false,
  disableDatesAfter,
  disabledDates = [],
  showClearDates = false,
}: {
  showNeighbouringMonth?: boolean;
  type?: "single" | "range";
  disablePreviousDates?: boolean;
  setSelectedDateObj: (
    dates:
      | { startDate: Date | null; endDate: Date | null }
      | { selectedDate: Date | null },
  ) => void;
  defaultSelectedDateObj?: {
    startDate: Date | string | null;
    endDate: Date | string | null;
  };
  disableMonthSelection?: boolean;
  disableYearSelection?: boolean;
  disableDatesAfter?: Date;
  disabledDates?: string[];
  showClearDates?: boolean;
}) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const [currSelectedYear, setCurrSelectedYear] = useState<number>(year);
  const [currSelectedMonth, setCurrSelectedMonth] = useState<number>(month);
  const [nextMonthAndYear, setNextMonthAndYear] = useState<{
    month: number;
    year: number;
  }>({
    month: month + 1,
    year: year,
  });
  const [showYearMonthTable, setShowYearMonthTable] = useState<{
    open: boolean;
    type: "year" | "month" | "";
  }>({
    open: false,
    type: "",
  });

  const handleMonthChange = (month: number) => {
    setCurrSelectedMonth(month);
  };

  const handleYearChange = (year: number) => {
    setCurrSelectedYear(year);
  };

  const handleChooseDate = (date: {
    startDate?: Date | null;
    endDate?: Date | null;
    noOfDaysSelected?: number;
    selectedDate?: Date | null;
  }) => {
    if (type === "single") {
      const modifiedDate = date;
      setSelectedDateObj(modifiedDate as any);
    } else if (type === "range" && date.startDate && date.endDate) {
      setSelectedDateObj(date as any);
    }
  };

  useEffect(() => {
    if (showNeighbouringMonth) {
      setNextMonthAndYear({
        month: currSelectedMonth === 11 ? 0 : currSelectedMonth + 1,
        year:
          currSelectedMonth + 1 > 11 ? currSelectedYear + 1 : currSelectedYear,
      });
    }
  }, [currSelectedMonth, currSelectedYear]);

  useEffect(() => {
    if (defaultSelectedDateObj?.startDate) {
      const _startDate = defaultSelectedDateObj.startDate;
      const defaultMonth = new Date(_startDate).getMonth();
      const defaultYear = new Date(_startDate).getFullYear();
      setCurrSelectedYear(defaultYear);
      setCurrSelectedMonth(defaultMonth);
    }
  }, [defaultSelectedDateObj]);

  return (
    <div>
      <MonthSelector
        currSelectedYear={currSelectedYear}
        handleYearChange={handleYearChange}
        handleMonthChange={handleMonthChange}
        currSelectedMonth={currSelectedMonth}
        showNeighbouringMonth={showNeighbouringMonth}
        nextMonthAndYear={nextMonthAndYear}
        setShowYearTable={setShowYearMonthTable}
        disableYearSelection={disableYearSelection}
        disableMonthSelection={disableMonthSelection}
      />
      <DaysTable
        currSelectedMonth={currSelectedMonth}
        currSelectedYear={currSelectedYear}
        showNeighbouringMonth={showNeighbouringMonth}
        nextMonthAndYear={nextMonthAndYear}
        type={type}
        disablePreviousDates={disablePreviousDates}
        setDateRange={handleChooseDate}
        defaultSelectedDateObj={defaultSelectedDateObj}
        disableDatesAfter={disableDatesAfter}
        disabledDates={disabledDates}
        showClearDates={showClearDates}
      />

      {showYearMonthTable.open && (
        <YearMonthTable
          currentYear={currSelectedYear}
          currentMonth={currSelectedMonth}
          handleYearChange={handleYearChange}
          handleMonthChange={handleMonthChange}
          type={showYearMonthTable.type}
          setShowYearTable={setShowYearMonthTable}
        />
      )}
    </div>
  );
};

export default CustomDateRangePicker;
