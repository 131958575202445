//* Utils Imports */
import { setCookiesValue } from "@Utils/storage";
import { generateRandomString } from "@Utils/common";
import GoogleIcon from "@Assets/icons/googleIcon.png";

//* URL Imports */
import { GOOGLE_AUTH_URL, GOOGLE_REDIRECT_URI } from "@Constants/urls";

const GoogleLogin = () => {
  const oAuthSignIn = () => {
    const google_csrf_token = generateRandomString(32);
    const urlData = new URLSearchParams(window.location.search);
    const redirectUrl = urlData.get("redirect");

    setCookiesValue("google_csrf_token", google_csrf_token, 604800);

    const state = JSON.stringify({
      csrf_token: google_csrf_token,
      redirectUrl: redirectUrl,
    });

    const form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", GOOGLE_AUTH_URL);

    const params = {
      client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      access_type: "offline",
      redirect_uri: GOOGLE_REDIRECT_URI,
      response_type: "code",
      scope: "profile email openid",
      include_granted_scopes: "true",
      prompt: "select_account consent",
      state: encodeURIComponent(state),
    };

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", params[key as keyof typeof params] as string);
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div data-cy="google-signin-button">
      <button
        className="w-full flex items-center justify-center p-[2px] text-sm border border-outline-button-gray rounded-md"
        onClick={() => oAuthSignIn()}
      >
        <span className="bg-white p-2">
          <img src={GoogleIcon} alt="Google Logo" width={20} height={20} />
        </span>
        <span>Sign In with Google</span>
      </button>
    </div>
  );
};

export default GoogleLogin;
