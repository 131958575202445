// Packages Import
import clsx from "clsx";

// Styles import
import Styles from "@Core/Pagination/Pagination.module.scss";

export default function Pagination({
  totalPages,
  currentPage,
  setCurrentPage,
}: {
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}) {
  function getPageNumbers() {
    if (totalPages <= 6) {
      return Array.from(
        { length: totalPages },
        (_, i) =>
          i !== 0 &&
          i !== totalPages - 1 && (
            <div
              key={i}
              className={clsx(Styles.pageNumber, {
                [Styles.active]: i + 1 === currentPage,
              })}
              onClick={() => handlePageChange(i + 1)}
            >
              <span>{i + 1}</span>
            </div>
          ),
      );
    } else if (!(currentPage + 3 >= totalPages || currentPage - 3 <= 1)) {
      return (
        <>
          <div className={clsx(Styles.pageNumber, Styles.ellipses)}>...</div>
          <div
            className={clsx(Styles.pageNumber)}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <span>{currentPage - 1}</span>
          </div>
          <div
            className={clsx(Styles.pageNumber, Styles.active)}
            onClick={() => handlePageChange(currentPage)}
          >
            <span>{currentPage}</span>
          </div>
          <div
            className={clsx(Styles.pageNumber)}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <span>{currentPage + 1}</span>
          </div>
          <div className={clsx(Styles.pageNumber, Styles.ellipses)}>...</div>
        </>
      );
    } else if (currentPage + 3 >= totalPages) {
      return (
        <>
          <div className={clsx(Styles.pageNumber, Styles.ellipses)}>...</div>
          {Array.from(
            { length: 5 },
            (_, i) =>
              totalPages - 4 + i !== totalPages && (
                <div
                  key={i}
                  className={clsx(Styles.pageNumber, {
                    [Styles.active]: totalPages - 4 + i === currentPage,
                  })}
                  onClick={() => handlePageChange(totalPages - 4 + i)}
                >
                  <span>{totalPages - 4 + i}</span>
                </div>
              ),
          )}
        </>
      );
    } else if (currentPage - 3 <= 1) {
      return (
        <>
          {Array.from(
            { length: 5 },
            (_, i) =>
              i != 0 && (
                <div
                  key={i}
                  className={clsx(Styles.pageNumber, {
                    [Styles.active]: i + 1 === currentPage,
                  })}
                  onClick={() => handlePageChange(i + 1)}
                >
                  <span>{i + 1}</span>
                </div>
              ),
          )}
          <div className={clsx(Styles.pageNumber, Styles.ellipses)}>...</div>
        </>
      );
    }
  }

  function handlePageChange(newPage: number) {
    if (newPage === currentPage) {
      return;
    }
    setCurrentPage(newPage);
  }

  return (
    <div className={Styles.paginationContainer}>
      <button
        disabled={currentPage === 1}
        className={clsx(Styles.nextPrevBtn, Styles.pageNumber)}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        &#60;
      </button>
      <div
        className={clsx(Styles.pageNumber, {
          [Styles.active]: 1 === currentPage,
        })}
        onClick={() => handlePageChange(1)}
      >
        <span>1</span>
      </div>
      {totalPages > 1 && (
        <>
          {getPageNumbers()}
          <div
            className={clsx(Styles.pageNumber, {
              [Styles.active]: totalPages === currentPage,
            })}
            onClick={() => handlePageChange(totalPages)}
          >
            <span>{totalPages}</span>
          </div>
        </>
      )}
      <button
        disabled={currentPage === totalPages}
        className={clsx(Styles.nextPrevBtn, Styles.pageNumber)}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        &#62;
      </button>
    </div>
  );
}
