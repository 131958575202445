/* //* Packages Imports */
import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";

/* //* Service Imports */
import { RootState } from "@Store/index";

//* Utils */
import { IRBAC } from "@Types/auth";
import { FORM_STEP, USER_TYPE } from "@Enums/common";

//* Interfaces Definition */
export interface AuthState {
  loading: boolean;
  authorised: boolean;
  formStep: FORM_STEP;
  role: USER_TYPE | null;
  id: number | null;
  email: string | null;
  access: IRBAC | null;
}

const initialState: AuthState = {
  loading: false,
  authorised: false,
  formStep: FORM_STEP.EMAIL,
  role: null,
  id: null,
  email: null,
  access: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setAuthorised(state, action) {
      state.authorised = action.payload;
    },
    setFormStep(state, action) {
      state.formStep = action.payload;
    },
    setUserData(state, action) {
      state.id = action.payload.userId;
      state.email = action.payload.userEmail;
      state.role = action.payload.role;
      state.access = action.payload.rbac;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { setLoading, setFormStep, setAuthorised, setUserData } =
  authSlice.actions;

export const userData = (state: RootState) => state.user;

export default authSlice.reducer;
