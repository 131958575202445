//* Packages Import
import { useState } from "react";

//* Hooks Imports *//
import { useAppDispatch } from "@Hooks/redux-hooks";
import { getMaskedInfo } from "@Actions/lead";

const useOpenWhatsApp = (
  phone: string,
  message?: string,
  isUnmasked?: boolean,
): [boolean, () => Promise<void>] => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const encodedMessage = encodeURIComponent(message || "");

  const sendWhatsAppMessage = async () => {
    setIsLoading(true);
    if (!isUnmasked) {
      const response: any = await dispatch(
        getMaskedInfo({ fieldNames: ["phone"] }),
      );

      const unmaskedPhone = response?.payload[0]?.phone;

      if (unmaskedPhone) {
        const WA_URL = `https://wa.me/${unmaskedPhone}?text=${encodedMessage}`;
        window.open(WA_URL, "_blank");
      }
    } else if (phone) {
      const WA_URL = `https://wa.me/${phone}?text=${encodedMessage}`;
      window.open(WA_URL, "_blank");
    }
    setIsLoading(false);
  };

  return [isLoading, sendWhatsAppMessage];
};

export default useOpenWhatsApp;
