import { ReactNode } from "react";
import { createPortal } from "react-dom";

interface IPortal {
  render: boolean;
  children: ReactNode;
}

export default function Portal({ children, render }: IPortal) {
  const portalElement = document.getElementById("_portal");

  return render && portalElement ? createPortal(children, portalElement) : null;
}
