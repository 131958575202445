//* Packages */
import { Fragment } from "react/jsx-runtime";

//* Assets */
import UCLogo from "@Assets/images/uc-logo.png";
// import Notification from "@Assets/icons/notification.png";

//* Hooks */
import { useAppSelector } from "@Hooks/redux-hooks";

//* Components */
import Avatar from "@Core/Avatar";
import Popper from "@Components/utility/Popper";
import Search from "@Components/Header/Search";
import ProfilePopper from "@Components/Header/ProfilePopper";

//* Utils */
import { USER_TYPE } from "@Enums/common";

const ROLE_LABEL: Record<USER_TYPE, string> = {
  sales_head: "Sales Head",
  sanction_agent: "Sanction Agent",
  am_agent: "AM Agent",
  rg_agent: "RG Agent",
  agent: "Login Agent",
  lender: "Lender",
  is_lqt_agent: "LQT Agent",
};

export default function Header() {
  const navigationLinks = useAppSelector(
    (state) => state.navigation.navigationLinks,
  );
  const email = useAppSelector((state) => state.user.email);
  const role = useAppSelector((state) => state.user.role);

  return (
    <div className="w-full h-[60px] max-w-[1680px] shadow-md bg-white flex items-center px-5 pr-8 justify-between">
      <div className="flex items-center gap-4">
        <a href="/" className="cursor-pointer">
          <img src={UCLogo} alt="unicreds" width={132} />
        </a>

        {role === USER_TYPE.LENDER && (
          <div className="text-gray border-l border-gray-border text-sm pl-4">
            Lender Portal
          </div>
        )}
        {role !== USER_TYPE.LENDER && (
          <div className="ml-4">
            <Search />
          </div>
        )}
      </div>

      <div className="flex items-center gap-6">
        {navigationLinks.map((nav, index) => {
          return (
            role &&
            !nav?.hiddenRoles?.includes(role) && (
              <Fragment key={nav.id}>
                <a
                  href={nav.link}
                  target="_blank"
                  className="cursor-pointer"
                  rel="noreferrer"
                >
                  <div className="flex items-center gap-2">
                    <img src={nav.icon} alt={nav.id} className="w-5 h-5" />
                    <span className="text-gray text-xs">{nav.label}</span>
                  </div>
                </a>
                {index < navigationLinks.length - 1 && (
                  <div className="h-4 w-[1px] bg-dark-gray" />
                )}
              </Fragment>
            )
          );
        })}
      </div>

      <div className="flex items-center gap-4 cursor-pointer">
        <Popper
          position="bottom-right"
          trigger={
            <Avatar
              name={email || "-"}
              subtext={role ? ROLE_LABEL[role] : "-"}
            />
          }
        >
          <ProfilePopper />
        </Popper>
      </div>
    </div>
  );
}
