//* Packages Imports */
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { getLoanDetails, setLeadLoanDetails } from "@Actions/lead/DetailsForm";

//* Data Imports */
import { EMPLOYMENT_TYPE, LOAN_TYPE } from "@Constants/leadDetailsData";

//* Assets Imports */
import { getCopyString } from "@Utils/common";
import CoinRupeeIcon from "@Assets/icons/CoinRupee.png";
import { MapBackendToFrontend } from "@Axios/mapping-framework";

//* Types Imports */
import { IPartner } from "@Types/common";
import { ILoanDetails } from "@Types/leads";
import { COPY_LOAN_DETAILS_MAPPER } from "@Mappers/lead";

const LoanDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading, saveLoader, loanDetails } =
    useAppSelector(lead).leadDetails.loanDetailsObj;
  const { partners } = useAppSelector((state) => state.app);
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.loanDetails.change;
  const { leadId } = useParams();

  const formInputData: Array<InputFieldsType> = [
    {
      type: "number",
      name: "loanAmount",
      label: "Loan Amount",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "loanType",
      label: "Loan Type",
      options: LOAN_TYPE,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "employmentType",
      label: "Employment Type",
      options: EMPLOYMENT_TYPE,
      disabled: isDisabled,
      callback(formObj) {
        handleEmploymentTypeChange(formObj?.formDataObj?.employmentType?.value);
      },
    },
    {
      type: "number",
      name: "existingEMI",
      label: "Existing EMI",
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "alreadyAppliedTo",
      label: "Already applied to",
      variant: "multiSelect",
      options: [...partners, { id: 0, name: "Others", logo: "" }],
      labelKey: "name",
      valueKey: "id",
      disabled: isDisabled,
    },
  ];

  const [inputFieldsData, setInputFieldsData] =
    useState<Array<InputFieldsType>>(formInputData);

  const handleEmploymentTypeChange = (employmentType: string) => {
    const additionalFields: Array<InputFieldsType> = [...formInputData];

    if (employmentType === "SALARIED" || employmentType === "SELF_EMPLOYED") {
      additionalFields.push({
        type: "number",
        name: "annualIncome",
        label: "Annual Income",
        required: true,
        disabled: isDisabled,
      });
    }

    if (employmentType === "SALARIED") {
      additionalFields.push(
        {
          type: "number",
          name: "workExperience",
          label: "Work Experience (in years)",
          required: true,
          disabled: isDisabled,
          isFloat: true,
        },
        {
          type: "text",
          name: "company",
          label: "Company Name",
          required: true,
          disabled: isDisabled,
        },
      );
    }
    setInputFieldsData(additionalFields);
  };

  const handleFormSubmit = async (formData: ILoanDetails) => {
    if (isNaN(Number(leadId))) return;

    const updatedFormData = { ...formData };
    if (loanDetails?.id) {
      updatedFormData.id = loanDetails.id;
    }

    await dispatch(
      setLeadLoanDetails({
        leadId: Number(leadId),
        payload: updatedFormData,
      }),
    );
  };

  const loanDetailsCopyContent = useMemo(() => {
    const modelMapper = new MapBackendToFrontend(COPY_LOAN_DETAILS_MAPPER);

    const formattedData: Record<string, string> =
      modelMapper.mapBackendToFrontend({
        loanAmount: loanDetails?.loanAmount || "",
        loanType: loanDetails?.loanType?.value || "",
        employmentType: loanDetails?.employmentType?.value || "",
        existingEMI:
          loanDetails?.existingEMI !== null ? loanDetails?.existingEMI : "",
        alreadyAppliedTo:
          loanDetails?.alreadyAppliedTo
            ?.map((data: IPartner) => data.name)
            ?.join(", ") || "",
        annualIncome: loanDetails?.annualIncome,
        workExperience:
          loanDetails?.workExperience !== null
            ? loanDetails?.workExperience
            : "",
        company: loanDetails?.company || "",
      });

    return getCopyString(formattedData);
  }, [loanDetails]);

  useEffect(() => {
    if (leadId && partners.length) {
      dispatch(getLoanDetails(Number(leadId)));
      setInputFieldsData(formInputData);
    }
  }, [partners]);

  useEffect(() => {
    if (loanDetails) {
      handleEmploymentTypeChange(loanDetails?.employmentType?.value);
    }
  }, [loanDetails?.employmentType?.value]);

  return (
    <div>
      <div className="flex relative gap-2 mb-7">
        <img src={CoinRupeeIcon} alt="Coin Rupee" />
        <h3>Loan Details</h3>
        <div className="absolute right-0 top-[6px]">
          <CopyToClipboard content={loanDetailsCopyContent} />
        </div>
      </div>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          onSubmit={(formData) => handleFormSubmit(formData)}
          gridCol={2}
          defaultValues={loanDetails || {}}
          hideSubmitButton={isDisabled}
          disableSubmitBtn={saveLoader}
        />
      )}
    </div>
  );
};

export default LoanDetails;
