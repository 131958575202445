export default function LeadDetailsLoader() {
  return (
    <div className="flex flex-col">
      <div className="h-4 w-[120px] rounded bg-lighter-gray animate-pulse"></div>

      <div className="mt-4">
        <div className="h-[90px] w-full rounded bg-lighter-gray animate-pulse"></div>
      </div>

      <div className="mt-4">
        <div className="h-[300px] w-full rounded bg-lighter-gray animate-pulse"></div>
      </div>

      <div className="mt-4">
        <div className="h-12 w-full rounded bg-lighter-gray animate-pulse"></div>
      </div>
    </div>
  );
}
