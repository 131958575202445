//* Packages */
import { useRef, useState } from "react";

//* Assets */
import SearchIcon from "@Assets/icons/search.png";

//* Components */
import LeadCardSearch, { ILead } from "@Components/LeadCardSearch";

//* Utils */
import API from "@Axios/main";
import debounce from "@Utils/debounce";
import { SEARCH } from "@Constants/urls";
import { SEARCH_MAPPER } from "@Mappers/app";
import { toastService } from "@Utils/toast";
import useOutsideClick from "@Hooks/useOutsideClick";

export default function Search() {
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<ILead[]>([]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const triggerRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const _API = new API("v1");

  useOutsideClick([triggerRef, popperRef], () => setShow(false));

  const fetchSuggestions = async (q: string) => {
    abortControllerRef.current?.abort();
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setLoading(true);

    try {
      const res: ILead[] = await _API.get(SEARCH, SEARCH_MAPPER, {
        params: { search: q },
        signal: abortController.signal,
      });
      setSuggestions(res);
    } catch (e: any) {
      if (e.code !== "ERR_CANCELED") {
        toastService.notify("error", "Something went wrong, please try again!");
      }
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }
  };

  const handleSearch = debounce((q: string) => {
    const trimmedQuery = q.trim();
    setQuery(trimmedQuery);

    if (!trimmedQuery) {
      setSuggestions([]);
      setLoading(false);
      return;
    }

    fetchSuggestions(trimmedQuery);
  }, 2500);

  return (
    <div className="relative">
      <div
        ref={triggerRef}
        className="w-[350px] h-[42px] bg-tag-light-gray rounded-md flex items-center px-3 gap-2"
        onClick={() => setShow(true)}
      >
        <img src={SearchIcon} alt="search" className="w-4 h-4" />
        <input
          placeholder="Search by lead name, email or phone"
          className="text-gray text-xs focus:outline-none bg-tag-light-gray w-full"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      {show && (
        <div
          ref={popperRef}
          className="flex flex-col w-[690px] max-h-[400px] overflow-auto rounded-md shadow-lg bg-white z-[999] top-14 absolute"
        >
          {loading ? (
            <span className="text-xs text-gray p-4">
              Fetching your leads...
            </span>
          ) : query ? (
            <div>
              <div className="flex items-center text-tag-gray text-xs p-4 pb-0">
                <span className="w-[400px]">Lead Info</span>
                <span className="w-1/2">Sales Agent</span>
                <span className="w-1/3">Lead Status</span>
              </div>
              {suggestions.length ? (
                suggestions.map((suggestion) => (
                  <LeadCardSearch key={suggestion.leadID} data={suggestion} />
                ))
              ) : (
                <span className="text-xs text-gray p-4">
                  No data found, try again with a different query.
                </span>
              )}
            </div>
          ) : (
            <span className="text-xs text-gray p-4">Type to search...</span>
          )}
        </div>
      )}
    </div>
  );
}
