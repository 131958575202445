//* Packages imports */
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getCookiesValue = (name: string) => {
  return cookies.get(name);
};

export const setCookiesValue = (
  name: string,
  value: string,
  cookiesExpiry: number = 2592000,
) => {
  cookies.set(name, value, {
    path: "/",
    maxAge: cookiesExpiry, // 30 Days -> 2592000 | 366 Days -> 31622400
    domain: import.meta.env.VITE_DOMAIN,
  });
};

export const removeCookiesValue = (name: string) => {
  cookies.remove(name, { path: "/", domain: import.meta.env.VITE_DOMAIN });
};

export const getValueFromLS = (name: string) => {
  try {
    return localStorage?.getItem(name);
  } catch (error) {
    console.error(error);
    return "{}";
  }
};

export const setValueToLS = (name: string, value: any) => {
  return localStorage.setItem(name, value);
};

export const removeValueFromLS = (name: string) => {
  return localStorage?.removeItem(name);
};

export const getValueFromSS = (name: string) => {
  try {
    return sessionStorage?.getItem(name);
  } catch (error) {
    console.error(error);
    return "{}";
  }
};

export const setValueToSS = (name: string, value: any) => {
  return sessionStorage.setItem(name, value);
};

export const removeValueFromSS = (name: string) => {
  return sessionStorage?.removeItem(name);
};
