//* Packages Imports */
import { useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import Button from "@Core/Button";
import FormGenerator from "@Core/FormGenerator";

//* Utils Imports */
import { USER_TYPE } from "@Enums/common";
import { lead } from "@Slice/LeadSlice";
import { userData } from "@Slice/AuthSlice";
import { createNewTask } from "@Actions/lead";
import { getStructuredDate } from "@Utils/timeUtils";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { ILeadAssigneeUsers, ILeadTaskCategory } from "@Types/common";

//* Styles Imports */
import Styles from "@Components/TaskSection/TaskForm.module.scss";

const TaskForm = ({ handleCloseForm }: { handleCloseForm: () => void }) => {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();
  const { assignees: assigneeOptions, tasks: tasksData } = useAppSelector(lead);
  const { id: authUserId, role: authUserRole } = useAppSelector(userData);

  const onSubmit = (formData: {
    taskDescription?: string;
    dueDate: { selectedDate: Date };
    taskCategory: ILeadTaskCategory;
    assignedTo: ILeadAssigneeUsers;
  }) => {
    try {
      let taskCompleteDate: number | Date = formData.dueDate.selectedDate;
      if (authUserRole === USER_TYPE.LENDER) {
        // set default time for midnight for lender
        taskCompleteDate = new Date(taskCompleteDate).setHours(23, 59, 59, 999);
      }
      const payload = {
        data: {
          lead: Number(leadId),
          summary: formData.taskDescription,
          remind_at: getStructuredDate(taskCompleteDate, true),
          task_category: formData.taskCategory.id,
          assignee: formData.assignedTo.id,
        },
        leadId: Number(leadId),
        callback: handleCloseForm,
      };
      dispatch(createNewTask(payload));
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const inputFieldsData = useMemo(() => {
    const INPUT_FIELDS_DATA = [
      {
        type: "select",
        name: "taskCategory",
        label: "Task Category",
        required: true,
        options: tasksData.categories,
        labelKey: "category",
        valueKey: "id",
        canSearch: true,
      },
      {
        type: "text",
        name: "taskDescription",
        placeholder: "write something...",
        label: "Task Description",
        required: false,
      },
      {
        type: "select",
        name: "assignedTo",
        label: "Assigned to",
        valueKey: "id",
        required: true,
        options: assigneeOptions,
        canSearch: true,
      },
      {
        type: "date",
        name: "dueDate",
        label: "Due on",
        required: true,
        datePickerType: "single",
        showTime: authUserRole !== USER_TYPE.LENDER,
        disablePreviousDates: true,
      },
    ];

    return INPUT_FIELDS_DATA;
  }, [assigneeOptions, tasksData.categories]);

  const defaultValues = useMemo(() => {
    return {
      dueDate: {
        selectedDate: new Date().setDate(new Date().getDate() + 1),
        startDate: new Date().setDate(new Date().getDate() + 1),
        endDate: null,
      },
      assignedTo: assigneeOptions.find(
        (assignee) => assignee.id === authUserId,
      ),
    };
  }, []);

  return (
    <div className={Styles.taskFormContainer}>
      <h3>Create New Task</h3>
      <FormGenerator
        defaultValues={defaultValues}
        inputFieldsData={inputFieldsData as any}
        onSubmit={onSubmit}
        hideSubmitButton
        customFooter={
          <div className={Styles.formActions}>
            <Button
              type="button"
              variant="outline"
              onClick={handleCloseForm}
              disabled={tasksData.isWriteDisabled}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={tasksData.isWriteDisabled}>
              Create New Task
            </Button>
          </div>
        }
        labelPosition="top"
      />
    </div>
  );
};

export default TaskForm;
