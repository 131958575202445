//* Packages Imports */
import { MutableRefObject, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Hooks Imports */
import useInfiniteScroll from "@Hooks/useInfiniteScroll";

//* Components Imports */
import Button from "@Core/Button";
import Sort from "@Core/Sort";
import RichTextField from "@Core/RichTextField";
import SkeletonCard from "@Core/SkeletonLoader/Card";
import NotesCard from "@Components/NotesSection/NotesCard";

//* Utils Imports */
import { DEFAULT_SORT_OPTIONS } from "@Constants/filtering";
import { INoteDetails, ILeadAssigneeUsers } from "@Types/common";
import { toastService } from "@Utils/toast";
import { createNewNote, resetAllNotes, getNotes } from "@Actions/lead";
import { lead } from "@Slice/LeadSlice";

//* Styles Imports */
import Styles from "@Components/NotesSection/NotesSection.module.scss";

const NotesSection = () => {
  const dispatch = useAppDispatch();

  const canAddNotes = useAppSelector(
    (state) => state.user.access?.detail.notes.add,
  );
  const { notes: notesData, assignees: mentionList } = useAppSelector(lead);

  const { leadId } = useParams();
  const editorRef = useRef<HTMLElement | null>(null);
  const clearEditorRef = useRef<null | (() => void)>(null);
  const getMentionsRef = useRef<null | (() => void)>(null);

  const [filters, setFilters] = useState<Record<string, string>>({});

  const fetchNotes = async () => {
    if (!notesData.hasMore) {
      return;
    }
    try {
      const payload = {
        filters: filters,
        leadId: Number(leadId),
      };
      await dispatch(getNotes(payload));
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const [notesLoaderRef, notesLoading] = useInfiniteScroll(
    fetchNotes,
    notesData.hasMore,
    [filters, notesData.hasMore],
  );

  const clearEditorContent = () => {
    clearEditorRef.current?.();
  };

  const handleSubmit = async () => {
    if (
      editorRef.current?.innerHTML &&
      editorRef.current.innerHTML.length > 12
    ) {
      const noteCreated = editorRef.current?.innerHTML;
      let taggedAgents: number[] | null = null;

      // Get mentioned ids as string
      const mentionedData: ILeadAssigneeUsers[] =
        getMentionsRef.current?.() || [];
      if (mentionedData?.length > 0) {
        taggedAgents = mentionedData.map((data) => data.id);
        const loanPartners = mentionedData
          .filter((data) => data.loanPartner)
          .map((data) => data.loanPartner);
        const uniqueLoanPartners = new Set(loanPartners);

        if (uniqueLoanPartners.size > 1) {
          toastService.notify(
            "error",
            "Cannot tag more than 1 lender at a time!",
          );
          return;
        }
      }

      try {
        const payload = {
          data: {
            note: noteCreated,
            tagged_agents: taggedAgents,
          },
          leadId: Number(leadId),
          callback: clearEditorContent,
        };
        dispatch(createNewNote(payload));
      } catch (err: unknown) {
        console.error(err);
      }
    }
  };

  return (
    <>
      {canAddNotes && (
        <div className={Styles.notesContainer}>
          <div className={Styles.notesInput}>
            <p>Create new note</p>
            <RichTextField
              editorRef={editorRef}
              clearEditorRef={clearEditorRef}
              getMentionsRef={getMentionsRef}
              mentionList={mentionList as any}
              hasToolbar
              hideToolbarOnBlur
              classes={Styles.noteEditor}
            />
          </div>
          <div className={Styles.noteSubmit}>
            <Button
              variant="outline"
              classes={Styles.submitBtn}
              onClick={handleSubmit}
              disabled={notesData.isCreateDisabled}
            >
              Create Note
            </Button>
          </div>
        </div>
      )}
      <div className={Styles.pastNotes}>
        <div className={Styles.title}>
          All Notes
          <Sort
            options={DEFAULT_SORT_OPTIONS}
            defaultSelected="-created_at"
            handleChange={(order: string) => {
              setFilters({ ...filters, ordering: order });
              dispatch(resetAllNotes());
            }}
          />
        </div>

        <div className={Styles.notesList}>
          {notesData.list.map((note: INoteDetails) => (
            <NotesCard key={note.id} note={note} />
          ))}
          <div
            className="flex-none min-h-1"
            ref={notesLoaderRef as MutableRefObject<null>}
          >
            {notesLoading && <SkeletonCard contentLines={0} listLength={8} />}
            {!notesData.hasMore &&
              (notesData.list?.length > 0 ? (
                <div className="text-center text-xs text-error">
                  No more data!
                </div>
              ) : (
                <div className={Styles.emptyData}>No data found!</div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotesSection;
