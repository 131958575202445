//* Packages */
import { RootState } from "@/store";
import { createSlice } from "@reduxjs/toolkit";

//* Utils */
import {
  ICountry,
  ITag,
  IPartner,
  ILostReason,
  ISelect,
  IAgent,
} from "@Types/common";

interface IAppState {
  appLoading: boolean;
  tags: ITag[];
  countries: ICountry[];
  partners: IPartner[];
  lostReasons: ILostReason[];
  utmSources: ISelect[];
  utmMediums: ISelect[];
  team: IAgent[];
}

const initialState: IAppState = {
  appLoading: false,
  tags: [],
  countries: [],
  partners: [],
  lostReasons: [],
  utmSources: [],
  utmMediums: [],
  team: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppLoading(state, action) {
      state.appLoading = action.payload;
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setPartners(state, action) {
      state.partners = action.payload;
    },
    setLostReasons(state, action) {
      state.lostReasons = action.payload;
    },
    setUtmSources(state, action) {
      state.utmSources = action.payload;
    },
    setUtmMediums(state, action) {
      state.utmMediums = action.payload;
    },
    setTeam(state, action) {
      state.team = action.payload;
    },
  },
});

export const {
  setAppLoading,
  setTags,
  setCountries,
  setPartners,
  setLostReasons,
  setUtmSources,
  setUtmMediums,
  setTeam,
} = appSlice.actions;

export const appData = (state: RootState) => state.app;

export default appSlice.reducer;
