//* Packages */
import { useEffect, useState } from "react";

//* Component Imports */
import Select from "@Core/Select";

//* Utils Imports */
import { getAgentTeam, updateLeadDetails } from "@Actions/lead";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Types & Enums Imports */
import { Agent, LeadTeamType } from "@Types/leads";
import { AGENT_TEAM_LIST, LENDER_TEAM_LIST, USER_TYPE } from "@Enums/common";

//* Redux Imports */
import { reassignLender } from "@Actions/lead/LoanApplication";

interface IAgentsProps {
  data: {
    [K in LeadTeamType]?: Agent;
  };
}

interface TeamMember {
  title: string;
  slug: LeadTeamType;
  disableAction?: boolean;
}

const AGENT_LIST: TeamMember[] = [
  { title: "Login Agent", slug: AGENT_TEAM_LIST.SALES_AGENT },
  { title: "Sanction Agent", slug: AGENT_TEAM_LIST.SANCTION_AGENT },
  { title: "Assistant Manager", slug: AGENT_TEAM_LIST.ASSISTANT_MANAGER },
  { title: "RG Agent", slug: AGENT_TEAM_LIST.RG_AGENT },
];

const LENDER_LIST: TeamMember[] = [
  { title: "Lender Executive", slug: LENDER_TEAM_LIST.LENDER_EXECUTIVE },
  {
    title: "Lender Manager",
    slug: LENDER_TEAM_LIST.LENDER_MANAGER,
    disableAction: true,
  },
  {
    title: "Lender Regional Head",
    slug: LENDER_TEAM_LIST.LENDER_REGIONAL_HEAD,
    disableAction: true,
  },
];

export default function Agents({ data }: IAgentsProps) {
  const dispatch = useAppDispatch();

  const { role } = useAppSelector((state) => state.user);
  const { teamData, leadData } = useAppSelector((state) => state.lead);

  const canUpdateAgent = useAppSelector(
    (state) => state.user.access?.detail.assignAgent.change,
  );

  const [agentMemberList, setAgentMemberList] = useState(AGENT_LIST);

  const fetchTeamByAgent = (agentType: LeadTeamType) => () => {
    if (!teamData?.[agentType]) dispatch(getAgentTeam(agentType));
  };

  const updatedSelectedValue = async (selectedVal: Agent, slug: string) => {
    if (!leadData?.id) return;
    const payload = {
      [slug]: selectedVal.id,
    };

    if (role === USER_TYPE.LENDER)
      dispatch(
        reassignLender({
          leadId: leadData?.id,
          applicationId: leadData.lenderData.applicationId,
          ...payload,
        }),
      );
    else dispatch(updateLeadDetails({ leadId: leadData?.id, payload }));
  };

  useEffect(() => {
    if (leadData?.leadinfo.lqtAgent) {
      setAgentMemberList((prev) => [
        { title: "LQT Agent", slug: AGENT_TEAM_LIST.LQT_AGENT },
        ...prev,
      ]);
    }
  }, [leadData?.leadinfo.lqtAgent]);

  return (
    <div className="flex items-center gap-4">
      {(role === USER_TYPE.LENDER ? LENDER_LIST : agentMemberList).map(
        (teamMember: TeamMember) => (
          <div
            key={teamMember.slug}
            className="flex flex-col gap-[6px] w-[160px]"
          >
            <span className="text-placeholder-gray text-xxs">
              {teamMember.title}
            </span>
            <Select
              labelKey="name"
              valueKey="id"
              size="xs"
              fetchOptions={fetchTeamByAgent(teamMember.slug)}
              options={teamData?.[teamMember.slug]?.list || []}
              disabled={!canUpdateAgent || teamMember?.disableAction}
              optionsLoading={teamData?.[teamMember.slug]?.loading}
              defaults={data[teamMember.slug] || []}
              setValue={(selectedVal) =>
                updatedSelectedValue(selectedVal, teamMember.slug)
              }
            />
          </div>
        ),
      )}
    </div>
  );
}
