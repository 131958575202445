//* Packages Imports */
import { MutableRefObject } from "react";
import { $getRoot, $createParagraphNode } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const MiscEditorPlugin = ({
  clearEditor,
  getMentions,
}: {
  clearEditor?: MutableRefObject<(() => void) | null>;
  getMentions?: MutableRefObject<(() => void) | null>;
}) => {
  const [editor] = useLexicalComposerContext();

  if (clearEditor)
    clearEditor.current = () => {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        root.append($createParagraphNode());
      });
    };

  if (getMentions)
    getMentions.current = () => {
      const mentionedList: Record<string, string>[] = [];
      if (editor?._decorators && Object.keys(editor._decorators).length > 0) {
        for (const decorator of Object.values(editor._decorators)) {
          const node = (decorator as any)?.props?.data;
          if (node) {
            mentionedList.push(node as Record<string, string>);
          }
        }
      }

      return mentionedList;
    };

  return null;
};

export default MiscEditorPlugin;
