//* Package Imports */
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Service Imports */
import API from "@Axios/main";
import { toastService } from "@Utils/toast";
import { updateFieldFromOptions } from "@Utils/lead";
import { getStructuredDate } from "@Utils/timeUtils";
import { LEAD_DETAILS_SECTION, GET_CITY_STATE } from "@Constants/urls";
import {
  setMarketingDetails,
  setBasicDetails,
  setAdmissionDetails,
  setLoanDetails,
  setCoApplicantDetails,
  updateCoApplicantDetails,
  removeCoApplicantDetails,
  setMarketingLoading,
  setBasicDetailsLoading,
  setAdmissionDetailsLoading,
  setLoanDetailsLoading,
  setCoApplicantDetailsLoading,
  setAcademicDetailsLoading,
  setAcademicDetails,
} from "@Slice/LeadSlice";

//* Data Imports */
import { RootState } from "@Store/index";
import { countryCodeData, ICountryCode } from "@Constants/countryCode";
import {
  ADMISSION_STATUS,
  AREA_OF_STUDY,
  DEGREES_LIST,
  EMPLOYMENT_TYPE,
  EXAM_LIST,
  CO_APPLICANT_PROFESSION,
  LOAN_TYPE,
  RELATION_ARRAY,
} from "@Constants/leadDetailsData";

//* Types & Mapper */
import {
  ISelect,
  ICountry,
  IPartner,
  IDatePickerSelection,
  CityState,
} from "@Types/common";
import {
  IAcademicDetails,
  IAdmissionDetails,
  IBasicDetails,
  ICoApplicantDetails,
  ILoanDetails,
  IMarketingDetails,
} from "@Types/leads";
import {
  MARKETING_DETAILS_MAPPER,
  ACADEMIC_DETAILS_MAPPER,
  BASIC_DETAILS_MAPPER,
  CO_APPLICANT_MAPPER,
  LOAN_DETAILS_MAPPER,
  ADMISSION_DETAILS_MAPPER,
} from "@Mappers/lead";

const _API = new API();

const apiCall = async (
  url: string,
  type: "get" | "post" | "patch",
  payload: any = null,
  mapper: any = {},
) => {
  let response;
  if (type === "get") response = await _API.get(url, mapper);
  if (type === "post") response = await _API.post(url, payload, mapper);
  if (type === "patch") response = await _API.patch(url, payload, mapper);
  return response;
};

//* Lead Details Section Actions */
export const getMarketingDetails = createAsyncThunk(
  "leads/getMarketingDetails",
  async (leadId: number, { dispatch }) => {
    try {
      dispatch(setMarketingLoading(true));
      const response: IMarketingDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "marketing-details"),
        MARKETING_DETAILS_MAPPER,
      );
      dispatch(setMarketingDetails(response?.[0]));
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead marketing details");
    } finally {
      dispatch(setMarketingLoading(false));
    }
  },
);

export const getLeadBasicDetails = createAsyncThunk(
  "leads/getLeadBasicDetails",
  async (leadId: number, { dispatch }) => {
    try {
      dispatch(
        setBasicDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: IBasicDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "basic-details"),
        BASIC_DETAILS_MAPPER,
      );
      if (response?.[0] && Object.keys(response[0])?.length) {
        countryCodeData.forEach((country: ICountryCode) => {
          if (
            country.dialCode === (response[0].alternateCountryCode as string)
          ) {
            response[0].alternateCountryCode = country;
          }
        });
        response[0].dob = {
          selectedDate: response[0]?.dob,
          startDate: response[0]?.dob,
          endDate: null,
        } as IDatePickerSelection;
        response[0].phoneWithCode =
          (response[0]?.alternateCountryCode as ICountryCode)?.dialCode +
          response[0]?.secondaryMobileNo;

        dispatch(setBasicDetails(response[0]));
      }
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead basic details");
    } finally {
      dispatch(
        setBasicDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setLeadBasicDetails = createAsyncThunk(
  "leads/setLeadBasicDetails",
  async (body: { leadId: number; payload: IBasicDetails }, { dispatch }) => {
    let errorMsg = "";

    try {
      dispatch(
        setBasicDetailsLoading({
          loading: true,
          type: "save",
        }),
      );

      let apiResponse: IBasicDetails | null = null;

      const hasAlternateCountryCode = !!(
        body.payload.alternateCountryCode as ICountryCode
      )?.dialCode;
      const hasSecondaryMobileNo = !!body.payload?.secondaryMobileNo?.length;

      if (hasAlternateCountryCode && !hasSecondaryMobileNo) {
        errorMsg = "Please enter a valid Phone number";
        throw new Error();
      }

      if (hasSecondaryMobileNo && !hasAlternateCountryCode) {
        errorMsg = "Please enter a valid Country Code";
        throw new Error();
      }

      const payload = {
        first_name: body.payload?.firstName,
        last_name: body.payload?.lastName,
        alt_phone:
          hasAlternateCountryCode && hasSecondaryMobileNo
            ? (body.payload?.alternateCountryCode as ICountryCode)?.dialCode +
              body.payload?.secondaryMobileNo
            : "",
        dob: (body.payload?.dob as IDatePickerSelection)?.selectedDate
          ? getStructuredDate(
              (body.payload.dob as IDatePickerSelection).selectedDate,
            )
          : null,
        city: body.payload.city,
        pincode: body.payload?.pincode,
        state: body.payload?.state,
      };

      if (body.payload?.id) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "basic-details")}/${body.payload.id}`,
          "patch",
          payload,
          BASIC_DETAILS_MAPPER,
        )) as IBasicDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "basic-details")}`,
          "post",
          { ...payload, lead: body.leadId },
          BASIC_DETAILS_MAPPER,
        )) as IBasicDetails | null;
      }

      const updatedResponse = {
        ...apiResponse,
        alternateCountryCode: body.payload.alternateCountryCode,
        dob: body.payload.dob,
      };

      dispatch(setBasicDetails(updatedResponse));
      toastService.notify("success", "Lead basic details updated successfully");
    } catch (error: any) {
      console.error("Error", error);

      const errorData = error.response?.data;

      if (errorData && !errorMsg.length && Object.keys(errorData).length) {
        Object.entries(errorData).forEach(([key, value]) => {
          errorMsg += `${key} - ${value}\n`;
        });
      }

      errorMsg = !errorMsg.length
        ? errorData?.message || "Failed to set lead basic form details"
        : errorMsg;

      toastService.notify("error", errorMsg);
    } finally {
      dispatch(
        setBasicDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const getAdmissionDetails = createAsyncThunk(
  "leads/getAdmissionDetails",
  async (leadId: number, { getState, dispatch }) => {
    try {
      const { app } = getState() as RootState;
      dispatch(
        setAdmissionDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: IAdmissionDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "admission-details"),
        ADMISSION_DETAILS_MAPPER,
      );
      if (response?.length && Object.keys(response[0])?.length) {
        const updatedResponse = {
          ...response[0],
          admissionStatus: ADMISSION_STATUS.filter(
            (data) => data.value === response[0]?.admissionStatus,
          )[0],
          areaOfStudy: AREA_OF_STUDY.filter(
            (data) => data.value === response[0]?.areaOfStudy,
          )[0],
          degree: DEGREES_LIST.filter(
            (data) => data.value === response[0]?.degree,
          )[0],
          country: app.countries.filter(
            (data: any) => data.id === response[0]?.country,
          )[0],
        };

        if (response[0]?.exams?.length) {
          updatedResponse.exams = EXAM_LIST.filter(
            (data) => data.value === response[0]?.exams[0]?.name,
          )[0];
          updatedResponse.overallScore = response[0]?.exams[0]?.score;
        }

        if (response[0]?.intake) {
          updatedResponse.intake = {
            selectedDate: response[0]?.intake,
            startDate: response[0]?.intake,
            endDate: null,
          } as IDatePickerSelection;
        }

        dispatch(setAdmissionDetails(updatedResponse));
      }
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead admission details");
    } finally {
      dispatch(
        setAdmissionDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setLeadAdmissionDetails = createAsyncThunk(
  "leads/setAdmissionDetails",
  async (
    body: { leadId: number; payload: IAdmissionDetails },
    { dispatch },
  ) => {
    try {
      dispatch(
        setAdmissionDetailsLoading({
          loading: true,
          type: "save",
        }),
      );

      let apiResponse: IAdmissionDetails | null = null;

      const payload = {
        course: (body.payload.areaOfStudy as ISelect)?.value,
        admission_status: (body.payload.admissionStatus as ISelect)?.value,
        country: (body.payload?.country as ICountry)?.id,
        degree: (body.payload?.degree as ISelect)?.value,
        university: body.payload?.university,
        exams: [
          {
            name: body.payload?.exams?.value,
            score: parseFloat(body.payload?.overallScore as string),
          },
        ],
        university_join_date: body.payload?.intake
          ? getStructuredDate(
              (body.payload.intake as IDatePickerSelection).selectedDate,
            )
          : null,
      };

      if (body.payload?.id) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "admission-details")}/${body.payload.id}`,
          "patch",
          payload,
          ADMISSION_DETAILS_MAPPER,
        )) as IAdmissionDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "admission-details")}`,
          "post",
          { ...payload, lead: body.leadId },
          ADMISSION_DETAILS_MAPPER,
        )) as IAdmissionDetails | null;
      }

      const updatedResponse = {
        ...apiResponse,
        admissionStatus: body.payload?.admissionStatus,
        areaOfStudy: body.payload?.areaOfStudy,
        degree: body.payload?.degree,
        exams: body.payload?.exams,
        overallScore: body.payload?.overallScore,
        country: body.payload?.country,
        intake: body.payload?.intake,
      } as IAdmissionDetails;

      dispatch(setAdmissionDetails(updatedResponse));
      toastService.notify(
        "success",
        "Lead Admission details updated successfully",
      );
    } catch (error: any) {
      console.error("Error", error);
      let errorMsg = "";

      const errorData = error.response?.data;

      if (errorData && Object.keys(errorData).length) {
        Object.entries(errorData).forEach(([key, value]) => {
          errorMsg += `${key} - ${value}\n`;
        });
      }

      errorMsg = !errorMsg.length
        ? errorData?.message || "Failed to set lead Admission form details"
        : errorMsg;
      toastService.notify("error", errorMsg);
    } finally {
      dispatch(
        setAdmissionDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const getAcademicDetails = createAsyncThunk(
  "leads/getAcademicDetails",
  async (leadId: number, { dispatch }) => {
    try {
      dispatch(
        setAcademicDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: IAcademicDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "academic-details"),
        ACADEMIC_DETAILS_MAPPER,
      );
      dispatch(setAcademicDetails(response[0]));
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead academic details");
    } finally {
      dispatch(
        setAcademicDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setLeadAcademicDetails = createAsyncThunk(
  "leads/setAcademicDetails",
  async (body: { leadId: number; payload: IAcademicDetails }, { dispatch }) => {
    try {
      dispatch(
        setAcademicDetailsLoading({
          loading: true,
          type: "save",
        }),
      );

      let apiResponse: IAcademicDetails | null = null;

      const payload = {
        tenth_marks: parseFloat(body.payload?.tenthMarks as string),
        twelfth_marks: parseFloat(body.payload?.twelfthMarks as string),
        bachelor_marks: parseFloat(body.payload?.bachelorMarks as string),
        masters_marks: parseFloat(body.payload?.mastersMarks as string),
        no_of_backlogs: parseInt(body.payload?.noOfBacklogs as string),
        work_gap: parseInt(body.payload?.workGap as string),
        academic_gap: parseInt(body.payload?.academicGap as string),
      };

      if (body.payload?.id) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "academic-details")}/${body.payload.id}`,
          "patch",
          payload,
          ACADEMIC_DETAILS_MAPPER,
        )) as any;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "academic-details")}`,
          "post",
          { ...payload, lead: body.leadId },
          ACADEMIC_DETAILS_MAPPER,
        )) as any;
      }

      dispatch(setAcademicDetails(apiResponse));
      toastService.notify(
        "success",
        "Lead Academic details updated successfully",
      );
    } catch (error: any) {
      console.error("Error", error);
      let errorMsg = "";

      const errorData = error.response?.data;

      if (errorData && Object.keys(errorData).length) {
        Object.entries(errorData).forEach(([key, value]) => {
          errorMsg += `${key} - ${value}\n`;
        });
      }

      errorMsg = !errorMsg.length
        ? errorData?.message || "Failed to set lead Academic form details"
        : errorMsg;
      toastService.notify("error", errorMsg);
    } finally {
      dispatch(
        setAcademicDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const getLoanDetails = createAsyncThunk(
  "leads/getLoanDetails",
  async (leadId: number, { getState, dispatch }) => {
    try {
      const { app } = getState() as RootState;
      const partnersList = [
        ...app.partners,
        { id: 0, name: "Others", logo: "" },
      ];
      dispatch(
        setLoanDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: any = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "loan-details"),
        LOAN_DETAILS_MAPPER,
      );
      if (response?.length && Object.keys(response[0])?.length) {
        const updatedResponse = {
          ...response[0],
          loanAmount: response[0]?.loanAmount
            ? parseInt(response[0].loanAmount)
            : response[0].loanAmount,
          loanType: LOAN_TYPE.filter(
            (data) => data.value === response[0]?.loanType,
          )[0],
          employmentType: EMPLOYMENT_TYPE.filter(
            (data) => data.value === response[0]?.employmentType,
          )[0],
        };

        if (response[0]?.alreadyAppliedTo.length) {
          updatedResponse.alreadyAppliedTo = [];
          partnersList.forEach((partner: IPartner) => {
            if (response[0]?.alreadyAppliedTo.includes(partner.name)) {
              updatedResponse.alreadyAppliedTo.push(partner);
            }
          });
        }

        dispatch(setLoanDetails(updatedResponse));
      }
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead loan details");
    } finally {
      dispatch(
        setLoanDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setLeadLoanDetails = createAsyncThunk(
  "leads/setLeadLoanDetails",
  async (body: { leadId: number; payload: ILoanDetails }, { dispatch }) => {
    try {
      dispatch(
        setLoanDetailsLoading({
          loading: true,
          type: "save",
        }),
      );

      let apiResponse: ILoanDetails | null = null;

      const payload = {
        loan_amount: parseFloat(body.payload?.loanAmount as string),
        loan_type: body.payload.loanType?.value,
        existing_emi: parseInt(body.payload?.existingEMI),
        employment_type: body.payload.employmentType?.value,
        already_applied_to: body.payload?.alreadyAppliedTo?.map(
          (option: IPartner) => option.name,
        ),
        annual_income: parseInt(body.payload?.annualIncome as string),
        work_experience: parseFloat(body.payload?.workExperience as string),
        company: body.payload?.company,
      };

      if (body.payload?.id) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "loan-details")}/${body.payload.id}`,
          "patch",
          payload,
          LOAN_DETAILS_MAPPER,
        )) as ILoanDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "loan-details")}`,
          "post",
          { ...payload, lead: body.leadId },
          LOAN_DETAILS_MAPPER,
        )) as ILoanDetails | null;
      }

      const updatedResponse = {
        ...apiResponse,
        loanAmount: parseInt(apiResponse?.loanAmount as string),
        loanType: body.payload?.loanType,
        employmentType: body.payload?.employmentType,
        alreadyAppliedTo: body.payload?.alreadyAppliedTo,
      } as ILoanDetails;

      dispatch(setLoanDetails(updatedResponse));
      toastService.notify("success", "Lead Loan details updated successfully");
    } catch (error: any) {
      console.error("Error", error);
      let errorMsg = "";

      const errorData = error.response?.data;

      if (errorData && Object.keys(errorData).length) {
        Object.entries(errorData).forEach(([key, value]) => {
          errorMsg += `${key} - ${value}\n`;
        });
      }

      errorMsg = !errorMsg.length
        ? errorData?.message || "Failed to set lead loan details"
        : errorMsg;
      toastService.notify("error", errorMsg);
    } finally {
      dispatch(
        setLoanDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const getCoApplicantDetails = createAsyncThunk(
  "leads/getCoApplicantDetails",
  async (leadId: number, { dispatch }) => {
    try {
      dispatch(
        setCoApplicantDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: ICoApplicantDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "coapplicant-details "),
        CO_APPLICANT_MAPPER,
      );

      const updatedResponse: ICoApplicantDetails[] = [];

      response?.forEach((coApplicant: ICoApplicantDetails) => {
        updateFieldFromOptions(
          countryCodeData,
          "countryCode",
          coApplicant as any,
          "dialCode",
        );
        updateFieldFromOptions(
          CO_APPLICANT_PROFESSION,
          "profession",
          coApplicant as any,
        );
        updateFieldFromOptions(RELATION_ARRAY, "relation", coApplicant as any);
        coApplicant.dob = {
          selectedDate: coApplicant?.dob as string,
          startDate: coApplicant?.dob as string,
          endDate: null,
        };
        updatedResponse.push(coApplicant);
      });

      dispatch(setCoApplicantDetails(response));
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead co-applicant details");
    } finally {
      dispatch(
        setCoApplicantDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setCoApplicantDetail = createAsyncThunk(
  "leads/setCoApplicantDetails",
  async (
    body: { leadId: number; payload: ICoApplicantDetails },
    { dispatch },
  ) => {
    let errorMsg = "";

    try {
      const hasAlternateCountryCode = !!(
        body.payload.countryCode as ICountryCode
      )?.dialCode;
      const hasSecondaryMobileNo = !!body.payload?.phone?.length;

      if (hasAlternateCountryCode && !hasSecondaryMobileNo) {
        errorMsg = "Please enter a valid Phone number";
        throw new Error();
      }

      if (hasSecondaryMobileNo && !hasAlternateCountryCode) {
        errorMsg = "Please enter a valid Country Code";
        throw new Error();
      }

      dispatch(
        setCoApplicantDetailsLoading({
          loading: true,
          type: "save",
        }),
      );
      let apiResponse: ICoApplicantDetails | null = null;

      const payload = {
        first_name: body.payload?.firstName,
        last_name: body.payload?.lastName,
        email: body.payload?.email,
        phone:
          hasAlternateCountryCode && hasSecondaryMobileNo
            ? (body.payload.countryCode as ICountryCode)?.dialCode +
              body.payload?.phone
            : "",
        relation: (body.payload?.relation as ISelect)?.value,
        dob: body.payload?.dob || null,
        existing_emi:
          body.payload?.ongoingEmis !== null
            ? parseInt(body.payload.ongoingEmis as string)
            : body.payload.ongoingEmis,
        employed: body.payload?.employed,
        income: body.payload?.income,
        profession: body.payload.profession?.value,
        pincode: body.payload?.pincode,
        state: body.payload?.state,
        city: body.payload?.city,
      };

      if (body.payload.id && !body.payload?.tempCoApplicant) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "coapplicant-details")}/${body.payload.id}`,
          "patch",
          payload,
          CO_APPLICANT_MAPPER,
        )) as ICoApplicantDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "coapplicant-details")}`,
          "post",
          payload,
          CO_APPLICANT_MAPPER,
        )) as ICoApplicantDetails | null;
      }
      const updatedResponse = {
        ...apiResponse,
        dob: apiResponse?.dob
          ? {
              selectedDate: (apiResponse?.dob as string) || null,
              startDate: (apiResponse?.dob as string) || null,
              endDate: null,
            }
          : null,
        relation: body.payload?.relation,
        countryCode: body.payload.countryCode,
        profession: updateFieldFromOptions(
          CO_APPLICANT_PROFESSION,
          "profession",
          apiResponse as any,
        ),
      } as ICoApplicantDetails;

      dispatch(updateCoApplicantDetails(updatedResponse));
      toastService.notify(
        "success",
        "Lead co-applicant details updated successfully",
      );
    } catch (error: any) {
      console.error("Error", error);

      const errorData = error.response?.data;

      if (errorData && !errorMsg.length && Object.keys(errorData).length) {
        Object.entries(errorData).forEach(([key, value]) => {
          errorMsg += `${key} - ${value}\n`;
        });
      }

      errorMsg = !errorMsg.length
        ? errorData?.message || "Failed to set coapplicant details"
        : errorMsg;
      toastService.notify("error", errorMsg);
      throw error;
    } finally {
      dispatch(
        setCoApplicantDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const removeCoApplicant = createAsyncThunk(
  "leads/removeCoApplicant",
  async (body: { leadId: number; coApplicantId: number }, { dispatch }) => {
    try {
      await _API.patch(
        `${LEAD_DETAILS_SECTION(body.leadId, "coapplicant-details")}/${body.coApplicantId}`,
        {
          is_deleted: true,
        },
      );
      dispatch(removeCoApplicantDetails(body.coApplicantId));
      toastService.notify(
        "success",
        "Lead co-applicant details removed successfully",
      );
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify(
        "error",
        error.response?.data?.message ||
          "Failed to remove lead co-applicant details",
      );
    }
  },
);

//* Get City State */
export const getCityState = async (pincode: string) => {
  try {
    const res: CityState = await _API.get(GET_CITY_STATE(pincode));
    if (!res || !res.city || !res.state) {
      toastService.notify("error", "Invalid Pincode");
    }
    return res;
  } catch (err: unknown) {
    let errorMessage;
    if (err instanceof AxiosError) {
      errorMessage = err.response?.data?.message || err.message;
    }
    toastService.notify("error", errorMessage || "Error getting city state!");
  }
};
