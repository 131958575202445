//* Component Imports */
import TabList from "@Core/Tabs/TabList";
import TabPane from "@Core/Tabs/TabPane";
import AllLeads from "@Components/AllLeads";
import MyTasks from "@Components/MyTasks";

//* Assets Imports */
import AvatarIcon from "@Assets/icons/UserAvatar.png";
import TasksIcon from "@Assets/icons/TasksNotepad.png";

const Dashboard = () => {
  return (
    <div>
      <TabList
        onTabChange={() => {}}
        tabId="tasksPanel"
        variant="gray"
        tabsVariant="left"
        tabTitleClasses="py-1 px-5"
      >
        <TabPane
          label="Tasks"
          preLabel={<img src={TasksIcon} alt="user" className="w-7" />}
        >
          <MyTasks />
        </TabPane>
        <TabPane
          label="All Leads"
          preLabel={<img src={AvatarIcon} alt="user" className="w-7" />}
        >
          <AllLeads />
        </TabPane>
      </TabList>
    </div>
  );
};

export default Dashboard;
