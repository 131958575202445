//* Packages Imports */
import { useEffect, useState, useRef } from "react";

//* Component Imports */
import Radio from "@Core/Radio";

//* Assets Imports */
import SortIcon from "@Assets/icons/SortFilterIcon.png";

//* Styles Imports*/
import Styles from "@Core/Sort/Sort.module.scss";

//* Interfaces */
interface SortProps {
  options: { label: string; value: string }[];
  defaultSelected: string;
  handleChange: (value: string) => void;
}

export default function Sort({
  options = [],
  defaultSelected = "",
  handleChange,
}: SortProps) {
  const optionsRef = useRef<HTMLDivElement | null>(null);

  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(defaultSelected);

  const handleDropdownClose = () => {
    setOptionsVisible(false);
  };

  useEffect(() => {
    if (optionsVisible) {
      const handleDropdownClick = (event: MouseEvent) => {
        if (!optionsRef.current?.contains(event.target as HTMLElement)) {
          handleDropdownClose();
        }
      };

      document.addEventListener("mousedown", handleDropdownClick);
      return () => {
        document.removeEventListener("mousedown", handleDropdownClick);
      };
    }
  }, [optionsVisible]);

  return (
    <div
      className={Styles.sortContainer}
      data-cy="sort-filter-container"
      ref={optionsRef}
    >
      <button type="button" onClick={() => setOptionsVisible(!optionsVisible)}>
        <img src={SortIcon} alt="Sort_Icon" />
        Sort
      </button>
      {optionsVisible && (
        <div className={Styles.sortPanel} role="listbox">
          <p>Sort by</p>
          {options?.length > 0 &&
            options.map((option) => (
              <div
                className={Styles.sortOption}
                key={option.value}
                role="option"
              >
                <Radio
                  id={option.value}
                  handleChange={(value: string | number) => {
                    setSelectedOption(String(value));
                    handleChange(String(value));
                  }}
                  label={option.label}
                  value={option.value}
                  checked={option.value === selectedOption}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
