//* Packages Imports */
import clsx from "clsx";
import { useEffect, useState } from "react";

//* Component Imports */
import Select from "@Core/Select";
import Pagination from "@Core/Pagination";
import SkeletonList from "@Core/SkeletonLoader/List";
import QueryFilters from "@Components/Filter/QueryFilters";

//* Utils Imports */
import { StatusKey } from "@Types/common";
import { USER_TYPE } from "@Enums/common";
import { PAGE_SIZE_OPTIONS } from "@Constants/common";
import { STATUS_COLOR } from "@Core/StatusBadge/constants";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { LEADS_FILTER_CONFIG, LEADS_FILTER_KEYS } from "@Constants/filtering";
import { getAllLeads, getAllStatus, getStatusCount } from "@Actions/dashboard";
import {
  getTeamMembers,
  getLostReasons,
  getTags,
  getUtmMediums,
  getUtmSources,
} from "@Actions/app";

//* Assets Imports */
import ChevronDown from "@Assets/icons/ChevronDown.png";
import RefreshIcon from "@Assets/icons/refresh.png";

//* Styles Imports */
import Styles from "@Components/AllLeads/AllLeads.module.scss";

const AllLeads = () => {
  const dispatch = useAppDispatch();
  const tags = useAppSelector((state) => state.app.tags);
  const team = useAppSelector((state) => state.app.team);
  const role = useAppSelector((state) => state.user.role);
  const leads = useAppSelector((state) => state.dashboard.leads);
  const lostReasons = useAppSelector((state) => state.app.lostReasons);
  const utmSources = useAppSelector((state) => state.app.utmSources);
  const utmMediums = useAppSelector((state) => state.app.utmMediums);

  const statusList = leads.statusList;
  const isUserLender = role === USER_TYPE.LENDER;

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [pageLimit, setPageLimit] = useState({ label: "20" });
  const [activeTab, setActiveTab] = useState<StatusKey | null>(null);

  const getLeadStatusCount = () => {
    if (!leads.statusLoading) dispatch(getStatusCount(filters));
  };

  const fetchNewData = (val: any) => {
    setPage(1);
    setFilters(val);
  };

  useEffect(() => {
    if (activeTab)
      dispatch(
        getAllLeads({
          page_size: pageLimit.label,
          page,
          status: activeTab,
          ...filters,
        }),
      );
  }, [page, pageLimit, activeTab, filters]);

  useEffect(() => {
    if (statusList?.length && !activeTab) {
      setActiveTab(statusList[0]);
    }
  }, [statusList]);

  useEffect(() => {
    if (!statusList.length) dispatch(getAllStatus());

    if (!isUserLender) {
      if (!tags.length) dispatch(getTags());
      if (!lostReasons.length) dispatch(getLostReasons());
      if (!utmSources.length) dispatch(getUtmSources());
      if (!utmMediums.length) dispatch(getUtmMediums());
      if (!team?.length) dispatch(getTeamMembers());
    }
  }, []);

  return (
    <div className={Styles.allLeadsContainer}>
      <div className={Styles.refreshContainer}>
        <button onClick={getLeadStatusCount}>
          {leads.statusLoading ? (
            <img className={Styles.loading} src={RefreshIcon} alt="loading" />
          ) : leads.statusCount ? (
            <>
              <img className={Styles.refresh} src={RefreshIcon} alt="refresh" />
              Refresh Count
            </>
          ) : (
            <>
              Get Count{" "}
              <img className={Styles.chevron} src={ChevronDown} alt="get" />
            </>
          )}
        </button>
      </div>
      {!isUserLender && (
        <div className="bg-white px-5 py-2">
          <QueryFilters
            filterConfig={LEADS_FILTER_CONFIG}
            filterKeys={LEADS_FILTER_KEYS}
            handleApplyFilters={fetchNewData}
            hasClearAll
          />
        </div>
      )}

      <div className={Styles.leadStatusList}>
        {statusList?.length > 0 &&
          statusList.map((status) => (
            <button
              key={status}
              className={clsx(
                STATUS_COLOR[status]?.border,
                activeTab === status && {
                  [Styles.activeStatus]: true,
                  [STATUS_COLOR[status]?.color]: true,
                  [STATUS_COLOR[status]?.text || "text-white"]: true,
                },
                activeTab !== status && "text-black",
              )}
              onClick={() => {
                setActiveTab(status);
                setPage(1);
              }}
            >
              {STATUS_COLOR[status]?.label}
              {leads.statusCount?.[status] !== undefined && (
                <span>{leads.statusCount[status]}</span>
              )}
            </button>
          ))}
      </div>
      <div className={Styles.pageTitle}>
        Showing {activeTab && STATUS_COLOR[activeTab]?.label} Leads
        <div className={Styles.pageRowsData}>
          Showing{" "}
          <span>
            <Select
              options={PAGE_SIZE_OPTIONS}
              valueKey="label"
              placeholder="10"
              defaults={pageLimit}
              size="xs"
              setValue={(data) => {
                setPageLimit(data);
                setPage(1);
              }}
              disabled={leads.loading || !leads.list?.length}
            />
          </span>
          rows
        </div>
      </div>
      <div className={Styles.leadsTable}>
        <div className={Styles.leadsHeader}>
          <div>Lead Info</div>
          {isUserLender && <div>Application Status</div>}
          <div>UniCreds Agent</div>
          <div>Created on</div>
        </div>
        {leads.loading ? (
          <SkeletonList gridCols={4} listLength={Number(pageLimit.label)} />
        ) : leads.list?.length > 0 ? (
          leads.list.map((lead) => (
            <div key={lead.id} className={Styles.leadCard}>
              <div>
                {lead.name}{" "}
                <a href={`/leads/${lead.id}`} target="_blank" rel="noreferrer">
                  {" "}
                  (#{lead.id})
                </a>
              </div>
              {isUserLender && (
                <div>
                  {lead.status && STATUS_COLOR[lead.status] ? (
                    <>
                      <span
                        className={clsx(
                          Styles.statusBanner,
                          STATUS_COLOR[lead.status]?.color,
                        )}
                      />
                      {STATUS_COLOR[lead.status]?.label}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              )}
              <div>{lead.agent}</div>
              <div>{lead.assignedOn}</div>
            </div>
          ))
        ) : (
          <div className={Styles.noData}>No data found</div>
        )}
      </div>
      {!leads.loading && Number(leads?.pages) > 1 && (
        <div className={Styles.paginationContainer}>
          <Pagination
            totalPages={leads.pages || 1}
            currentPage={page}
            setCurrentPage={(num: number) => {
              setPage(num);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AllLeads;
