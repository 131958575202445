export interface Crumb {
  id: string | number;
  label: string;
  link?: string;
}

interface IBreadcrumbs {
  crumbs: Crumb[];
}

export default function Breadcrumbs({ crumbs }: IBreadcrumbs) {
  return (
    <div className="flex items-center">
      {crumbs.map((crumb, index) => (
        <div className="text-xs flex items-center" key={crumb.id}>
          <div className="text-xs text-gray ">
            {crumb?.link ? (
              <a
                href={crumb.link}
                target="_blank"
                rel="noreferrer"
                className="hover:underline cursor-pointer"
              >
                {crumb.label}
              </a>
            ) : (
              <span>{crumb.label}</span>
            )}
          </div>
          {index < crumbs.length - 1 && (
            <span className="px-1  text-gray">{">"}</span>
          )}
        </div>
      ))}
    </div>
  );
}
