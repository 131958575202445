//* Package Imports */
import { useState, useEffect, useRef } from "react";
import clsx from "clsx";

//* Components Imports */
import Checkbox from "@Core/Checkbox";

//* Assets Imports */
import CircleCross from "@Assets/icons/CircleCross.png";
import SearchIcon from "@Assets/icons/search.png";

//* Utils Imports */
import debounce from "@Utils/debounce";

//* Styles */
import Styles from "@Core/TagSelect/TagSelect.module.scss";

//* Interface */
interface SelectProps {
  options: Record<string, any>[];
  label?: string;
  showDownArrow?: boolean;
  canSearch?: boolean;
  variant?: "singleSelect" | "multiSelect";
  placeholder?: string;
  defaults?: any;
  labelKey?: string;
  valueKey?: string;
  size?: "sm" | "xs" | "xxs";
  disabled?: boolean;
  mandatory?: boolean;
  setValue?: (option: any) => void;
  onClick?: () => void;
}

const TagSelect = ({
  options,
  canSearch,
  variant = "singleSelect",
  label = "Select",
  labelKey = "label",
  valueKey = "value",
  defaults = "",
  size = "xs",
  disabled = false,
  setValue,
  onClick,
}: SelectProps) => {
  const selectorRef = useRef<HTMLDivElement | null>(null);

  const [listVisible, setListVisible] = useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const textClass = `text-${size}`;

  const getSelectedOptions = () => {
    const values = defaults.split(",");
    return options.filter((item) => values.includes(item[valueKey].toString()));
  };

  const checkBoxSelectedOption = selectedOptions?.map(
    (option) => option?.[labelKey],
  );

  const selected: string =
    variant === "multiSelect" && selectedOptions?.length
      ? checkBoxSelectedOption?.join(", ")
      : selectedOptions?.[0]?.[labelKey];

  const handleDropdownClose = () => {
    setDropdownOptions(options);
    setListVisible(false);
  };

  const handleSelectOption = (value: any, checked?: boolean) => {
    let _selectedOptions;
    if (variant === "multiSelect") {
      _selectedOptions =
        checked && selectedOptions
          ? [...selectedOptions, value]
          : selectedOptions?.filter(
              (option) => option?.[valueKey] !== value?.[valueKey],
            );
    } else {
      handleDropdownClose();
      _selectedOptions = [value];
    }

    setSelectedOptions(_selectedOptions);
    setValue?.(_selectedOptions);
  };

  const handleClearValue = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setListVisible(false);
    setSelectedOptions([]);
    setValue?.([]);
  };

  const handleInputChange = debounce((value: string) => {
    setDropdownOptions(
      options?.filter((option) =>
        option[labelKey].toLowerCase().includes(value.trim().toLowerCase()),
      ),
    );
  }, 500);

  useEffect(() => {
    if (listVisible) {
      const handleDropdownClick = (event: MouseEvent) => {
        if (
          !selectorRef.current?.contains(event.target as HTMLElement) &&
          listVisible
        ) {
          handleDropdownClose();
        }
      };

      document.addEventListener("mousedown", handleDropdownClick);
      return () => {
        document.removeEventListener("mousedown", handleDropdownClick);
      };
    }
  }, [listVisible]);

  useEffect(() => {
    const defaultSelectedOptions = getSelectedOptions();
    if (
      JSON.stringify(selectedOptions) !== JSON.stringify(defaultSelectedOptions)
    ) {
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [defaults]);

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  return (
    <div
      className={clsx(Styles.selectContainer, {
        [Styles.disabled]: disabled,
      })}
      ref={selectorRef}
      onClick={onClick}
    >
      <div className={Styles.selectWrapper}>
        <section
          className={Styles.selectLabel}
          onClick={() => {
            if (!disabled) setListVisible(!listVisible);
          }}
        >
          {selected && (
            <img
              onClick={(e) => {
                handleClearValue(e);
              }}
              src={CircleCross}
              alt="clear"
            />
          )}
          {label}
          {selected && <div className={Styles.selectedFields}>{selected}</div>}
        </section>
        {listVisible && (
          <div className={Styles.selectPanel} role="listbox">
            {canSearch && (
              <div className={clsx(Styles.selectOption, Styles.inputType)}>
                <img src={SearchIcon} alt="search" />
                <input
                  className={Styles.input}
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                />
              </div>
            )}

            {dropdownOptions?.length > 0 ? (
              dropdownOptions.map((option, index) => (
                <div
                  className={clsx(Styles.selectOption, {
                    [Styles.selected]:
                      variant !== "multiSelect" &&
                      selectedOptions?.some(
                        (item) => item?.[valueKey] === option[valueKey],
                      ),
                    [Styles.singleSelect]: variant !== "multiSelect",
                  })}
                  key={`${option[labelKey]}${index}`}
                  role="option"
                  onClick={() => {
                    if (variant !== "multiSelect") handleSelectOption(option);
                  }}
                >
                  {variant === "multiSelect" ? (
                    <Checkbox
                      label={option[labelKey]}
                      name={option[labelKey]}
                      value={option[valueKey]}
                      checked={checkBoxSelectedOption?.includes(
                        option[labelKey],
                      )}
                      onChange={(_, checked) =>
                        handleSelectOption(option, checked)
                      }
                    />
                  ) : (
                    <span className={textClass}>{option[labelKey]}</span>
                  )}
                </div>
              ))
            ) : (
              <div
                className={clsx(
                  Styles.selectOption,
                  Styles.noOptions,
                  textClass,
                )}
              >
                No options
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagSelect;
