export const DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const MONTHS_MAP: Record<number, string> = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const SHORT_MONTHS_MAP: Record<number, string> = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const DAYS_MAP: Record<number, string> = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export function leapYear(year: number) {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}

export function getMonthStartingDay(year: number, month: number) {
  return new Date(year, month, 1).getDay();
}

export const formatDate = (date: string | Date) => {
  try {
    const _date = new Date(date);
    return (
      _date.getDate() +
      "-" +
      MONTHS_MAP[_date.getMonth()] +
      "-" +
      _date.getFullYear()
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTimeFromDate = (date: string | Date) => {
  const _date = new Date(date);
  let hours: number | string = _date.getHours();
  let minutes: number | string = _date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours < 10 ? "0" + hours : hours.toString();
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${hours}:${minutes} ${ampm}`;
};

export function getNoOfDaysBetweenDates(date1: string, date2: string) {
  try {
    if (!date1 && !date2) {
      return 0;
    } else if (date1 && !date2) {
      return 1;
    } else {
      const _date1 = new Date(date1).getTime();
      const _date2 = new Date(date2).getTime();

      return (_date2 - _date1) / (1000 * 60 * 60 * 24);
    }
  } catch (error) {
    console.error(error);
    return 0;
  }
}
